import { useHistory, useLocation } from "react-router-dom";

export const useQueryParams = () => {
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);

  const getParam = (name) => {
    return queryParams.get(name);
  };

  const setParam = (name, value) => {
    if (!value) {
      queryParams.delete(name);
    } else {
      queryParams.set(name, value);
    }

    history.push({
      pathname: location.pathname,
      search: queryParams.toString(),
    });
  };

  return { getParam, setParam };
};
