import {
  Button,
  Card,
  Col,
  Empty,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Typography,
  Spin,
} from "antd";

import UserSelectOption from "common/components/userSelect/userSelect";
import { useEffect, useRef, useState } from "react";

import Client from "redux/models/client";
import Dashboard from "redux/models/dashboard";
import LookupTable from "redux/models/lookupTable";
import User from "redux/models/user";

import K from "utilities/constants";
import {
  convertIntoSnakeCase,
  customUserHandleSearch,
} from "utilities/generalUtility";

import { useHistory } from "react-router-dom";
import useSearchAndFilter from "common/customHook/useSearchAndFilter";
import { useDispatch, useSelector } from "react-redux";
import * as dashboardActions from "redux/slices/dashboardSlice";
import * as dashboardSelectors from "redux/selectors/dashboardSelector";
import DashboardJobDetailModal from "./dashboardJobDetailModal";

// import PipelineDashboardReqDetail from "./pipelineDashboardReqDetail";
import PipelineDashboardSummary from "./pipelineDashboardSummary";

const { Title } = Typography;
const { Option } = Select;
const searchFilterPrefix = "pipeline_dashboard";

const getDefaultFilterOption = (option) => ({
  id: option?.value,
  name: option?.label,
});

const getDefaultUserFilterOption = (option) => ({
  id: option?.value,
  name: option?.title,
});

export default function PipelineDashboard({ globalSelector, setPrintData }) {
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  // const [isPending, startTransition] = useTransition();
  const history = useHistory();
  const statusRef = useRef(null);
  const [setter, getter] = useSearchAndFilter();
  const selectedDepartmentOption = useSelector(
    dashboardSelectors.selectPipelineDepartmentFilter,
  );
  const selectedLocationOption = useSelector(
    dashboardSelectors.selectPipelineLocationFilter,
  );
  const selectedManagerOption = useSelector(
    dashboardSelectors.selectPipelineManagerFilter,
  );
  const selectedRecruiterOption = useSelector(
    dashboardSelectors.selectPipelineRecruiterFilter,
  );

  const [loading, setLoading] = useState({
    summaryLoading: false,
    reqLoading: false,
    reqDetailLoading: false,
    jobLocationListing: false,
    recruiters: false,
    jobDepartmentListing: false,
    clientHiringManager: false,
  });

  const [isModalVisible, setIsModalVisible] = useState(false);
  // const [requisitionDetail, setRequisitionDetail] = useState([]);
  const [pageData, setPageData] = useState({
    hiringManagers: [getDefaultUserFilterOption(selectedManagerOption)],
    departments: [],
    recruiters: [getDefaultUserFilterOption(selectedRecruiterOption)],
    jobDepartmentListing: [getDefaultFilterOption(selectedDepartmentOption)],
    jobLocationListing: [getDefaultFilterOption(selectedLocationOption)],
  });
  const [statusDetail, setStatusDetail] = useState([]);
  const [dashboardData, setDashboardData] = useState({
    candidateSummary: null,
  });

  const onFinish = async (values) => {
    setLoading((prev) => {
      return { ...prev, summaryLoading: true, reqLoading: true };
    });
    console.log("values", values, getter(`${searchFilterPrefix}_recruiterId`));
    setter(values, searchFilterPrefix);

    await Promise.all([
      fetchSummary(values),
      // startTransition(() =>
      //   getRequisitionDetail({ managerId, title, recruiterId, filter }),
      // ),
    ]);

    setPrintData((prev) => ({
      ...prev,
      pipelineDashboard: {
        ...prev.pipelineDashboard,
        requisition: values.ext_ats_id ? values.ext_ats_id : K.NullPlaceholder,
        title: values.title ? values.title : K.NullPlaceholder,
      },
    }));
  };

  const getJobLocations = async () => {
    try {
      const body = {
        client_ids: globalSelector.selectedClients ?? [],
      };
      setLoading((prev) => {
        return { ...prev, jobLocationListing: true };
      });
      setPageData((prev) => {
        return { ...prev, jobLocationListing: [] };
      });
      const res = await LookupTable.getJobLocationByMultipleClient(body, false);
      setPageData((prev) => {
        return { ...prev, jobLocationListing: res };
      });
      setLoading((prev) => {
        return { ...prev, jobLocationListing: false };
      });
    } catch (error) {
      console.error(error);
      setLoading((prev) => {
        return { ...prev, jobLocationListing: false };
      });
    }
  };

  const getClientDepartments = async () => {
    try {
      const body = {
        client_ids: globalSelector.selectedClients ?? [],
      };
      setLoading((prev) => {
        return { ...prev, jobDepartmentListing: true };
      });
      setPageData((prev) => {
        return { ...prev, jobDepartmentListing: [] };
      });
      const res = await LookupTable.getJobDepartmentByMultipleClient(
        body,
        false,
      );
      setPageData((prev) => ({ ...prev, jobDepartmentListing: res }));
      setLoading((prev) => {
        return { ...prev, jobDepartmentListing: false };
      });
    } catch (err) {
      console.error(err);
      setLoading((prev) => {
        return { ...prev, jobDepartmentListing: false };
      });
    }
  };

  const getClientRecruiter = async () => {
    try {
      const body = {
        client_ids: globalSelector.selectedClients ?? [],
      };
      setLoading((prev) => {
        return { ...prev, recruiters: true };
      });
      setPageData((prev) => {
        return { ...prev, recruiters: [] };
      });
      const recruiter = await Client.getRecruiterByClientsId(body, false);

      setPageData((prev) => ({ ...prev, recruiters: recruiter }));
      setLoading((prev) => {
        return { ...prev, recruiters: false };
      });
    } catch (err) {
      console.error(err);
      setLoading((prev) => {
        return { ...prev, recruiters: false };
      });
    }
  };

  // const getRequisitionDetail = async (values) => {
  //   const body = { clientIds: globalSelector.selectedClients, ...values };
  //   try {
  //     const res = await Dashboard.getRequisitionDetail(body);
  //     startTransition(() => {
  //       setRequisitionDetail(res);
  //       setLoading((prev) => {
  //         return { ...prev, reqLoading: false };
  //       });
  //     });
  //   } catch (err) {
  //     console.error(err);
  //     setLoading((prev) => {
  //       return { ...prev, reqLoading: false };
  //     });
  //   }
  // };

  const getJobDetailByStatus = async (statusId, jobIds, subStatusId) => {
    setIsModalVisible(true);
    setLoading((prev) => {
      return { ...prev, reqDetailLoading: true };
    });

    try {
      const payload = {
        statusId,
        subStatusId,
        clientIds: globalSelector.selectedClients,
        ...form.getFieldsValue(),
      };
      const res = await Dashboard.v2.getPipelineDetails(payload);

      setStatusDetail(res);
      setLoading((prev) => {
        return { ...prev, reqDetailLoading: false };
      });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSummary = async (values) => {
    try {
      const body = { clientIds: globalSelector.selectedClients, ...values };
      const res = await Dashboard.v2.getPipelineSummary(body);
      setDashboardData({ candidateSummary: res });
      setLoading((prev) => {
        return { ...prev, summaryLoading: false };
      });
    } catch (err) {
      setLoading((prev) => {
        return { ...prev, summaryLoading: false };
      });
      console.error(err);
    }
  };

  const getClientHiringManager = async () => {
    try {
      const body = { clientIds: globalSelector.selectedClients };

      setLoading((prev) => {
        return { ...prev, hiringManagers: true };
      });
      setPageData((prev) => {
        return { ...prev, hiringManagers: [] };
      });
      const hiringManagerData = await User.getClientHiringManager(body, false);
      setPageData((prev) => ({ ...prev, hiringManagers: hiringManagerData }));
      setLoading((prev) => {
        return { ...prev, hiringManagers: false };
      });
    } catch (err) {
      console.error(err);
      setLoading((prev) => {
        return { ...prev, hiringManagers: false };
      });
    }
  };

  const jumpToJobDetail = (
    clientId,
    jobId,
    statusId,
    statusName,
    subStatusId,
    subStatusName,
  ) => {
    let path = `/clients/${clientId}/job/${jobId}/${statusId}/${convertIntoSnakeCase(
      statusName,
    )}`;
    if (subStatusId) {
      path += `/${subStatusId}/${convertIntoSnakeCase(subStatusName)}`;
    }

    history.push(path);
  };

  useEffect(() => {
    form.submit();
  }, [globalSelector.selectedClients]);

  const handleCancel = () => {
    setIsModalVisible(false);
    setStatusDetail([]);
  };

  return (
    <>
      <Card className="historicalPerformanceCard">
        <div className="historicalPerformance">
          <Title className="hpTitle" level={5}>
            Pipeline Dashboard
          </Title>
          <Form
            form={form}
            initialValues={{
              managerId: getter(`${searchFilterPrefix}_managerId`) ?? null,
              title: getter(`${searchFilterPrefix}_title`) ?? "",
              recruiterId: getter(`${searchFilterPrefix}_recruiterId`) ?? null,
            }}
            className="selectMenu"
            onFinish={onFinish}
          >
            <Row gutter={[20, 20]}>
              <Col xs={24} sm={12} md={8}>
                <Form.Item
                  className="formItems"
                  name="ext_ats_id"
                  initialValue={getter(`${searchFilterPrefix}_ext_ats_id`)}
                >
                  <Input placeholder="Requisition ID" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Form.Item
                  className="formItems"
                  name="job_departments_id"
                  initialValue={getter(
                    `${searchFilterPrefix}_job_departments_id`,
                  )}
                >
                  <Select
                    showSearch
                    allowClear
                    loading={loading.jobDepartmentListing}
                    optionFilterProp="label"
                    placeholder="Job Department"
                    options={pageData.jobDepartmentListing.map((item) => ({
                      value: item.id,
                      label: item.name,
                    }))}
                    onChange={(_, option) => {
                      setPrintData((prev) => ({
                        ...prev,
                        pipelineDashboard: {
                          ...prev.pipelineDashboard,
                          department: option?.label ?? K.NullPlaceholder,
                        },
                      }));
                      dispatch(
                        dashboardActions.setFilterDepartmentOption(option),
                      );
                    }}
                    onDropdownVisibleChange={(open) => {
                      if (open) {
                        getClientDepartments();
                      }
                    }}
                    notFoundContent={
                      loading.jobDepartmentListing ? (
                        <Spin
                          className="dashboard-input-spinner"
                          size="small"
                        />
                      ) : (
                        "No Data Found"
                      )
                    }
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} md={8}>
                <Form.Item
                  className="formItems"
                  name="job_locations_id"
                  initialValue={getter(
                    `${searchFilterPrefix}_job_locations_id`,
                  )}
                >
                  <Select
                    showSearch
                    optionFilterProp="label"
                    allowClear
                    placeholder="Job Location"
                    loading={loading.jobLocationListing}
                    options={pageData.jobLocationListing.map(
                      ({ id, name }) => ({
                        value: id,
                        label: name,
                      }),
                    )}
                    onChange={(_, option) => {
                      setPrintData((prev) => ({
                        ...prev,
                        pipelineDashboard: {
                          ...prev.pipelineDashboard,
                          location: option?.label ?? K.NullPlaceholder,
                        },
                      }));
                      dispatch(
                        dashboardActions.setFilterLocationOption(option),
                      );
                    }}
                    onDropdownVisibleChange={(open) => {
                      if (open) {
                        getJobLocations();
                      }
                    }}
                    notFoundContent={
                      loading.jobLocationListing ? (
                        <Spin
                          className="dashboard-input-spinner"
                          size="small"
                        />
                      ) : (
                        "No Data Found"
                      )
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Form.Item
                  className="formItems"
                  name="managerId"
                  initialValue={getter(`${searchFilterPrefix}_managerId`)}
                >
                  <Select
                    showSearch
                    optionFilterProp="label"
                    optionLabelProp="title"
                    allowClear
                    loading={loading.hiringManagers}
                    placeholder="Manager"
                    // options={pageData.hiringManagers.map(({ id, name }) => ({
                    //   value: id,
                    //   label: name,
                    // }))}
                    filterOption={customUserHandleSearch}
                    onChange={(_, option) => {
                      setPrintData((prev) => ({
                        ...prev,
                        pipelineDashboard: {
                          ...prev.pipelineDashboard,
                          manager: option?.label ?? K.NullPlaceholder,
                        },
                      }));
                      dispatch(dashboardActions.setFilterManagerOption(option));
                    }}
                    onDropdownVisibleChange={(open) => {
                      if (open) {
                        getClientHiringManager();
                      }
                    }}
                    notFoundContent={
                      loading.hiringManagers ? (
                        <Spin
                          className="dashboard-input-spinner"
                          size="small"
                        />
                      ) : (
                        "No Data Found"
                      )
                    }
                  >
                    {pageData.hiringManagers.map(
                      ({ id, name, email, clients }) => (
                        <Option key={id} value={id} title={name}>
                          <UserSelectOption
                            key={id}
                            userEmail={email}
                            clients={clients}
                          >
                            {name}
                          </UserSelectOption>
                        </Option>
                      ),
                    )}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Form.Item
                  className="formItems"
                  name="title"
                  initialValue={getter(`${searchFilterPrefix}_title`)}
                >
                  <Input placeholder="Title" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Form.Item
                  className="formItems"
                  name="recruiterId"
                  initialValue={getter(`${searchFilterPrefix}_recruiterId`)}
                >
                  <Select
                    showSearch
                    allowClear
                    loading={loading.recruiters}
                    optionLabelProp="title"
                    optionFilterProp="children"
                    placeholder="Recruiters"
                    filterOption={customUserHandleSearch}
                    onChange={(_, option) => {
                      setPrintData((prev) => ({
                        ...prev,
                        historicalPerformance: {
                          ...prev.historicalPerformance,
                          manager: option?.label ?? K.NullPlaceholder,
                        },
                      }));
                      dispatch(
                        dashboardActions.setFilterRecruiterOption(option),
                      );
                    }}
                    onDropdownVisibleChange={(open) => {
                      if (open) {
                        getClientRecruiter();
                      }
                    }}
                    notFoundContent={
                      loading.recruiters ? (
                        <Spin
                          className="dashboard-input-spinner"
                          size="small"
                        />
                      ) : (
                        "No Data Found"
                      )
                    }
                  >
                    {pageData?.recruiters?.map(
                      ({ id, name, email, clients }) => (
                        <Option title={name} key={id} value={id}>
                          <UserSelectOption
                            key={id}
                            userEmail={email}
                            clients={clients}
                          >
                            {name}
                          </UserSelectOption>
                        </Option>
                      ),
                    )}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item className="formSubmitBtn">
              <Button
                type="primary"
                htmlType="submit"
                loading={loading.summaryLoading}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Card>
      <PipelineDashboardSummary
        // requisitionDetail={requisitionDetail}
        dashboardData={dashboardData}
        isLoading={loading.summaryLoading}
        getJobDetailByStatus={getJobDetailByStatus}
        statusRef={statusRef}
      />
      {/* Requisition Detail Section */}
      {/* <Title className="requisitionTitle">Requisition Detail </Title> */}
      {/* {loading.reqLoading ? ( */}
      {/*  <Card loading={true} /> */}
      {/* ) : requisitionDetail.length ? ( */}
      {/*  requisitionDetail.map( */}
      {/*    (item, index) => */}
      {/*      item.vacancies?.length > 0 && ( */}
      {/*        <PipelineDashboardReqDetail */}
      {/*          key={index} */}
      {/*          getJobDetailByStatus={getJobDetailByStatus} */}
      {/*          requisitionDetail={requisitionDetail} */}
      {/*          statusRef={statusRef} */}
      {/*          reqLoading={loading.reqLoading} */}
      {/*          item={item} */}
      {/*          index={index} */}
      {/*        /> */}
      {/*      ), */}
      {/*  ) */}
      {/* ) : ( */}
      {/*  <Empty /> */}
      {/* )} */}

      <Modal
        forceRender
        centered
        title={`${statusRef.current?.displayStatusName ?? "N/A"} | Total:  ${
          statusRef.current?.statusCount ?? "0"
        }`}
        width={600}
        okButtonProps={{ hidden: true }}
        open={isModalVisible}
        closeIcon={<i className="icon-closeable" />}
        className="s2-theme-style"
        wrapClassName="vertical-center-modal"
        onCancel={handleCancel}
        destroyOnClose
      >
        {statusRef.current?.statusCount !== 0 ? (
          <DashboardJobDetailModal
            details={statusDetail}
            setStatusDetail={setStatusDetail}
            isModalVisible={isModalVisible}
            statusRef={statusRef}
            jumpToJobDetail={jumpToJobDetail}
            isLoading={loading.reqDetailLoading}
          />
        ) : (
          <Empty />
        )}
      </Modal>
    </>
  );
}
