import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Tooltip,
} from "antd";
import UserSelectOption from "common/components/userSelect/userSelect";

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Client from "redux/models/client";
// import LookupTable from "redux/models/lookupTable";
import User from "redux/models/user";
import { selectUdf } from "redux/slices/udfSlice";
import K from "utilities/constants";
import { convertDateStringToMoment } from "utilities/dateUtility";
import {
  customUserHandleSearch,
  generateUdfSetFieldValues,
  generateUdfValuesObject,
  getIsVisibleLookups,
  noTrailingSpaceAllowedRule,
  removeUdfKeysFromObject,
} from "utilities/generalUtility";
import DisplayUDF from "../userDefinedFields/displayUdf";

const { Option } = Select;

export default function CreateJob({
  form,
  clientId,
  lookupTables,
  setModalState,
  editData,

  getJobUdfs,
  setRefreshTable,
}) {
  const [jobTiers, setJobTiers] = useState([]);
  const udfSlice = useSelector(selectUdf);
  const [users, setUsers] = useState({ "Hiring Manager": [], Recruiter: [] });

  const shouldUpdate = (prevValues, currValues) => {
    if (prevValues.jobTiersId !== currValues.jobTiersId && !editData)
      form.resetFields(["jobTiersTitlesId"]);
    return prevValues.jobTiersId !== currValues.jobTiersId;
  };
  const editJob = async (values) => {
    setModalState((prev) => {
      return { ...prev, isLoadingJobModal: true };
    });
    values.recruitingStartDate = values.recruitingStartDate.format(
      K.DateFormat.ResponseWithTime,
    );
    const body = {
      ...values,
      jobSourceId: editData.id,
      clientsId: clientId,
    };
    try {
      await Client.editClientJobs(body);
      setRefreshTable((prev) => !prev);
      await getJobUdfs(editData.id);

      setModalState((prev) => {
        return {
          ...prev,
          isLoadingJobModal: false,
          isCreateJobModalVisible: false,
        };
      });
      message.success("Job Updated Successfully");
    } catch (err) {
      setModalState((prev) => {
        return { ...prev, isLoadingJobModal: false };
      });
      console.error(err);
    }
  };

  const addJob = async (values) => {
    setModalState((prev) => {
      return { ...prev, isLoadingJobModal: true };
    });
    values.clientsId = +clientId;
    values.recruitingStartDate = values.recruitingStartDate.format(
      K.DateFormat.ResponseWithTime,
    );
    values.isWorkFromHome = +values.isWorkFromHome;
    try {
      await Client.addClientJobs(values);
      await getJobUdfs();
      setRefreshTable((prev) => !prev);
      setModalState((prev) => {
        return {
          ...prev,
          isLoadingJobModal: false,
          isCreateJobModalVisible: false,
        };
      });
      message.success("Job Created Successfully");
    } catch (err) {
      setModalState((prev) => {
        return { ...prev, isLoadingJobModal: false };
      });
      console.error(err);
    }
  };

  const onFinish = async (values) => {
    const udfValues = generateUdfValuesObject(
      udfSlice[K.Redux.JobSourceUdf],
      values,
    );
    const updatedValues = removeUdfKeysFromObject(
      values,
      udfSlice[K.Redux.JobSourceUdf],
    );
    values = { ...updatedValues, udfValues: udfValues };
    if (editData) {
      editJob(values);
    } else {
      addJob(values);
    }
  };

  const validateStartingFields = (fromValue, to) => {
    const toValue = form.getFieldValue(to);
    if (!fromValue && toValue) {
      return Promise.reject("Enter Min Pay!");
    } else if (parseInt(fromValue) > parseInt(toValue)) {
      return Promise.reject("Value should be less than `Max Pay` field");
    } else {
      return Promise.resolve();
    }
  };
  const validateEndingFields = (toValue, from) => {
    const fromValue = form.getFieldValue(from);
    if (!toValue && fromValue) {
      return Promise.reject("Enter Max Pay!");
    } else if (parseInt(fromValue) > parseInt(toValue)) {
      return Promise.reject("Value should be greater than `Min Pay` field");
    } else {
      return Promise.resolve();
    }
  };

  const formatter = (value) =>
    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const getClientTiers = async () => {
    try {
      const res = await Client.getTiersById(clientId);
      setJobTiers(res);
    } catch (err) {
      console.error(err);
    }
  };

  // const getUserDesignations = async () => {
  //   try {
  //     const designations = await LookupTable.getDesignationData();
  //     const requiredDesignation = designations.filter(
  //       (item) => item.name === "Recruiter"
  //     );
  //     await Promise.all([
  //       ...requiredDesignation.map(async (item) => {
  //         const res = await User.getbyDesignation(item.id);

  //         setUsers((prev) => ({ ...prev, [item.name]: res.users }));
  //       }),
  //     ]);
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  const getClientHiringManager = async () => {
    try {
      //*id = clientID
      const body = { clientIds: [clientId] };
      const res = await User.getClientHiringManager(body);
      setUsers((prev) => ({ ...prev, ["Hiring Manager"]: res }));
    } catch (err) {
      console.error(err);
    }
  };

  const getClientRecruiter = async () => {
    try {
      const body = {
        client_ids: [clientId] ?? [],
      };
      const recruiter = await Client.getRecruiterByClientsId(body);

      setUsers((prev) => ({ ...prev, Recruiter: recruiter }));
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    (async () => {
      await Promise.all([
        // getUserDesignations(),
        getClientHiringManager(),
        getClientTiers(),
        getClientRecruiter(),
      ]);
    })();
  }, []);

  useEffect(() => {
    if (editData) {
      const udfEditData = generateUdfSetFieldValues(
        udfSlice[K.Redux.JobSourceUdf],
      );
      console.log("Edit data", editData);
      form.setFieldsValue({
        title: editData?.title,
        jobTiersId: editData?.jobTier.id,
        jobTiersTitlesId: editData?.jobTier?.jobTierTitle?.find(
          (item) => item?.jobTitle?.id === editData?.jobTiersTitlesId,
        )?.jobTitle?.id,
        managerId: editData?.jobHiringManagers?.map((item) => item.id),
        jobRecruiters: editData?.jobRecruiters?.map((item) => item.id),
        jobLocationsId: editData?.jobLocation?.id,
        recruitingStartDate: convertDateStringToMoment(
          editData?.recruitingStartDate,
        ),
        atsLookupId: editData?.atsLookUp?.id,
        atsLookupSecondaryId: editData?.atsLookUpSecondary?.id,
        extAtsId: editData?.extAtsId,
        extSecondaryAtsId: editData?.extSecondaryAtsId,
        jobStatusesId: editData?.jobStatus?.id,
        jobDepartmentsId: editData?.jobDepartment?.id,
        jobGroupsId: editData?.jobGroup?.id,
        jobOriginalOpenings: editData?.vacancies?.map(
          (item) => item.totalOpenings,
        ),
        maxPay: editData?.maxPay,
        minPay: editData?.minPay,
        isWorkFromHome: editData?.isWorkFromHome,
        ...udfEditData,
      });
    }
  }, [editData]);

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onFinish}
      initialValues={{
        isWorkFromHome: false,
      }}
    >
      <Row gutter={28}>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Title"
            name="title"
            rules={[
              { required: true, message: "Please enter job title!" },
              noTrailingSpaceAllowedRule(),
            ]}
          >
            <Input placeholder="Enter Job Title" className="modalInput" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Select Tier"
            name="jobTiersId"
            rules={[{ required: true, message: "Please select job tier!" }]}
          >
            <Select
              showSearch
              allowClear
              optionFilterProp="children"
              placeholder="Select Tier"
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            >
              {jobTiers.map(({ id, name }) => (
                <Option key={id} value={id}>
                  {name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={12}>
          <Form.Item shouldUpdate={shouldUpdate}>
            {({ getFieldValue }) => {
              const jobTierTitles =
                jobTiers
                  .find(({ id }) => id === getFieldValue("jobTiersId"))
                  ?.jobTierTitle?.sort((a, b) =>
                    a.job_title.name.localeCompare(b.job_title.name),
                  ) || [];
              return (
                <Form.Item
                  label="Associated Titles"
                  name="jobTiersTitlesId"
                  className="mb-0"
                  rules={[
                    {
                      required: true,
                      message: "Please select tier associated titles",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    placeholder="Select Tier Associated Titles"
                    disabled={!getFieldValue("jobTiersId")}
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  >
                    {jobTierTitles.map(({ job_title }, index) => (
                      <Option key={index} value={job_title.id}>
                        {job_title.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Job Hiring Managers"
            name="managerId"
            rules={[
              {
                required: true,
                message: "Please select job hiring managers",
              },
            ]}
          >
            <Select
              showArrow
              showSearch
              allowClear
              mode="multiple"
              optionFilterProp="label"
              optionLabelProp="title"
              placeholder="Select Job Hiring Managers"
              filterOption={customUserHandleSearch}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            >
              {users["Hiring Manager"].map(({ id, name, email, clients }) => (
                <Option key={id} value={id} title={name}>
                  <UserSelectOption userEmail={email} clients={clients}>
                    {name}
                  </UserSelectOption>
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={12}>
          <Form.Item
            label="Job Recruiters"
            name="jobRecruiters"
            rules={[
              {
                required: true,
                message: "Please select job recruiters",
              },
            ]}
          >
            <Select
              showArrow
              allowClear
              showSearch={true}
              mode="multiple"
              optionFilterProp="children"
              optionLabelProp="title"
              placeholder="Select Job Recruiters"
              filterOption={customUserHandleSearch}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            >
              {users["Recruiter"].map(({ id, name, email, clients }) => (
                <Option key={id} value={id} title={name}>
                  <UserSelectOption userEmail={email} clients={clients}>
                    {name}
                  </UserSelectOption>
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {/* @Client Lookup */}
        <Col xs={24} sm={12}>
          <Form.Item
            label="Job Locations"
            className="formItemLocation"
            name="jobLocationsId"
            rules={[
              {
                required: true,
                message: "Please select job location",
              },
            ]}
          >
            <Select
              showSearch
              allowClear
              placeholder="Select Job Locations"
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              optionFilterProp="children"
            >
              {getIsVisibleLookups(
                lookupTables.jobLocation.filter(
                  (lookup) => parseInt(lookup.clientId) === parseInt(clientId),
                ),
                editData?.jobLocation.id,
              ).map((item) => (
                <Option
                  key={item.id}
                  value={item.id}
                  disabled={item.isVisible === 1 ? false : true}
                >
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={12}>
          <Form.Item
            label={
              <div>
                <Tooltip
                  title="Starting point for the calculation of the time to paramenters"
                  color={K.TooltipColor}
                  placement="top"
                >
                  <i className="icon-info-circle"></i>
                </Tooltip>
                &nbsp;Recruiting Start Date
              </div>
            }
            name="recruitingStartDate"
            rules={[
              {
                required: true,
                message: "Please select start date",
              },
            ]}
          >
            <DatePicker
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              format={K.DateFormat.DashUSFormat}
              // disabledDate={disableFutureDate}
              className="datePickerModal"
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={12}>
          <Form.Item
            label="ATS"
            name="atsLookupId"
            rules={[
              {
                required: true,
                message: "Please select ATS",
              },
            ]}
          >
            <Select
              showSearch
              allowClear
              placeholder="Select ATS"
              optionFilterProp="children"
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            >
              {getIsVisibleLookups(
                lookupTables.ats,
                editData?.atsLookUp?.id,
              ).map((item) => (
                <Option
                  key={item.id}
                  value={item.id}
                  disabled={item.isVisible === 1 ? false : true}
                >
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={12}>
          <Form.Item
            label="ATS ID"
            name="extAtsId"
            rules={[noTrailingSpaceAllowedRule()]}
          >
            <Input placeholder="Enter ATS ID" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={12}>
          <Form.Item label="ATS Secondary" name="atsLookupSecondaryId">
            <Select
              showSearch
              allowClear
              optionFilterProp="children"
              placeholder="Select ATS Secondary"
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            >
              {getIsVisibleLookups(
                lookupTables.ats,
                editData?.atsLookUpSecondary?.id,
              ).map((item) => (
                <Option
                  key={item.id}
                  value={item.id}
                  disabled={item.isVisible === 1 ? false : true}
                >
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={12}>
          <Form.Item
            label="Secondary ATS ID"
            name="extSecondaryAtsId"
            rules={[noTrailingSpaceAllowedRule()]}
          >
            <Input placeholder="Enter Secondary ATS ID" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={12} i>
          <Form.Item
            label="Job Status"
            name="jobStatusesId"
            rules={[
              {
                required: true,
                message: "Please select job status.",
              },
            ]}
          >
            <Select
              showSearch
              allowClear
              placeholder="Select Job Status"
              optionFilterProp="children"
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            >
              {getIsVisibleLookups(
                lookupTables.jobStatus,
                editData?.jobStatus?.id,
              ).map((item) => (
                <Option
                  key={item.id}
                  value={item.id}
                  disabled={item.isVisible === 1 ? false : true}
                >
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {/* @Client Lookup */}
        <Col xs={24} sm={12}>
          <Form.Item label="Job Department" name="jobDepartmentsId">
            <Select
              showSearch
              allowClear
              placeholder="Select Job Departments"
              optionFilterProp="children"
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            >
              {getIsVisibleLookups(
                lookupTables.jobDepartment.filter(
                  (lookup) => parseInt(lookup.clientId) === parseInt(clientId),
                ),
                editData?.jobDepartment?.id,
              ).map((item) => (
                <Option
                  key={item.id}
                  value={item.id}
                  disabled={item.isVisible === 1 ? false : true}
                >
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {/* @Client Lookup */}
        <Col xs={24} sm={12}>
          <Form.Item label="Job Group" name="jobGroupsId">
            <Select
              showSearch
              allowClear
              placeholder="Select Job Groups"
              optionFilterProp="children"
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            >
              {getIsVisibleLookups(
                lookupTables.jobGroup.filter(
                  (lookup) => parseInt(lookup.clientId) === parseInt(clientId),
                ),
                editData?.jobGroup?.id,
              ).map((item) => (
                <Option
                  key={item.id}
                  value={item.id}
                  disabled={item.isVisible === 1 ? false : true}
                >
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {!editData && (
          <Col xs={24} sm={12}>
            <Form.Item
              label="Job Original Openings"
              name="jobOriginalOpenings"
              rules={[
                {
                  required: true,
                  message: "Please enter job openings!",
                },
              ]}
            >
              <InputNumber
                min={1}
                className="modalInput w-100"
                placeholder="Enter Openings"
              />
            </Form.Item>
          </Col>
        )}

        <Col xs={24} sm={12}>
          <Form.Item
            label="Min Pay"
            name="minPay"
            rules={[
              { required: true, message: "Please enter min pay" },
              () => ({
                validator(_, value) {
                  return validateStartingFields(value, "maxPay");
                },
              }),
            ]}
          >
            <InputNumber
              min={0}
              className="modalInput w-100"
              formatter={formatter}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Max Pay"
            name="maxPay"
            rules={[
              { required: true, message: "Please enter max pay" },
              () => ({
                validator(_, value) {
                  return validateEndingFields(value, "minPay");
                },
              }),
            ]}
          >
            <InputNumber
              min={0}
              className="modalInput w-100"
              formatter={formatter}
            />
          </Form.Item>
        </Col>
        {udfSlice[K.Redux.JobSourceUdf]?.map((item) => {
          return (
            <Col xs={24} sm={12}>
              <DisplayUDF
                isFormItem={true}
                title={item.label}
                fieldTypeId={item.fieldTypeId}
                udfId={item.id}
                isRequired={item.isRequired}
                form={form}
                formItemName={item.label}
                options={
                  item.udfMultiValueFieldTypeVal.length
                    ? item.udfMultiValueFieldTypeVal
                    : []
                }
              />
            </Col>
          );
        })}
        <Col span={24}>
          <Form.Item
            name="isWorkFromHome"
            valuePropName="checked"
            className="checkboxModal"
          >
            <Checkbox>WFH</Checkbox>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
