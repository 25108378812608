import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pipeline: {
    filter: {
      manager: {
        selected: { option: null },
      },
      recruiter: {
        selected: { option: null },
      },
      department: {
        selected: { option: null },
      },
      location: {
        selected: { option: null },
      },
    },
  },
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setFilterManagerOption: (state, action) => {
      state.pipeline.filter.manager.selected.option = action.payload;
    },
    setFilterRecruiterOption: (state, action) => {
      state.pipeline.filter.recruiter.selected.option = action.payload;
    },
    setFilterDepartmentOption: (state, action) => {
      state.pipeline.filter.department.selected.option = action.payload;
    },
    setFilterLocationOption: (state, action) => {
      state.pipeline.filter.location.selected.option = action.payload;
    },
  },
});

export const {
  setFilterManagerOption,
  setFilterRecruiterOption,
  setFilterDepartmentOption,
  setFilterLocationOption,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
