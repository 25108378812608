import { DeleteOutlined } from "@ant-design/icons";
import { Button, message } from "antd";
import Client from "../../redux/models/client";
import { useState } from "react";

export const DeleteAttachmentButton = ({ item, onSuccess }) => {
  const [loading, setLoading] = useState(false);

  const deleteAttachment = async () => {
    try {
      setLoading(true);
      await Client.deleteFileFromS3(item.attachment);
      await Client.deleteAttachement(item.id);
      message.success("Attachment Deleted Successfully");
      onSuccess();
    } catch (error) {
      console.log(error);
      message.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button type="link" disabled={loading} onClick={() => deleteAttachment()}>
      <DeleteOutlined style={{ fontSize: 14, color: "red" }} />
    </Button>
  );
};
