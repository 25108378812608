import { Alert, Form, message, Modal, Select, Spin } from "antd";
import { useEffect, useState } from "react";

import Job from "redux/models/job";

import "../clients/create/jobCard.scss";
import Candidate from "redux/models/candidate";
import JobSelectOption from "common/components/jobSelect/jobSelect";
import { customJobHandleSearch } from "utilities/generalUtility";
import { selectGlobalSelector } from "redux/slices/globalSelectorSlice";
import { useSelector } from "react-redux";

const { Option } = Select;
export default function TransferCandidateJob({
  jobId,
  candidateActivity,
  transferCandidate,
  setTransferCandidate,
  setJobData,
  userClients,
  onActivityModalClose,
  clientId,
}) {
  const [transferWarning, setTransferWarning] = useState(false);
  const [form] = Form.useForm();
  const [jobList, setJobList] = useState([]);
  const [isJobsLoading, setIsJobsLoading] = useState(false);
  const [isTransferLoading, setIsTransferLoading] = useState(false);

  const globalSelector = useSelector(selectGlobalSelector);

  const transferCancelHandler = () => {
    form.resetFields();
    setTransferCandidate((prev) => {
      return { ...prev, showModal: false, transferResponse: null };
    });
  };

  const afterModalClose = () => {
    form.resetFields();
    setTransferCandidate((prev) => {
      return { ...prev, showModal: false, transferResponse: null };
    });
  };

  const transferJob = async (jobSourceId, candidateJobEntryId) => {
    try {
      setIsTransferLoading(true);

      const body = {
        jobSourceId: jobId,
        transferJobSourceId: jobSourceId,
        candidateJobEntryId: candidateJobEntryId,
        candidateId: candidateActivity.candidatesId,
      };

      await Candidate.transferCandiateJob(body);

      onActivityModalClose();
      //close delete modal
      setTransferCandidate((prev) => {
        return { ...prev, showModal: false, transferResponse: null };
      });
      //update canddiate listing
      setJobData((prev) => {
        return { ...prev, shouldUpdateListing: !prev.shouldUpdateListing };
      });
      Job.syncCandidateTTM(+jobId);
      message.success("Candidate transfered successfully");
    } catch (error) {
      setTransferCandidate((prev) => {
        return { ...prev, transferResponse: error.message };
      });
    } finally {
      setIsTransferLoading(false);
    }
  };

  const getJobsByClientIds = async () => {
    setIsJobsLoading(true);
    try {
      const body = {
        clientIds:
          globalSelector.selectedClients.length > 0
            ? globalSelector.selectedClients.map((id) => id)
            : userClients.map(({ id }) => id),
        isCurrentOpeningGreaterThenZero: 1,
      };
      const res = await Job.getOnlyJobsByClientIds(body, false);

      setJobList(res.filter(({ id }) => +jobId !== id));
    } catch (err) {
      console.error(err);
    } finally {
      setIsJobsLoading(false);
    }
  };

  useEffect(() => {
    if (transferCandidate.showModal) getJobsByClientIds();
  }, [transferCandidate.showModal]);

  const onFinish = async (values) => {
    transferJob(values.jobId, candidateActivity.id);
  };

  const onChange = (event, record) => {
    const jobClientId = jobList.find((item) => item.id === event);
    console.log("jobClientId", {
      jobClient: jobClientId.clientsId,
      client: clientId,
      con: jobClientId.clientsId !== +clientId,
    });
    setTransferWarning(jobClientId.clientsId !== +clientId);
  };

  return (
    <Modal
      centered
      width={568}
      okText="Transfer"
      title="Transfer Candidate"
      className="s2-theme-style"
      wrapClassName="vertical-center-modal"
      open={transferCandidate.showModal}
      closeIcon={<i className="icon-closeable"></i>}
      okButtonProps={{
        loading: isTransferLoading,
      }}
      onOk={() => form.submit()}
      onCancel={transferCancelHandler}
      afterClose={afterModalClose}
      destroyOnClose
    >
      <Spin className="relative-spinner" spinning={isJobsLoading}>
        <div>
          <Form form={form} onFinish={onFinish} layout="vertical">
            {transferWarning ? (
              <Alert
                showIcon
                type="warning"
                className="warningAlert"
                message="Caution: Transferring Candidate to Another Client Job "
                description={
                  "Transferring a candidate to another client job may result in the deletion of all entered user defined fields."
                }
              />
            ) : null}
            <Form.Item
              label="Transfer to"
              name="jobId"
              rules={[{ required: true, message: "Please select a job !" }]}
            >
              <Select
                showSearch
                allowClear
                onChange={onChange}
                optionFilterProp="children"
                filterOption={customJobHandleSearch}
                placeholder="Select a Job"
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              >
                {jobList.map(({ id, title, client, jobLocation, extAtsId }) => (
                  <Option key={id} value={id}>
                    <JobSelectOption
                      title={title}
                      location={jobLocation.name}
                      client={client}
                      extAtsId={extAtsId}
                    >
                      {title}
                    </JobSelectOption>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </div>
      </Spin>
    </Modal>
  );
}
