import {
  Col,
  DatePicker,
  Form,
  message,
  Modal,
  PageHeader,
  Row,
  Select,
  Typography,
} from "antd";

import {
  createdAtRenderer,
  candidateName,
  tooltipCreatedAtRenderer,
  tooltipCandidateName,
  commissionAmountRenderer,
  toolTipCommissionAmountRenderer,
  toolTipCommissionEarnedAmountRenderer,
  commissionEarnedAmountRenderer,
  tooltipCandidateStartDate,
  candidateStartDate,
} from "config/rendererConfig";
import LayoutCss from "layout/layout.module.scss";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BillingApproval from "redux/models/billingApproval";
import CommissionPlan from "redux/models/commissionPlan";
import User from "redux/models/user";
import {
  selectGlobalSelector,
  setGlobalRecruiters,
} from "redux/slices/globalSelectorSlice";
import { selectUser } from "redux/slices/userSlice";
import K from "utilities/constants";
import { disabledDate } from "utilities/dateUtility";

import {
  displayDollar,
  displayHire,
  filterColumnListing,
} from "utilities/tableUtility";
import "../submitToBilling/submitToBilling.scss";
import CommissionApprovalDetails from "./commissionDetails";
import CommissionApprovalComponent from "./commissionApprovalComponent";

const { Title } = Typography;

export default function CommissionApproval() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const currentIndexRef = useRef(null);
  const recruiterBillingThreshold = useRef(null);

  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [isStatementCreating, setIsStatementCreating] = useState(false);

  const userSlice = useSelector(selectUser);
  const globalSelector = useSelector(selectGlobalSelector);

  const [selectedRecruiter, setSelectedRecruiter] = useState([]);

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "ID",
      field: "id",
      sortable: true,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      suppressColumnsToolPanel: true,
    },
    {
      headerName: "Client",
      field: "submittedJobBill.client.name",
      sortable: true,
      tooltipField: "submittedJobBill.client.name",
    },
    {
      headerName: "Candidate",
      field: "submittedJobBill.candidateName",
      sortable: true,
      tooltipField: "submittedJobBill.candidateName",
      tooltipComponent: tooltipCandidateName,
      cellRenderer: candidateName,
    },
    {
      headerName: "Start Date",
      field: "submittedJobBill.candidateJobEntry",
      sortable: true,
      tooltipField: "submittedJobBill.candidateJobEntry",
      tooltipComponent: tooltipCandidateStartDate,
      cellRenderer: candidateStartDate,
    },
    {
      headerName: "Job Title",
      field: "submittedJobBill.jobTitle",
      sortable: true,
      tooltipField: "submittedJobBill.jobTitle",
    },
    {
      headerName: "Billing Type",
      field: "submittedJobBill.billingTypeName",
      sortable: true,
      tooltipField: "submittedJobBill.billingTypeName",
    },
    {
      headerName: "Amount",
      field: "thresholdMaker",
      sortable: true,
      tooltipField: "thresholdMaker",
      tooltipComponent: toolTipCommissionAmountRenderer,
      cellRenderer: commissionAmountRenderer,
    },
    {
      headerName: "Commission Earned",
      field: "duplicateAmount",
      sortable: true,
      tooltipField: "duplicateAmount",
      tooltipComponent: toolTipCommissionEarnedAmountRenderer,
      cellRenderer: commissionEarnedAmountRenderer,
    },
    {
      headerName: "Create Date",
      field: "createdAt",
      sortable: true,
      tooltipField: "createdAt",
      tooltipComponent: tooltipCreatedAtRenderer,
      cellRenderer: createdAtRenderer,
    },
  ]);
  const [pageStates, setPageStates] = useState({
    listing: {},
    checkedRows: {},
    selectedRecord: null,
    isModalVisible: false,
    conversionHistory: [],
    isEditMode: false,
    isDetailModalVisible: false,
  });
  const [dataList, setDataList] = useState({
    listing: [],
    shouldUpdate: false,
    loading: false,
  });

  const toggleEdit = () => {
    setPageStates((prev) => ({ ...prev, isEditMode: !prev.isEditMode }));
  };
  const onRecruiterChange = (value) => {
    dispatch(setGlobalRecruiters(value));

    const recruiters = userSlice.recruiters.filter((id) => value.includes(+id));

    console.log(
      "Selected Recruiter",
      selectedRecruiter,
      globalSelector.selectedRecruiters,
    );
    setSelectedRecruiter([...recruiters]);
  };

  const editAmount = async () => {
    const payload = {
      commissionApprovalId: pageStates.selectedRecord.id,
      duplicateAmount: +inputRef.current.value,
      currency: "USD",
    };
    try {
      const res = await CommissionPlan.editCommissionApproval(payload);
      const history = await getBillHistory({
        type: K.Invoice.Type.Commission,
        billingId: res.id,
      });
      getCommissionApprovals([pageStates.selectedRecord.recrutorId]);
      setPageStates((prev) => ({
        ...prev,
        selectedRecord: {
          ...res,
          submittedJobBill: prev.selectedRecord.submittedJobBill,
        },
        conversionHistory: history,
        isEditMode: !prev.isEditMode,
      }));
    } catch (err) {
      message.error("Failed to edit amount.");
      console.error(err);
    }
  };
  const createCommissionStatement = async (values) => {
    const { selected, recruiterId, thresholdType } =
      pageStates.checkedRows[currentIndexRef.current];
    const { deliveryDate, ...restValues } = values;
    try {
      setIsStatementCreating(true);
      await CommissionPlan.createCommissionStatement({
        ...restValues,
        recruiterId,
        commissionApproval: selected,
        deliveryDate: deliveryDate.format(K.DateFormat.Response),
        billingThreshold: recruiterBillingThreshold.current,
        thresholdType,
      });
      message.success("Commission Statement Created");
      removeStatementFromListing(recruiterId, selected);
      form.resetFields();
    } catch (err) {
      console.error(err);
    } finally {
      setIsStatementCreating(false);
    }
  };

  const removeStatementFromListing = (recruiterId, commissionApprovals) => {
    setPageStates((prev) => {
      const { listing, ...rest } = prev;
      let updatedObj = {};
      const updated = Object.entries(listing).map((el) => {
        el = el[1];

        if (el.recruiterBasic.id === recruiterId) {
          const { defaultBillingThreshold, recruiterBasic } = el;
          const approvals = recruiterBasic.approvals.filter(
            (item) => !commissionApprovals.includes(item.id),
          );
          recruiterBasic.approvals = approvals;
          updatedObj = {
            [`${el.recruiterBasic.id}`]: {
              defaultBillingThreshold,
              recruiterBasic,
            },
          };
          return {
            [`${el.recruiterBasic.id}`]: {
              defaultBillingThreshold,
              recruiterBasic,
            },
          };
        }

        updatedObj = { [`${el.recruiterBasic.id}`]: el };

        return {
          [`${el.recruiterBasic.id}`]: el[1],
        };
      });

      return {
        ...rest,
        listing: { ...listing, ...updatedObj },
        isModalVisible: false,
        checkedRows: {
          ...prev.checkedRows,
          [currentIndexRef.current]: {
            total: 0,
            recruiterId: null,
            selected: [],
          },
        },
      };
    });
  };

  const getBillHistory = async (payload) => {
    try {
      const res = await BillingApproval.getSubmittedBillHistory(payload);
      return res;
    } catch (err) {
      console.error(err);
    }
  };

  const getCommissionApprovals = async (recruiterID) => {
    setDataList((prev) => ({ ...prev, loading: true }));
    try {
      const res = await CommissionPlan.getCommissionApproval(recruiterID, true);
      const config = {};

      res.forEach((element) => {
        config[element.recruiterBasic.id] = {
          total: 0,
          recruiterId: null,
          selected: [],
        };
      });

      setPageStates((prev) => {
        return {
          ...prev,
          listing: { ...prev.listing, [`${res[0].recruiterBasic.id}`]: res[0] },
          checkedRows: { ...prev.checkedRows, ...config },
        };
      });
    } catch (err) {
      console.error(err);
    }
    setDataList((prev) => ({ ...prev, loading: false }));
  };

  const onColumnVisible = async (event) => {
    if (event.source === "gridOptionsChanged") return;

    const columnState = event.columnApi.getColumnState();
    setColumnDefs((prev) =>
      prev.map((el, i) => ({
        ...el,
        hide: columnState[i].hide,
        pinned: columnState[i].pinned,
      })),
    );

    const cols = filterColumnListing(columnDefs, columnState);
    try {
      await User.saveTableVisibleColumn({
        usersId: userSlice.details.id,
        configJson: JSON.stringify(cols),
        tableName: K.AgGridTable.Keys.CommissionApprovalsVisibleColumn,
      });
      message.success("Column configration saved");
    } catch (err) {
      console.error(err);
    }
  };

  const getColumnsConfigrations = async () => {
    try {
      const res = await User.getConfigrations(
        K.AgGridTable.Keys.CommissionApprovalsVisibleColumn,
      );
      const parsed = JSON.parse(res.config).map((item) => {
        if (item.field === "id")
          return {
            ...item,
            checkboxSelection: true,
            headerCheckboxSelection: true,
          };
        if (item.field === "duplicateAmount")
          return {
            ...item,
            cellRenderer: commissionEarnedAmountRenderer,
            tooltipComponent: toolTipCommissionEarnedAmountRenderer,
          };
        if (item.field === "thresholdMaker")
          return {
            ...item,
            cellRenderer: commissionAmountRenderer,
            tooltipComponent: toolTipCommissionAmountRenderer,
          };
        if (item.field === "createdAt")
          return {
            ...item,
            cellRenderer: createdAtRenderer,
            tooltipComponent: tooltipCreatedAtRenderer,
          };
        if (item.field === "candidateName")
          return {
            ...item,
            cellRenderer: candidateName,
            tooltipComponent: tooltipCandidateName,
          };
        if (item.field === "submittedJobBill.candidateJobEntry")
          return {
            ...item,
            tooltipComponent: tooltipCandidateStartDate,
            cellRenderer: candidateStartDate,
          };

        return item;
      });
      setColumnDefs(parsed);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getColumnsConfigrations();
  }, []);

  const updateColumns = async (event) => {
    const cols = filterColumnListing(
      columnDefs,
      event.columnApi.getColumnState(),
    );
    if (shouldUpdate)
      try {
        await User.saveColumnSort({
          usersId: userSlice.detail.id,
          tableName: K.AgGridTable.Keys.CommissionApprovalsVisibleColumn,
          configJson: JSON.stringify(cols),
        });
        message.success("Columns order saved successfully");
      } catch (err) {
        console.error(err);
      }
    else setShouldUpdate(true);
  };

  const fetchRecruiterTitle = (recruiterID) => {
    return userSlice.recruiters.find((item) => item.id === recruiterID).name;
  };

  return (
    <>
      <PageHeader
        ghost={false}
        title="Commission Approval"
        className={`${LayoutCss.appPageHeader} ${LayoutCss.pageHeader}`}
        extra={
          <Select
            mode="multiple"
            showArrow
            maxTagCount={1}
            maxTagPlaceholder={(omittedValues) => {
              return (
                <span className="custom-max-tag-count">
                  +{omittedValues.length}
                </span>
              );
            }}
            allowClear
            showSearch
            optionFilterProp="label"
            placeholder="Select Recruiters"
            className="commision-select"
            defaultValue={globalSelector.selectedRecruiters}
            options={userSlice.recruiters.map((item) => ({
              value: item.id,
              label: item.name,
              disabled:
                globalSelector.selectedRecruiters.length >= 10 &&
                !globalSelector.selectedRecruiters.includes(item.id),
            }))}
            onChange={onRecruiterChange}
          />
        }
      />
      {globalSelector.selectedRecruiters.length > 0 ? (
        userSlice.recruiters
          .filter((i) => {
            return globalSelector.selectedRecruiters.includes(i.id);
          })
          .map((item, index) => {
            return (
              <CommissionApprovalComponent
                form={form}
                currentIndexRef={currentIndexRef}
                recruiterBillingThreshold={recruiterBillingThreshold}
                pageStates={pageStates}
                setPageStates={setPageStates}
                columnDefs={columnDefs}
                item={item}
                dataList={dataList}
                setDataList={setDataList}
                onColumnVisible={onColumnVisible}
                fetchRecruiterTitle={fetchRecruiterTitle}
                updateColumns={updateColumns}
              />
            );
          })
      ) : (
        <Row justify="center" align="middle" style={{ marginTop: "100px" }}>
          <Col>
            <div style={{ fontSize: "20px", textAlign: "center" }}>
              Please select recruiters to view commission approvals
            </div>
          </Col>
        </Row>
      )}

      <Modal
        centered
        width={572}
        className="clientBillingModal modal-overflow-auto"
        title={pageStates.selectedRecord?.submittedJobBill.client.name}
        footer={false}
        open={pageStates.isDetailModalVisible}
        onOk={() =>
          setPageStates((prev) => ({ ...prev, isDetailModalVisible: false }))
        }
        onCancel={() =>
          setPageStates((prev) => ({ ...prev, isDetailModalVisible: false }))
        }
        closeIcon={
          <span className="closeIcon">
            <i className="icon-closeable" />
          </span>
        }
      >
        <CommissionApprovalDetails
          inputRef={inputRef}
          pageStates={pageStates}
          toggleEdit={toggleEdit}
          editAmount={editAmount}
        />
      </Modal>

      <Modal
        title="Create Statement"
        className="s2-theme-style"
        open={pageStates.isModalVisible}
        destroyOnClose
        centered
        width={400}
        onOk={form.submit}
        onCancel={() => {
          setPageStates((prev) => ({ ...prev, isModalVisible: false }));
        }}
        okButtonProps={{ loading: isStatementCreating }}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={createCommissionStatement}
          rules={[{ required: true, message: "status is required!" }]}
        >
          <Form.Item
            name="status"
            label="Status"
            rules={[{ required: true, message: "status is required!" }]}
          >
            <Select
              showSearch
              optionFilterProp="label"
              placeholder="Status"
              options={[
                { label: "Eligible", value: "ELIGIBLE" },
                { label: "Not Eligible", value: "NOT_ELIGIBLE" },
              ]}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            />
          </Form.Item>
          <Form.Item
            label="Due Date"
            name="deliveryDate"
            rules={[{ required: true, message: "due date is required!" }]}
            initialValue={moment()}
          >
            <DatePicker
              disabledDate={disabledDate}
              placeholder="Due Date"
              format={K.DateFormat.DashUSFormat}
            />
          </Form.Item>
          <div className="text-right">
            <Title level={5} className="mb-0">
              Total:{" "}
              {currentIndexRef.current !== null &&
              pageStates.checkedRows[currentIndexRef.current]?.thresholdType ===
                K.CommissionThresholdType.Amount
                ? displayDollar(
                    currentIndexRef.current !== null
                      ? pageStates.checkedRows[currentIndexRef.current]?.total
                      : 0,
                  )
                : displayHire(
                    currentIndexRef.current !== null
                      ? pageStates.checkedRows[currentIndexRef.current]?.total
                      : 0,
                  )}
            </Title>
          </div>
        </Form>
      </Modal>
    </>
  );
}
