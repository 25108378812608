import { Tooltip as AntTooltip } from "antd";

const Tooltip = ({ text, children, ...props }) => {
  return (
    <AntTooltip
      title={<div style={{ color: "#181d1f" }}>{text}</div>}
      showArrow={false}
      color={"#fafafa"}
      placement={"bottomRight"}
      {...props}
    >
      <div
        className="ellipsis"
        style={{
          margin: "0 auto",
          maxWidth: 100,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          cursor: "pointer",
        }}
      >
        {children}
      </div>
    </AntTooltip>
  );
};
export default Tooltip;
