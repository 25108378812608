import { InfoCircleOutlined } from "@ant-design/icons";
import { Card, Col, Divider, Row, Tooltip, Typography } from "antd";

import K from "utilities/constants";

import { displayPercentage } from "utilities/tableUtility";

const { Title, Text } = Typography;

export default function PipelineDashboardSummary({
  isLoading,
  dashboardData,
  // requisitionDetail,
  getJobDetailByStatus,
  statusRef,
}) {
  function DisplayStatusCount({
    statusName,
    statusCount,
    statusId,
    subStatusName = "",
    subStatusId = null,
    displayStatusName,
    defination = "Drill Down",
    isDrill = true,
  }) {
    return (
      <div
        className="recruitmentPipelineSec withdrewCol openingCol "
        onClick={() => {
          if (dashboardData.candidateSummary?.jobs > 0 && isDrill) {
            statusRef.current = {
              statusId,
              statusCount: statusCount ?? 0,
              statusName: statusName ?? "",
              subStatusName: subStatusName ?? "",
              subStatusId,
              displayStatusName,
            };

            getJobDetailByStatus(statusId, subStatusId);
          }
        }}
        style={{
          cursor:
            dashboardData.candidateSummary?.jobs?.length > 0
              ? "pointer"
              : "default",
        }}
      >
        <Title level={5} className={isDrill ? "hover-underlined" : ""}>
          {displayStatusName}
          <Tooltip title={defination} color={K.TooltipColor}>
            <InfoCircleOutlined />
          </Tooltip>
        </Title>
        <Text>{statusCount}</Text>
      </div>
    );
  }

  return (
    <Card
      loading={isLoading}
      className="recruitmentPipelineCard recruitmentPdf"
      title="Summary"
    >
      <Row gutter={[30, 30]} className="recruitmentPipelineRow">
        <Col xs={12} sm={8} md={6} xl={3}>
          <div className="recruitmentPipelineSec">
            <Title level={5}>Requisitions</Title>
            <Text>{dashboardData.candidateSummary?.jobs}</Text>
          </div>
        </Col>
        <Col xs={12} sm={8} md={6} xl={3}>
          <div className="recruitmentPipelineSec openingCol">
            <Title level={5}>Openings</Title>
            <Text>{dashboardData.candidateSummary?.openings}</Text>
          </div>
        </Col>
        <Col xs={12} sm={8} md={6} xl={3}>
          {/* <div className="recruitmentPipelineSec withdrewCol">
                <Title level={5}>Prospects</Title>
                <Text>{dashboardData.candidateSummary?.prospects}</Text>
              </div> */}
          <DisplayStatusCount
            statusCount={dashboardData.candidateSummary?.prospects}
            statusId={K.Status.Prospect.id}
            statusName={K.Status.Prospect.name}
            defination={K.StatusDefinations.Prospect}
            displayStatusName={K.Status.Prospect.name}
          />
        </Col>
        <Col xs={12} sm={8} md={6} xl={3}>
          <div className="recruitmentPipelineSec candidateCol openingCol">
            {/* <Title level={5}>Active Candidates</Title>
              <Text>{dashboardData.candidateSummary?.activeCandidates}</Text> */}
            <DisplayStatusCount
              statusCount={dashboardData.candidateSummary?.activeCandidates}
              displayStatusName="Active Candidates"
              defination={K.StatusDefinations.ActiveCandidate}
              isDrill={false}
            />
          </div>
        </Col>
        <Col xs={12} sm={8} md={6} xl={3}>
          <div className="recruitmentPipelineSec">
            <Title level={5}>Requisition Coverage</Title>
            <Text>
              {dashboardData.candidateSummary?.requisitionCoverage
                ? displayPercentage(
                    dashboardData.candidateSummary?.requisitionCoverage.toFixed(
                      2,
                    ),
                  )
                : K.NullPlaceholder}
            </Text>
          </div>
        </Col>
      </Row>
      <Divider className="rowDivider" />
      <Title className="activeCandidate" level={5}>
        Active Candidate Detail
      </Title>

      <Row gutter={[30, 30]} className="recruitmentPipelineRow">
        <Col xs={12} sm={8} md={6} xl={3}>
          {/* <div className="recruitmentPipelineSec">
                <Title level={5}>Under Review</Title>
                <Text>{dashboardData.candidateSummary?.underReview}</Text>
              </div> */}
          <DisplayStatusCount
            statusCount={dashboardData.candidateSummary?.underReview}
            statusId={K.Status.Candidates.id}
            statusName={K.Status.Candidates.name}
            displayStatusName="Under Review"
            defination={K.StatusDefinations.UnderReview}
          />
        </Col>
        <Col xs={12} sm={8} md={6} xl={3}>
          {/* <div className="recruitmentPipelineSec openingCol">
                <Title level={5}>S2 Interview</Title>
                <Text>{dashboardData.candidateSummary?.s2Interview}</Text>
              </div> */}
          <DisplayStatusCount
            statusCount={dashboardData.candidateSummary?.s2Interview}
            statusId={K.Status.S2Interview.id}
            statusName={K.Status.S2Interview.name}
            displayStatusName={K.Status.S2Interview.name}
            defination={K.StatusDefinations.S2Interview}
          />
        </Col>
        <Col xs={12} sm={8} md={6} xl={3}>
          {/* <div className="recruitmentPipelineSec withdrewCol">
                <Title level={5}>Submittal</Title>
                <Text>
                  <Text>{dashboardData.candidateSummary?.submittal}</Text>
                </Text>
              </div> */}
          <DisplayStatusCount
            statusCount={dashboardData.candidateSummary?.submittal}
            statusId={K.Status.Submitted.id}
            statusName={K.Status.Submitted.name}
            displayStatusName="Submittal"
            defination={K.StatusDefinations.Submittal}
          />
        </Col>
        <Col xs={12} sm={8} md={6} xl={3}>
          {/* <div className="recruitmentPipelineSec candidateCol openingCol">
                <Title level={5}>Client Interview</Title>
                <Text>{dashboardData.candidateSummary?.clientInterview}</Text>
              </div> */}

          <DisplayStatusCount
            statusCount={dashboardData.candidateSummary?.clientInterview}
            statusId={K.Status.ClientInterview.id}
            statusName={`${K.Status.ClientInterview.name}`}
            displayStatusName={`${K.Status.ClientInterview.name}`}
            defination={K.StatusDefinations.ClientInterview}
          />
        </Col>
        <Col xs={12} sm={8} md={6} xl={3}>
          {/* <div className="recruitmentPipelineSec">
                <Title level={5}>Offer Extended</Title>
                <Text>{dashboardData.candidateSummary?.offerExtended}</Text>
              </div> */}

          <DisplayStatusCount
            statusCount={dashboardData.candidateSummary?.offerExtended}
            statusId={K.Status.Offer.id}
            statusName={K.Status.Offer.name}
            subStatusName={`${K.Status.Offer.subStatus.Extended.name}`}
            subStatusId={K.Status.Offer.subStatus.Extended.id}
            displayStatusName={`${K.Status.Offer.name} ${K.Status.Offer.subStatus.Extended.name}`}
            defination={K.StatusDefinations.OfferExtended}
          />
        </Col>
        <Col xs={12} sm={8} md={6} xl={3}>
          <DisplayStatusCount
            statusCount={dashboardData.candidateSummary?.offerAccepted}
            statusId={K.Status.Offer.id}
            statusName={K.Status.Offer.name}
            subStatusName={`${K.Status.Offer.subStatus.Accepted.name}`}
            subStatusId={K.Status.Offer.subStatus.Accepted.id}
            displayStatusName={`${K.Status.Offer.name} ${K.Status.Offer.subStatus.Accepted.name}`}
            defination={K.StatusDefinations.OfferAccepted}
          />
        </Col>
        <Col xs={12} sm={8} md={6} xl={3}>
          <DisplayStatusCount
            statusCount={dashboardData.candidateSummary?.hired}
            statusId={K.Status.Hired.id}
            statusName={K.Status.Hired.name}
            displayStatusName={K.Status.Hired.name}
            defination={K.StatusDefinations.Hired}
          />
        </Col>
      </Row>
    </Card>
  );
}
