import { Form, InputNumber, message, Modal, Typography } from "antd";
import { useEffect, useState } from "react";
import Candidate from "redux/models/candidate";
import K from "utilities/constants";

const { Text, Paragraph } = Typography;

export default function OfferedSalaryModal({
  candidateActivity,
  offeredSalaryModal,
  setCandidateActivity,
  setOfferedSalaryModal,
}) {
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const formatter = (value) =>
    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const handleCancel = () => {
    setOfferedSalaryModal((prev) => ({
      ...prev,
      currentStatus: null,
      showModal: false,
    }));
    form.resetFields();
  };

  const onFinish = async (values) => {
    values.candidateEntryId = candidateActivity.id;
    try {
      setLoading(true);
      const res = await Candidate.editOfferedSalary(values);
      setCandidateActivity((prev) => ({ ...prev, salary: res.salary }));
      message.success("Salary updated successfully.");
      handleCancel();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // if (offeredSalaryModal.currentStatus === K.CandidateStatus.Hired)
    form.setFieldValue("offeredSalary", candidateActivity.salary);
    // else form.resetFields();
  }, [offeredSalaryModal.currentStatus]);

  return (
    <Modal
      open={offeredSalaryModal.showModal}
      centered
      okText="Submit"
      title={
        offeredSalaryModal.currentStatus === K.CandidateStatus.Hired &&
        candidateActivity.salary
          ? "Confirm Offered Salary"
          : "Offered Salary Accepted"
      }
      className="s2-theme-style modal-overflow-auto"
      onCancel={handleCancel}
      onOk={form.submit}
      okButtonProps={{ loading }}
    >
      {offeredSalaryModal.currentStatus === K.CandidateStatus.Offer ? (
        <Text>
          Please enter the offered salary <strong>accepted</strong> by the
          candidate.
        </Text>
      ) : (
        <Text>
          Please verify the offered salary <strong>accepted</strong> by the
          candidate.
        </Text>
      )}

      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item label="Offered Salary" name="offeredSalary">
          <InputNumber min={0} formatter={formatter} />
        </Form.Item>
      </Form>

      {offeredSalaryModal.currentStatus === K.CandidateStatus.Offer ? (
        <Paragraph>
          If you don't have this information, leave the field blank and press
          submit. You will be requested to enter it again once the candidate
          moves to the Hired status.
        </Paragraph>
      ) : offeredSalaryModal.currentStatus === K.CandidateStatus.Hired &&
        candidateActivity.salary ? (
        <>
          <Text>
            Adjust the amount if the offered salary has changed and press
            submit.
          </Text>
          <Paragraph className="mt-3" style={{ color: "red" }}>
            Ensure the offered salary is correct <strong>before</strong>{" "}
            submitting as a billable record will be generated once saved.
          </Paragraph>
        </>
      ) : (
        <>
          <Text>
            Ensure the offered salary is correct before submitting as a billable
            record will be generated once saved.
          </Text>
          <Paragraph className="mt-3" style={{ color: "red" }}>
            If you don't have this information, a billable record will be
            generated using the <strong>Max Pay</strong> listed on the job
            profile. Only submit blank if you will not receive the Offered
            Salary
          </Paragraph>
        </>
      )}
    </Modal>
  );
}
