import {
  Checkbox,
  Select,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Col,
  Row,
  Divider,
} from "antd";

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import BillingJob from "redux/models/billingJobs";
import LookupTable from "redux/models/lookupTable";

import K from "utilities/constants";
import { noTrailingSpaceAllowedRule } from "utilities/generalUtility";

const { Lookup } = K.Network.URL;

const lookupTables = [
  {
    reduxKey: K.Redux.Source,
    apiEndPoint: Lookup.Sources,
  },
  {
    reduxKey: K.Redux.BillingTypes,
    apiEndPoint: Lookup.BillingTypes,
  },
];

export default function SubmitOneTimeJobBilling({
  isOpen,
  clientId,
  setIsModalVisible,
  candidateActivity,
  currencyList,
  setRefresh = null, //Its a refresh trigger in canddiate issue credit .
}) {
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { id, candidate, jobSource } = candidateActivity;

  const onFinish = async (values) => {
    values = {
      ...values,
      candidateJobEntryId: id,
      jobTitle: jobSource.title,
      jobSourceId: jobSource.id,
      clientId: clientId ? clientId : jobSource.clientsId,
    };

    try {
      setLoading(true);
      await BillingJob.submitOnetimeJobBilling(values);
      message.success("One Time Billing Submitted");
      setIsModalVisible(false);
      setRefresh && setRefresh((prev) => !prev);
      form.resetFields();
    } catch (err) {
      console.error(err);
      setIsModalVisible(false);
    } finally {
      setLoading(false);
    }
  };

  const getLookupData = async () => {
    try {
      await Promise.all([
        ...lookupTables.map(async (item) => {
          await dispatch(LookupTable.getData(item.reduxKey, item.apiEndPoint));
        }),
      ]);
    } catch (error) {
      console.error(error);
    }
  };

  const shouldCostUpdate = (prevValue, curValue) => {
    if (
      prevValue.quantity !== curValue.quantity ||
      prevValue.price !== curValue.price
    ) {
      form.setFieldValue("amount", curValue.price * curValue.quantity);
      return true;
    }
    return false;
  };

  const handleOk = () => {
    form.submit();
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  useEffect(() => {
    getLookupData();
  }, []);

  return (
    <Modal
      centered
      width={462}
      open={isOpen}
      okText="Submit"
      onOk={handleOk}
      onCancel={handleCancel}
      title="One Time Billing"
      className="s2-theme-style modal-overflow-auto"
      closeIcon={<i className="icon-closeable"></i>}
      okButtonProps={{ loading }}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Divider className="headerDvider" />

        <div className="hireFeeData">
          <Row>
            <Col xs={24} md={24} xl={24}>
              <Form.Item
                name="candidateName"
                initialValue={candidate.firstName + " " + candidate.lastName}
                label="Candidate Name"
                rules={[
                  { required: true, message: "Candidate Name is required!" },
                  noTrailingSpaceAllowedRule(),
                ]}
              >
                <Input
                  className="inputModal"
                  placeholder="Candidate Name"
                  disabled={true}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={24} xl={24}>
              <Form.Item
                name="billing_type_name"
                label="Description"
                rules={[
                  { required: true, message: "Description is required!" },
                  noTrailingSpaceAllowedRule(),
                ]}
              >
                <Input className="inputModal" placeholder="Description" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col flex="auto" xs={24} md={24} xl={24}>
              <Row align="bottom" gutter={[12, 0]}>
                <Col xs={24} md={18} xl={18}>
                  <Form.Item
                    label="Amount"
                    name={"price"}
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Amount!",
                      },
                    ]}
                  >
                    <InputNumber className="w-100" placeholder="Enter Amount" />
                  </Form.Item>
                </Col>

                <Col xs={24} md={18} xl={6}>
                  <Form.Item name={"currency"} initialValue="USD">
                    <Select
                      showSearch
                      optionFilterProp="label"
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      placeholder="Currency"
                      options={currencyList.map(({ code }) => ({
                        value: code,
                        label: code,
                      }))}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row align="bottom" gutter={[12, 0]}>
            <Col xs={24} md={24} xl={24}>
              <Form.Item
                name="quantity"
                label="Quantity"
                initialValue={1}
                rules={[{ required: true, message: "Please enter quantity" }]}
              >
                <InputNumber
                  className="inputModal"
                  placeholder="Quantity"
                  min={1}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row align="bottom" gutter={[12, 0]}>
            <Col xs={24} md={24} xl={24}>
              <Form.Item
                label="Cost"
                name="amount"
                shouldUpdate={shouldCostUpdate}
              >
                <InputNumber
                  disabled
                  className="inputModal"
                  placeholder="Cost"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row align="bottom" gutter={[12, 0]}>
            <Col xs={24} md={24} xl={24}>
              <Form.Item
                initialValue={false}
                valuePropName="checked"
                name={"isCommissionable"}
              >
                <Checkbox>Is Commissionable</Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>
    </Modal>
  );
}
