import { Button } from "antd";
import { DownloadIcon } from "../../../common/icons/DownloadIcon";
import Tooltip from "../../../common/components/Tooltip/Tooltip";

export const ExportButton = ({ onClick, disabled, isExportLimitExceeded }) => {
  const Component = () => (
    <Button
      style={{ display: "flex", alignItems: "center" }}
      type={"primary"}
      onClick={onClick}
      disabled={disabled}
      icon={<DownloadIcon style={{ display: "flex", fontSize: "14px" }} />}
    >
      Export
    </Button>
  );

  if (isExportLimitExceeded) {
    return (
      <Tooltip
        text={"Cannot export more than 10,000 engagements at once."}
        showArrow={true}
        placement={"left"}
      >
        <span>
          <Component />
        </span>
      </Tooltip>
    );
  }

  return <Component />;
};
