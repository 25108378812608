import { Divider, PageHeader, Tabs } from "antd";
import LayoutCss from "../../layout/layout.module.scss";
import { useEffect } from "react";
import { pick } from "lodash";
import { useQueryParams } from "../../hooks/useQueryParams";
import { TabsConfig } from "./tabsConfig";

export const Engagements = () => {
  const { getParam, setParam } = useQueryParams();

  const tabFromQuery = TabsConfig[getParam("tab")];
  const activeTab = tabFromQuery || TabsConfig.thisWeek;

  const tabItems = Object.values(TabsConfig).map((t) =>
    pick(t, ["key", "label"]),
  );

  const onTabChange = (tab) => {
    setParam("tab", tab);
  };

  useEffect(() => {
    if (!tabFromQuery) {
      setParam("tab", activeTab.key);
    }
  }, []);

  return (
    <>
      <PageHeader
        ghost={false}
        title="Engagements"
        footer={
          <>
            <Divider className={LayoutCss.appPageHeaderDivider} />
            <Tabs
              activeKey={activeTab.key}
              onChange={onTabChange}
              className={LayoutCss.appPageHeaderTabs}
              items={tabItems}
            />
          </>
        }
        className={LayoutCss.appPageHeader}
      />
      {activeTab.component()}
    </>
  );
};
