import { Empty, Select, Spin } from "antd";
import { useCallback, useState } from "react";
import { debounce } from "lodash";
import EngagementRecruiters from "../../../redux/models/engagementRecruiters";
import { LoadingOutlined } from "@ant-design/icons";

export const RecruitersSelect = ({ onChange, clients }) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleDropdownVisibleChange = async (open) => {
    if (open && !options.length) {
      try {
        const res = await EngagementRecruiters.get({
          client: clients,
          isActive: false,
        });
        setOptions(res.data);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }
  };

  const onRecruiterChange = useCallback(
    debounce((e, i) => {
      onChange(i.map((r) => r.id) || null);
    }, 800),
    [onChange],
  );

  return (
    <Select
      mode="multiple"
      showArrow
      allowClear
      style={{ width: "100%" }}
      maxTagTextLength={6}
      maxTagCount={1}
      placeholder={"Select Recruiter"}
      options={options}
      onChange={onRecruiterChange}
      notFoundContent={
        loading ? (
          <Spin indicator={<LoadingOutlined spin />} />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )
      }
      onDropdownVisibleChange={handleDropdownVisibleChange}
    />
  );
};
