export const selectPipelineManagerFilter = (state) =>
  state.dashboard.pipeline.filter.manager.selected.option;

export const selectPipelineRecruiterFilter = (state) =>
  state.dashboard.pipeline.filter.recruiter.selected.option;

export const selectPipelineDepartmentFilter = (state) =>
  state.dashboard.pipeline.filter.department.selected.option;

export const selectPipelineLocationFilter = (state) =>
  state.dashboard.pipeline.filter.location.selected.option;
