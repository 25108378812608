import { useState, useEffect, useRef, useMemo } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  PageHeader,
  Button,
  Card,
  Typography,
  Divider,
  Tabs,
  Row,
  Col,
  message,
  InputNumber,
  Modal,
  Form,
  Input,
  Tag,
  Popconfirm,
  Alert,
} from "antd";
import { StopOutlined } from "@ant-design/icons";
import LayoutCss from "layout/layout.module.scss";
import Candidate from "redux/models/candidate";
import UserDetailCard from "common/userDetailCard/userDetailCard";
import ViewActivity from "features/candidates/viewActivity";
import { displayDollar } from "utilities/tableUtility";
import {
  checkNullPlaceHolder,
  isPermissionPresent,
} from "utilities/generalUtility";
import K from "utilities/constants";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "redux/slices/userSlice";
import { setShouldReinitialize } from "redux/slices/candidateSlice";
import UDF from "redux/models/udf";
import { setCandidateUdf } from "redux/slices/udfSlice";
import styles1 from "../clients/create/clientsJob.module.scss";
import styles from "./candidatesDetails.module.scss";
import { ActivityTab } from "./activityTab";

const { TextArea } = Input;
const { Title, Text } = Typography;

export default function CandidatesDetails() {
  const dispatch = useDispatch();
  const jobId = useRef(null);
  const jobDetailRef = useRef(null);
  const { state } = useLocation();
  const { id } = useParams();
  const history = useHistory();
  const [reasonForm] = Form.useForm();
  const [candidateForm] = Form.useForm();
  const [candidateDetail, setCandidateDetail] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [candidateActivity, setCandidateActivity] = useState(null);
  const userSlice = useSelector(selectUser);

  const defaultActiveTab = state?.tab ?? "profile";
  const defaultActiveJobTab = state?.jobTab ?? "details";
  const defaultJobEntryId = state?.candidateJobEntryId;

  const defaultCandidateJobEntry = candidateDetail?.candidateJobEntries?.find(
    (e) => e.id === +defaultJobEntryId,
  );

  const handleCancelReasonModal = () => {
    setIsModalVisible(false);
    reasonForm.resetFields();
  };
  const handleOkReasonModal = () => {
    reasonForm.submit();
    setIsModalVisible(false);
  };
  const tagColors = {
    Prospect: "#D9D9D9",
    Candidates: "#F6A2E6",
    Submitted: "#BDD7EE",
    Offer: "#99FF99",
    Withdrew: "#F9B367",
    Declined: "#FF9999",
    Hired: "#00CC66",
    Terminated: "#FF9999",
    "S2 Interview": "#33CCFF",
    "Client Interview": "#CC99FF",
    "No Show to Day 1": "#FF9999",
    "Within Guarantee": "#FFB66D",
    "Within Retention Period": "#FFB66D",
  };

  const getCandidateDetailById = async (candidateId) => {
    try {
      const res = await Candidate.getCandidateDetail(candidateId);
      const { firstName, lastName, phoneNumber: phone, ...rest } = res;
      setCandidateDetail({
        ...rest,
        firstName,
        lastName,
        phone,
        name: [firstName, lastName].join(" "),
      });
      reasonForm.setFieldValue("id", candidateId);
    } catch (error) {
      message.error(error.message);
    }
  };

  const addToBlacklist = async (data) => {
    try {
      const res = await Candidate.addToBlacklist(data);
      setCandidateDetail((prev) => ({
        ...prev,
        isBlacklist: res.isBlacklist,
        blacklistReason: res.blacklistReason,
        id: res.id,
      }));
      reasonForm.resetFields();
    } catch (error) {
      reasonForm.resetFields();
      console.error("error", error);
    }
  };

  const removeToBlacklist = async () => {
    try {
      const res = await Candidate.removeToBlacklist({
        id,
      });
      setCandidateDetail((prev) => ({ ...prev, isBlacklist: res.isBlacklist }));
      reasonForm.resetFields();
    } catch (error) {
      console.error("error", error);
    }
  };

  useEffect(() => {
    getCandidateDetailById(id);
  }, [id]);

  const getCandidateUdfs = async (entityKeyId = null) => {
    const candidateUdfs = await UDF.getEditUdfFields({
      clientId: candidateActivity.jobSource.clientsId,
      entityId: K.UDF.Entities.Candidate.id,
      entityKey: K.UDF.Entities.Candidate.key,
      entityKeyId,
      isVisible: 1,
    });

    dispatch(setCandidateUdf(candidateUdfs));
    return candidateUdfs;
  };

  const fetchCandidateDetails = async (jobEntryId, jobSourceId, vacancies) => {
    jobId.current = jobSourceId;
    jobDetailRef.current = { vacancies: vacancies?.at(-1) };
    try {
      const res = await Candidate.getActivity(jobEntryId);

      setCandidateActivity(res);
      dispatch(setShouldReinitialize());
    } catch (err) {
      message.error("Failed to fetch candidate!");
      console.error(err);
    }
  };

  const getSubStatusColor = (job) => {
    const colorHexValue =
      job.status.name === "Terminated"
        ? tagColors[job.subStatus.name]
        : tagColors[job.status.name];
    return colorHexValue;
  };

  useEffect(() => {
    if (defaultCandidateJobEntry) {
      fetchCandidateDetails(
        defaultCandidateJobEntry.id,
        defaultCandidateJobEntry.jobSource.id,
        defaultCandidateJobEntry.jobSource.vacancies,
      );
    }
  }, [defaultCandidateJobEntry]);

  useEffect(() => {
    window.history.replaceState({}, "");
  }, []);

  return (
    candidateDetail && (
      <>
        <PageHeader
          ghost={false}
          className={LayoutCss.appPageHeader}
          onBack={history.goBack}
          title={candidateDetail.name}
          extra={
            candidateDetail.isBlacklist === 0 ? (
              <Button
                type="ghost"
                icon={<StopOutlined />}
                onClick={() => setIsModalVisible(true)}
              >
                Add to Blacklist
              </Button>
            ) : (
              <Popconfirm
                title="Are you sure ?"
                onConfirm={removeToBlacklist}
                okText="Yes"
                cancelText="No"
              >
                <Button type="ghost" icon={<StopOutlined />}>
                  Remove from Blacklist
                </Button>
              </Popconfirm>
            )
          }
        />
        <div className={styles.candidateDetailsWrapper}>
          <UserDetailCard
            styles={styles}
            userDetail={candidateDetail}
            setCandidateDetail={setCandidateDetail}
            isEditUserInfo
            onMergeCallback={getCandidateDetailById}
          />
          <Card
            className={styles.candidateInfoCard}
            bodyStyle={{ padding: "4px 0 1.5rem" }}
          >
            <Tabs
              defaultActiveKey={defaultActiveTab}
              className="candidates-info-card"
              tabBarGutter={40}
              items={[
                {
                  key: "profile",
                  permission: K.Permissions.ViewCandidateProfile,
                  label: <span className="cic-tabs-label-style">Profile</span>,
                  children: (
                    <div className={styles.blacklistAlert}>
                      <div
                        className={styles.cicTabsContent}
                        style={{ flex: 1 }}
                      >
                        <Title level={4} className={styles.personalInfoHeading}>
                          Personal Info
                        </Title>
                        <Row gutter={24} className="mb-3">
                          <Col xs={24} sm={12} xl={9}>
                            <label className={styles.icLabelStyle}>
                              Full Name
                            </label>
                            <Text className={styles.icTextStyle}>
                              {candidateDetail.name}
                            </Text>
                          </Col>
                        </Row>
                        <Row gutter={24} className="mb-3">
                          <Col xs={24} sm={12} xl={9}>
                            <label className={styles.icLabelStyle}>
                              Home Address
                            </label>
                            <Text
                              title={candidateDetail.location}
                              className={styles.icTextStyle}
                            >
                              {candidateDetail.location}
                            </Text>
                          </Col>
                        </Row>
                        <Row gutter={24} className="mb-3">
                          <Col xs={24} sm={12} xl={9}>
                            <label className={styles.icLabelStyle}>
                              Current Title
                            </label>
                            <Text
                              title={
                                candidateDetail.currentTitle ??
                                K.NullPlaceholder
                              }
                              className={styles.icTextStyle}
                            >
                              {candidateDetail.currentTitle ??
                                K.NullPlaceholder}
                            </Text>
                          </Col>
                        </Row>
                      </div>
                      {candidateDetail.isBlacklist ? (
                        <div>
                          <Alert
                            showIcon
                            type="warning"
                            className="warningAlert"
                            message="Warning: Candidate is blacklisted. "
                            description={`Reason: ${
                              candidateDetail.blacklistReason ??
                              K.NullPlaceholder
                            } `}
                          />
                        </div>
                      ) : null}
                    </div>
                  ),
                },
                {
                  key: "jobs",
                  label: <span className="cic-tabs-label-style">Jobs</span>,
                  permission: K.Permissions.ViewCandidateJobs,
                  children: (
                    <div
                      className={`cic-tabs-content ${styles.cicTabsContent}`}
                    >
                      {candidateDetail?.candidateJobEntries?.map((jobEntry) => (
                        <Card
                          key={jobEntry.id}
                          className={styles.jobsCardsWrapper}
                          bodyStyle={{
                            width: "100%",
                            background:
                              jobEntry.id === candidateActivity?.id &&
                              "#f7f6ff",
                          }}
                          onClick={() => {
                            fetchCandidateDetails(
                              jobEntry.id,
                              jobEntry.jobSource.id,
                              jobEntry.jobSource.vacancies,
                            );
                          }}
                        >
                          <div className={styles.jobCardContentWrapper}>
                            <div className={styles.jobWrapper}>
                              <Title
                                title={jobEntry.jobSource.title}
                                level={3}
                                className={`${styles.jobTitle} hover-underlined`}
                              >
                                {jobEntry.jobSource.title}
                              </Title>

                              <div className={styles.locationsList}>
                                Client : {jobEntry.jobSource.client?.name}
                              </div>
                              <div className={styles.locationsList}>
                                Location: {jobEntry.jobSource.jobLocation?.name}
                              </div>

                              <div className={styles.locationsList}>
                                <span
                                  className={`${styles.tagesStyle} ${styles.fullTime}`}
                                >
                                  Desired Salary:{" "}
                                  {displayDollar(jobEntry.desireSalary)}
                                </span>
                              </div>
                              <div className={styles.locationsList}>
                                <span
                                  className={`${styles.tagesStyle} ${styles.fullTime}`}
                                >
                                  Candidate Type:&nbsp;
                                  {checkNullPlaceHolder(
                                    K.CandidateTypes[jobEntry.hireType],
                                  )}
                                </span>
                              </div>
                              {/* <div className={styles.tagesListWrapper}>
                                <span
                                  className={`${styles.tagesStyle} ${styles.fullTime}`}
                                >
                                  {job.position}
                                </span>
                                <Divider
                                  type={"vertical"}
                                  className={styles.jobTimeDivider}
                                />
                                <span
                                  className={`${styles.tagesStyle} ${styles.marketing}`}
                                >
                                  {displayDollar(job.salary)}
                                </span>
                              </div> */}

                              <div className={styles.tagesListWrapper}>
                                <Tag
                                  className="tagTextColor"
                                  color={tagColors[jobEntry.status.name]}
                                >
                                  {jobEntry.status.name}
                                </Tag>
                                <Divider type="vertical" />
                                <Tag
                                  className="tagTextColor"
                                  color={getSubStatusColor(jobEntry)}
                                >
                                  {jobEntry.subStatus.name}
                                </Tag>
                              </div>
                            </div>
                          </div>
                        </Card>
                      ))}
                    </div>
                  ),
                },
                {
                  key: "activities",
                  label: <span className="cic-tabs-label-style">Activity</span>,
                  permission: K.Permissions.ViewCandidateProfile,
                  children: (
                    <div
                      className={`cic-tabs-content ${styles.cicTabsContent}`}
                    >
                      <ActivityTab candidateId={candidateDetail.id} />
                    </div>
                  ),
                },
              ].filter(({ permission }) =>
                isPermissionPresent(permission, userSlice.roles),
              )}
            />
          </Card>
          <Modal
            className={`s2-theme-style ${styles1.ReasonForMoving}`}
            title="Reason for blacklist"
            open={isModalVisible}
            centered
            onCancel={handleCancelReasonModal}
            closeIcon={<i className={`icon-closeable ${styles1.closeAble}`} />}
            footer={[
              <Button key="1" onClick={handleCancelReasonModal}>
                Cancel
              </Button>,
              <Button key="2" type="primary" onClick={handleOkReasonModal}>
                Submit
              </Button>,
            ]}
          >
            <Form
              form={reasonForm}
              layout="vertical"
              className={styles1.ReasonForMovingFrom}
              onFinish={addToBlacklist}
            >
              <Form.Item name="id" initialValue={id} hidden>
                <InputNumber />
              </Form.Item>
              <Form.Item
                label="Reason"
                name="reason"
                className={styles1.rfmFields}
              >
                <TextArea
                  showCount
                  maxLength={500}
                  placeholder="Reason"
                  className={`${styles1.rfmInput} ${styles1.rfmTextArea}`}
                  autoSize={{ minRows: 5, maxRows: 6 }}
                />
              </Form.Item>
            </Form>
          </Modal>
        </div>
        {candidateActivity && (
          <div
            className={`candidate-view-tabs ${styles.candidateViewActivity}`}
          >
            <ViewActivity
              jobId={jobId.current}
              jobDetails={jobDetailRef.current}
              styles={styles1}
              showEditIcon={false}
              candidateForm={candidateForm}
              candidateActivity={candidateActivity}
              setCandidateDetail={setCandidateDetail}
              setCandidateActivity={setCandidateActivity}
              getCandidateUdfs={getCandidateUdfs}
              defaultActiveTab={defaultActiveJobTab}
            />
          </div>
        )}
      </>
    )
  );
}
