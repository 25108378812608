import { message, PageHeader } from "antd";
import LayoutCss from "layout/layout.module.scss";

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import User from "redux/models/user";
import { selectGlobalSelector } from "redux/slices/globalSelectorSlice";
import { selectUser } from "redux/slices/userSlice";
import K from "utilities/constants";

import { filterColumnListing } from "utilities/tableUtility";

import "./submitToBilling.scss";
import { clientBilling, jobBilling } from "./tableColumns";

import BillingComponent from "./billingComponent";

const { AgGridTable } = K;

const configKeys = {
  jobBilling: AgGridTable.Keys.SubmittedJobBillingsVisibleColumn,
  clientBilling: AgGridTable.Keys.SubmittedClientBillingsVisibleColumn,
};

export default function SubmitToBilling() {
  const [columnDefs, setColumnDefs] = useState({
    jobBilling,
    clientBilling,
  });

  const userSlice = useSelector(selectUser);
  const globalSelector = useSelector(selectGlobalSelector);
  const [shouldUpdate, setShouldUpdate] = useState(false);

  const onColumnVisible = async (event, key) => {
    if (event.source === "gridOptionsChanged") return;
    const columnState = event.columnApi.getColumnState();
    setColumnDefs((prev) => ({
      ...prev,
      [key]: prev[key].map((el, i) => ({
        ...el,
        hide: columnState[i].hide,
        pinned: columnState[i].pinned,
      })),
    }));
    const cols = filterColumnListing(columnDefs[key], columnState);
    try {
      await User.saveTableVisibleColumn({
        usersId: userSlice.details.id,
        tableName: configKeys[key],
        configJson: JSON.stringify(cols),
      });
      message.success("Column configration saved");
    } catch (err) {
      console.error(err);
    }
  };
  //?save cloumn Sorting
  const updateColumns = async (event, key) => {
    if (event.source === "gridOptionsChanged") return;
    const cols = filterColumnListing(
      columnDefs[key],
      event.columnApi.getColumnState(),
    );

    // return;
    if (shouldUpdate)
      try {
        await User.saveColumnSort({
          usersId: userSlice.details.id,
          tableName: configKeys[key],
          configJson: JSON.stringify(cols),
        });
        message.success("Columns order saved successfully");
      } catch (err) {
        console.error(err);
      }
    else setShouldUpdate(true);
  };

  const getColumnsConfigrations = async (key) => {
    try {
      const res = await User.getConfigrations(configKeys[key]);
      const parsed = JSON.parse(res.config).map((item) => {
        return {
          ...item,
          ...columnDefs[key].find((c) => c.field === item.field),
        };
      });
      setColumnDefs((prev) => ({
        ...prev,
        [key]: parsed,
      }));
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    (async () => {
      await Promise.all([
        getColumnsConfigrations("jobBilling"),
        getColumnsConfigrations("clientBilling"),
      ]);
    })();
  }, []);

  return (
    <>
      <PageHeader
        ghost={false}
        title="Billing Approval"
        className={`${LayoutCss.appPageHeader} ${LayoutCss.pageHeader}`}
      />
      {console.log("Global Selector", globalSelector, userSlice)}

      {globalSelector.selectedClients.length > 0
        ? globalSelector.selectedClients.map((id) => (
            <BillingComponent
              key={id}
              clientId={id}
              columnDefs={columnDefs}
              setColumnDefs={setColumnDefs}
              onColumnVisible={onColumnVisible}
              updateColumns={updateColumns}
              activeKey={[globalSelector.selectedClients[0]]}
            />
          ))
        : userSlice.clients.map(({ id }) => (
            <BillingComponent
              key={id}
              clientId={id}
              columnDefs={columnDefs}
              setColumnDefs={setColumnDefs}
              onColumnVisible={onColumnVisible}
              updateColumns={updateColumns}
              activeKey={[userSlice.clients[0].id]}
            />
          ))}
    </>
  );
}
