import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
// import "ag-grid-enterprise";
import ErrorBoundary from "common/components/errorBoundary/errorBoundary";
import Spinner from "common/components/spinner/spinner";
import { useEffect, useState } from "react";
import { useClearCache } from "react-clear-cache";
import { useDispatch } from "react-redux";
import { Switch, useHistory } from "react-router-dom";
import User from "redux/models/user";
import { setInitialSelector } from "redux/slices/globalSelectorSlice";
import { setLoggedInUser } from "redux/slices/userSlice";
import getNextAvailableRoute from "routes/nextAvailableRoute";
import RouteWithSubRoutes from "routes/routeWithSubRoutes";
import routes from "routes/routes";
import K from "utilities/constants";
import "./App.less";
import { LicenseManager } from "ag-grid-enterprise";
import { Alert } from "antd";
import { isNonProdEnvironment } from "../utilities/systemUtility";

function EnvironmentAlert() {
  if (!isNonProdEnvironment()) {
    return null;
  }

  let alertStyles = {};
  let msg = null;
  if (K.Server === K.Environment.STAGING) {
    msg = "Staging";
    alertStyles = {
      backgroundColor: "#3AC73A",
    };
  } else if (K.Server === K.Environment.QA) {
    msg = "QA";
    alertStyles = {
      backgroundColor: "#ff6600",
    };
  } else if (K.Server === K.Environment.DEV) {
    msg = "Development";
    alertStyles = {
      backgroundColor: "#00d9ff",
    };
  }

  return (
    <Alert
      message={msg}
      banner
      showIcon={false}
      className="env-header"
      style={alertStyles}
    />
  );
}

LicenseManager.setLicenseKey(K.AgGridTable.TrailKey);
export default function App() {
  useClearCache({ auto: true });

  const dispatch = useDispatch();
  const [shouldLoad, setShouldLoad] = useState(false);
  const history = useHistory();

  const getCurrentUserDetails = async () => {
    try {
      const res = await User.getLoggedInUserDetails();

      const availableRoutes = getNextAvailableRoute(res.basicDetail);
      dispatch(
        setLoggedInUser({
          data: res,
          homeRoute: availableRoutes[0],
        }),
      );
      dispatch(
        setInitialSelector({
          isSuperAdmin: res.basicDetail.isSuperAdmin,
          data: res,
        }),
      );
      setShouldLoad(true);
      const occurances = window.location.pathname.split("/").length - 1;
      const route =
        occurances >= 3
          ? window.location.pathname.match(/(.*)\/(\/*)/)[1]
          : window.location.pathname;
      if (!res.basicDetail.isSuperAdmin && !availableRoutes.includes(route))
        history.replace(availableRoutes[0]);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    document.title = K.Server
      ? `${document.title} - ${K.Server}`
      : document.title;
    if (
      User.isTokenAvailable() &&
      !(window.location !== window.parent.location)
    )
      getCurrentUserDetails();
    else setShouldLoad(true);
  }, []);

  return (
    <ErrorBoundary>
      {shouldLoad ? (
        <div className="App">
          <EnvironmentAlert />
          <Switch>
            {routes.map((route, i) => (
              <RouteWithSubRoutes
                key={i}
                {...route}
                exact={route.exact ?? false}
              />
            ))}
          </Switch>
        </div>
      ) : (
        <Spinner />
      )}
    </ErrorBoundary>
  );
}
