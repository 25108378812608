import {
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Radio,
  Row,
  Select,
} from "antd";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BillingClient from "redux/models/billingClients";
import BillingJob from "redux/models/billingJobs";
import LookupTable from "redux/models/lookupTable";
import { selectConfigration } from "redux/slices/configrationSlice";
import { selectUser } from "redux/slices/userSlice";
import K from "utilities/constants";
import {
  getIsVisibleLookups,
  noTrailingSpaceAllowedRule,
} from "utilities/generalUtility";

const { Lookup } = K.Network.URL;

const lookupTables = [
  {
    reduxKey: K.Redux.Source,
    apiEndPoint: Lookup.Sources,
  },
  {
    reduxKey: K.Redux.BillingTypes,
    apiEndPoint: Lookup.BillingTypes,
  },
];

export default function SubmitOneTimeBilling({
  isOpen,
  selectedClientId,
  setIsModalVisible,
}) {
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const titleCurrencyRef = useRef({
    title: "",
    billingTypeName: "",
  });
  const assignedClients = useSelector(selectUser).clients;
  const lookupData = useSelector(selectConfigration).lookup;
  const configrationSlice = useSelector(selectConfigration);
  const [clientBillings, setClientBillings] = useState([]);

  const onFinish = async (values) => {
    values.billMonth =
      values.billMonth.format(K.DateFormat.DashMonthFormat) + "-15";
    const { clientLevelBillingEntryId, ...rest } = values;

    try {
      setLoading(true);
      await BillingClient.submitOneTimeBilling({
        ...rest,
        ...titleCurrencyRef.current,
      });
      message.success("One Time Billing Submitted");
      if (selectedClientId) {
        setIsModalVisible(false);
      } else {
        setIsModalVisible((prev) => ({
          ...prev,
          isModalVisible: false,
        }));
      }
      form.resetFields();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const getLookupData = async () => {
    try {
      await Promise.all([
        ...lookupTables.map(async (item) => {
          await dispatch(LookupTable.getData(item.reduxKey, item.apiEndPoint));
        }),
        getOneTimeEntries(selectedClientId),
        getAllCurrencies(),
      ]);
    } catch (error) {
      console.error(error);
    }
  };

  const getOneTimeEntries = async (clientId) => {
    if (clientId) {
      try {
        const res = await BillingClient.getOneTimeEntries(
          clientId,
          moment().format("DD-MM-YYYY"),
        );
        setClientBillings(res);
      } catch (error) {
        console.error(error);
      }
    }
  };
  const shouldPriceUpdate = (prevValue, curValue) => {
    if (
      prevValue.clientLevelBillingEntryId !== curValue.clientLevelBillingEntryId
    ) {
      const billingRule = clientBillings.find(
        (item) => item.id === curValue.clientLevelBillingEntryId,
      );
      titleCurrencyRef.current = {
        title: billingRule.aliasName,
        billingTypeName: billingRule.clientLevelBillingType.name,
        currency: billingRule.currency,
      };
      form.setFieldsValue({
        price: billingRule.amount,
        amount: billingRule.amount * curValue.quantity,
      });
      return true;
    }
    return false;
  };
  const shouldCostUpdate = (prevValue, curValue) => {
    if (
      prevValue.quantity !== curValue.quantity ||
      prevValue.price !== curValue.price
    ) {
      form.setFieldValue("amount", curValue.price * curValue.quantity);
      return true;
    }
    return false;
  };

  const handleOk = () => {
    form.submit();
  };
  const handleCancel = () => {
    if (selectedClientId) {
      setIsModalVisible(false);
    } else {
      setIsModalVisible((prev) => ({
        ...prev,
        isModalVisible: false,
      }));
    }
    form.resetFields();
  };
  const onClientChange = (value) => {
    getOneTimeEntries(value);
  };
  const getAllCurrencies = async () => {
    try {
      await dispatch(BillingJob.getCurrencies());
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getLookupData();
  }, []);

  const onChange = ({ target: { value } }) => {
    form.setFieldsValue({
      price:
        value === "credit"
          ? -Math.abs(form.getFieldValue("price"))
          : +Math.abs(form.getFieldValue("price")),
      billingRadio: value,
    });
    form.validateFields(["price"]);
  };

  const validatePriceValue = (value) => {
    if (!value) {
      return Promise.reject("Amount is required!");
    } else if (value > 0 && form.getFieldValue("billingRadio") === "credit") {
      return Promise.reject("Amount value should be negative!");
    } else if (value < 0 && form.getFieldValue("billingRadio") === "debit") {
      return Promise.reject("Amount value should be positive!");
    } else {
      return Promise.resolve();
    }
  };

  return (
    <Modal
      centered
      width={462}
      open={isOpen}
      okText="Submit"
      onOk={handleOk}
      onCancel={handleCancel}
      title="One Time Billing"
      className="s2-theme-style modal-overflow-auto"
      closeIcon={<i className="icon-closeable"></i>}
      okButtonProps={{ loading }}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          className="billing-radios"
          name="billingRadio"
          initialValue={"debit"}
        >
          <Radio.Group
            options={[
              { label: "Billing Amount", value: "debit" },
              { label: "Credit Amount", value: "credit" },
            ]}
            onChange={onChange}
            optionType="button"
            buttonStyle="solid"
          />
        </Form.Item>
        <Divider />
        <Form.Item
          hidden={selectedClientId}
          name="clientId"
          label="Clients"
          initialValue={selectedClientId}
          rules={[{ required: true, message: "Clients is required!" }]}
        >
          <Select
            showSearch
            allowClear
            placeholder="Select Clients"
            optionFilterProp="label"
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            onChange={onClientChange}
            options={assignedClients.map(({ id, name }) => ({
              label: name,
              value: id,
            }))}
          />
        </Form.Item>

        <Form.Item
          label="Billing Type"
          name="oneTimePaymentBillingType"
          rules={[{ required: true, message: "Billing type is required!" }]}
        >
          <Select
            showSearch
            allowClear
            optionFilterProp="label"
            placeholder="Select Billing Type"
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            options={getIsVisibleLookups(
              lookupData[K.Redux.BillingTypes],
              null,
            ).map(({ id, name, isVisible }) => ({
              value: id,
              label: name,
              disabled: isVisible === 1 ? false : true,
            }))}
          />
        </Form.Item>
        <Form.Item
          name="sourceId"
          label="Source"
          rules={[{ required: true, message: "Source is required!" }]}
        >
          <Select
            showSearch
            allowClear
            optionFilterProp="label"
            placeholder="Select Source"
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            options={getIsVisibleLookups(lookupData[K.Redux.Source], null).map(
              ({ id, name, isVisible }) => ({
                value: id,
                label: name,
                disabled: isVisible === 1 ? false : true,
              }),
            )}
          />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
          rules={[
            { required: true, message: "Description is required!" },
            noTrailingSpaceAllowedRule(),
          ]}
        >
          <Input className="inputModal" placeholder="Description" />
        </Form.Item>
        <Form.Item
          name="billMonth"
          label="Billing Month"
          rules={[{ required: true, message: "Billing Month is required!" }]}
        >
          <DatePicker
            placeholder="Select Billing month"
            picker="month"
            allowClear={true}
            format={K.DateFormat.MonthYearFormat}
          />
        </Form.Item>
        <Row>
          <Col xs={24} md={16} xl={16}>
            <Form.Item
              shouldUpdate={shouldPriceUpdate}
              label="Amount"
              name="price"
              rules={[
                () => ({
                  required: true,
                  validator(_, value) {
                    return validatePriceValue(value);
                  },
                }),
              ]}
            >
              <InputNumber className="inputModal" placeholder="Enter Amount" />
            </Form.Item>
          </Col>
          <Col xs={24} md={16} xl={8} style={{ alignSelf: "end" }}>
            <Form.Item name={"currency"} initialValue="USD">
              <Select
                showSearch
                optionFilterProp="label"
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                placeholder="Currency"
                options={configrationSlice.currencies.map(({ code }) => ({
                  value: code,
                  label: code,
                }))}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name="quantity"
          label="Quantity"
          initialValue={1}
          rules={[{ required: true, message: "Please enter quantity" }]}
        >
          <InputNumber className="inputModal" placeholder="Quantity" min={1} />
        </Form.Item>
        <Form.Item label="Cost" name="amount" shouldUpdate={shouldCostUpdate}>
          <InputNumber disabled className="inputModal" placeholder="Cost" />
        </Form.Item>
      </Form>
    </Modal>
  );
}
