import { SwapOutlined, UserOutlined } from "@ant-design/icons";
import {
  Alert,
  Avatar,
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  message,
  Row,
  Select,
  Space,
  Tabs,
  TimePicker,
  Tooltip,
  Typography,
} from "antd";
import {
  activeStatusDateRules,
  finalDestinationDates,
  finalStatusDateRules,
  subStatusesToDisplay,
} from "config/statusConfig";
import groupBy from "lodash/groupBy";
import moment from "moment";
import { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { useDispatch, useSelector } from "react-redux";
import Candidate from "redux/models/candidate";
import Status from "redux/models/status";
import {
  editCandidateList,
  selectCandidates,
} from "redux/slices/candidateSlice";
import {
  selectIsClientHasEngagementMilestones,
  selectUser,
} from "redux/slices/userSlice";
import K from "utilities/constants";
import { isValidMomentDate } from "utilities/dateUtility";
import {
  calculateWorkingDays,
  generateUdfSetFieldValues,
  isPermissionPresent,
} from "utilities/generalUtility";
import CandidateComments from "./candidateComments";
import CandidateDetails from "./candidateDetails";
import CandidateHistory from "./candidateHistory";
import CandidateIssueCredits from "./candidateIssueCredits";
import OfferedSalaryModal from "./offeredSalaryModal";
import ScheduledBilling from "./scheduledBilling";

import DeleteCandidate from "./deleteCandidate";
import TransferCandidateJob from "./transferCandidateJob";
import BillingListModal from "./billingListModal";
import { selectUdf } from "redux/slices/udfSlice";
import { useParams } from "react-router-dom";
import CandidateAttachments from "./candidateAttachment";
import Job from "redux/models/job";
import LastOpeningFilledModal from "./lastOpeningFilledModal";
import EngagementView from "./engagementView/engagementView";

const { Option } = Select;
const { Title, Paragraph } = Typography;

export default function ViewActivity({
  jobId,
  styles,
  jobDetails,
  candidateActivity,
  setModalVisible,
  candidateForm,
  showEditIcon = true,
  setCandidateActivity,
  setCandidateDetail,
  setJobData,
  getCandidateUdfs,
  getJobDetails,
  setRefreshTable,
  getStatusBreakdown,
  isjobDetail = false,
  defaultActiveTab = "details",
}) {
  let isBroken = false;
  const [form] = Form.useForm();
  const { clientId } = useParams();

  const dispatch = useDispatch();
  const userSlice = useSelector(selectUser);
  const isClientHasEngagementMilestones = useSelector((state) =>
    selectIsClientHasEngagementMilestones(
      state,
      candidateActivity.jobSource.clientsId,
    ),
  );
  const candidatesSlice = useSelector(selectCandidates);
  const udfSlice = useSelector(selectUdf);

  const [loading, setLoading] = useState(false);

  const [statusHistory, setStatusHistory] = useState({
    useConfig: false,
    configKey: "",
    startIndex: -1,
    currentStatus: "",
    currentSubStatus: "",
    currentStatusType: "active",
    previousStatus: "",
    previousSubStatus: "",
    previousStatusType: "active",
  });
  const [disabledOptions, setDisabledOptions] = useState({
    statusIndex: -1,
    subStatusIndex: -1,
  });
  const [listing, setListing] = useState({
    status: [],
    subStatuses: null,
  });
  const [offeredSalaryModal, setOfferedSalaryModal] = useState({
    showModal: false,
    currentStatus: null,
    shouldReinitialize: false,
  });
  const [deleteCandidateModal, setDeleteCandidateModal] = useState({
    showModal: false,
    deleteResponse: null,
  });

  const [transferCandidate, setTransferCandidate] = useState({
    showModal: false,
    transferResponse: null,
  });
  const [guranteePeriod, setGuaranteePeriod] = useState({
    workingDays: null,
  });
  const [billingListModal, setBillingListModal] = useState({
    showModal: false,
    billingList: [],
    shouldReinitialize: false,
    isLoading: false,
  });

  const [lastOpeningFilledModal, setLastOpeningFilledModal] = useState({
    showModal: false,
    movingCandidate: false,
    shouldReinitialize: false,
  });

  const [activeTabKey, setActiveTabKey] = useState(defaultActiveTab);

  const onActivityModalClose = () => {
    setCandidateActivity(null);
    setOfferedSalaryModal((prev) => ({
      ...prev,
      isLoading: false,
      shouldReinitialize: !prev.shouldReinitialize,
    }));
  };

  const onStatusChange = (_, option) => {
    let useConfig = false,
      configKey = "";
    if (option.children === K.CandidateStatus.Declined) {
      useConfig = true;
      configKey = K.CandidateStatus.Declined;
    } else if (option.children === K.CandidateStatus.Withdrew) {
      useConfig = true;
      configKey = K.CandidateStatus.Withdrew;
    }
    setStatusHistory({
      ...statusHistory,
      useConfig,
      configKey,
      currentStatus: option.children,
      currentSubStatus: "",
      currentStatusType: option.type,
    });
  };

  const onSubStatusChange = (_, option) => {
    if (
      (statusHistory.currentStatus === K.CandidateStatus.Offer &&
        option.children === "Accepted") ||
      (statusHistory.currentStatus === K.CandidateStatus.Hired &&
        option.children === "Started Work")
    ) {
      setOfferedSalaryModal((prev) => ({
        ...prev,
        showModal: true,
        currentStatus: statusHistory.currentStatus,
      }));
    }
    setStatusHistory({
      ...statusHistory,
      currentSubStatus: option.children,
    });
  };
  const shouldUpdate = (prevValues, currValues) => {
    if (prevValues.statusId && prevValues.statusId !== currValues.statusId)
      form.resetFields(["subStatusId"]);
    return prevValues.statusId !== currValues.statusId;
  };

  const updateCandidateDetails = (updated) => {
    setCandidateDetail((prev) => ({
      ...prev,
      candidateJobEntries: prev.candidateJobEntries.map((entry) => {
        if (entry.id === updated.id) {
          return {
            ...entry,
            status: updated.status,
            subStatus: updated.subStatus,
          };
        }
        return entry;
      }),
    }));
  };

  const onFinish = async (values) => {
    const payload = {
      candidateJobEntryId: candidateActivity.id,
      previousStatusId: candidateActivity.statusId,
      previousSubStatusId: candidateActivity.subStatusId,
      recrutorId: userSlice.details.id,
      jobId: +jobId,
      statusName: statusHistory.currentStatus,
      previousStatusName: statusHistory.previousStatus,
      subStatusName: statusHistory.currentSubStatus,
      previousSubStatusName: statusHistory.previousSubStatus,
      issueCreditBillingIds: billingListModal.billingList?.map(({ id }) => id),
      isCreditBilling: values.isCreditBilling,
      issueCreditReason: values.issueCreditReason,
    };

    for (let key in values) {
      if (
        isValidMomentDate(values[key]) &&
        ![
          "interviewTime",
          "secondInterviewTime",
          "thirdInterviewTime",
        ].includes(key)
      )
        values[key] = values[key].format(K.DateFormat.Response);
      else if (
        isValidMomentDate(values[key]) &&
        ["interviewTime", "secondInterviewTime", "thirdInterviewTime"].includes(
          key,
        )
      )
        values[key] = values[key].format(K.TimeFomat.Response);
    }
    try {
      setLoading(true);
      const res = await Candidate.changeStatus({ ...payload, ...values });

      if (lastOpeningFilledModal.movingCandidate) {
        await Job.filledLastOpening(+jobId);
      }
      setLastOpeningFilledModal((prev) => ({
        ...prev,
        showModal: false,
        movingCandidate: false,
      }));

      showEditIcon
        ? dispatch(editCandidateList(res))
        : updateCandidateDetails(res);
      message.success("Candidate status changed successfully");
      setCandidateActivity((prev) => ({
        ...prev,
        ...res,
      }));
      setOfferedSalaryModal((prev) => ({
        ...prev,
        shouldReinitialize: !prev.shouldReinitialize,
      }));
      setBillingListModal((prev) => {
        return {
          ...prev,
          isLoading: true,
          showModal: false,
        };
      });
      if (isjobDetail) {
        setRefreshTable((prev) => !prev);
        getStatusBreakdown();
        getJobDetails();
        //  setJobData((prev) => ({ shouldUpdate: !prev.shouldUpdate }));
      }
      form.setFieldsValue({ issueCreditReason: null, isCreditBilling: true });

      Job.syncCandidateTTM(+jobId);
      // dispatch(setJobStatisticsShouldUpdate());
      // const syncRes = await Job.syncCandidateTTM(+jobId);
      // dispatch(updateCandidateList(res));
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  const editCandidate = () => {
    const udfEditData = generateUdfSetFieldValues(
      udfSlice[K.Redux.CandidateUdf],
    );

    candidateForm.setFieldsValue({
      ...candidateActivity.candidate,
      salary: candidateActivity.salary,
      desireSalary: candidateActivity.desireSalary,
      position: candidateActivity.position,
      candidateStatusesId: candidateActivity.candidateStatusesId,
      candidateJobRecruiters: candidateActivity.candidateJobRecruiters.map(
        ({ id }) => id,
      ),
      interviewConfirmation: candidateActivity.interviewConfirmation
        ? true
        : false,
      atsLookupId: candidateActivity.atsLookupId,
      hireType: candidateActivity.hireType,
      sourceId: candidateActivity.sourcesId,
      ...udfEditData,
    });

    setModalVisible((prev) => {
      return {
        ...prev,
        isCandidateModalVisible: true,
        editCandidateId: candidateActivity.candidate.id, // candidateId
        editCandidateEntryId: candidateActivity.id, // entryId
      };
    });
  };

  const undoStatus = async () => {
    try {
      const res = await Status.undoCandidateStatus(candidateActivity.id);
      setCandidateActivity(res.data);
      setOfferedSalaryModal((prev) => ({
        ...prev,
        shouldReinitialize: !prev.shouldReinitialize,
      }));
      if (showEditIcon) dispatch(editCandidateList(res.data));
      else updateCandidateDetails(res.data);
      message.success(res.message);
      if (isjobDetail) {
        getStatusBreakdown();
        getJobDetails();
      }
      Job.syncCandidateTTM(+jobId);
    } catch (err) {
      console.error(err);
    }
  };
  const setInitialValues = (statuses) => {
    form.setFieldsValue({
      statusId: candidateActivity.statusId,
      subStatusId: candidateActivity.subStatusId,
      otherStatusId: candidateActivity.otherStatusId,
    });
    const statusOptionIndex = statuses.findIndex(
      (item) => item.id === candidateActivity.statusId,
    );
    const subStatusOptionIndex = statuses[
      statusOptionIndex
    ].subStatuses.findIndex(
      (item) => item.id === candidateActivity.subStatusId,
    );
    const ruleIndex = activeStatusDateRules.findIndex(
      (item) =>
        item.status === statuses[statusOptionIndex].name &&
        item.subStatus ===
          statuses[statusOptionIndex].subStatuses[subStatusOptionIndex].name,
    );
    setDisabledOptions({
      statusIndex: statusOptionIndex,
      subStatusIndex: subStatusOptionIndex,
    });
    setStatusHistory({
      ...statusHistory,
      previousStatus: statuses[statusOptionIndex].name,
      previousSubStatus:
        statuses[statusOptionIndex].subStatuses[subStatusOptionIndex].name,
      previousStatusType: statuses[statusOptionIndex].type,
      currentStatus: statuses[statusOptionIndex].name,
      currentSubStatus:
        statuses[statusOptionIndex].subStatuses[subStatusOptionIndex].name,
      currentStatusType: statuses[statusOptionIndex].type,
      startIndex: ruleIndex,
      useConfig: false,
    });
  };

  const getAllStatus = async () => {
    try {
      const res = await Status.getAll();
      const grouped = groupBy(res, "name");
      setListing({
        status: res,
        subStatuses: grouped,
      });
      setInitialValues(res);
    } catch (err) {
      console.error(err);
    }
  };

  const handleCalculateWorkingDays = (date) => {
    const workingDays = calculateWorkingDays(candidateActivity.startDate, date);
    setGuaranteePeriod((prev) => {
      return { ...prev, workingDays: workingDays };
    });
  };

  useEffect(() => {
    //getCandidateUdfs(candidateActivity.id);
    form.setFieldsValue({
      statusId: undefined,
    });
    if (!listing.status.length) getAllStatus();
    else setInitialValues(listing.status);
  }, [
    candidatesSlice.shouldReinitialize,
    offeredSalaryModal.shouldReinitialize,
  ]);
  const isLastElgible = (candidateId) => {
    const activeStatusIds = ["1", "2", "3", "4", "5", "6"];

    const activeCandiates = candidatesSlice.listing.reduce((prev, curr) => {
      if (
        curr.isConsiderable === 1 &&
        activeStatusIds.includes(curr.statusId.toString())
      ) {
        if (curr.id !== candidateId) {
          return prev + 1;
        }
      }
      return prev;
    }, 0);

    return activeCandiates > 0 ? true : false;
  };

  const isEngagementTabVisible = () => {
    if (isPermissionPresent(K.Permissions.SuperAdmin, userSlice.roles)) {
      return true;
    }
    const hasManageEngagementsPermission = isPermissionPresent(
      [
        K.Permissions.ManageEngagementsForAssignedCandidates,
        K.Permissions.ManageEngagementsForAssignedClients,
      ],
      userSlice.roles,
    );
    return hasManageEngagementsPermission && isClientHasEngagementMilestones;
  };

  return (
    <>
      {candidateActivity && (
        <Card className={styles.viewActivityModal}>
          <div className={styles.viewActivityHeader}>
            <div className={styles.ClientProfile}>
              <Avatar
                size="large"
                icon={<UserOutlined className={styles.avatarIcon} />}
              />
              <div className={styles.clientName + "edit-icon"}>
                <Title level={5} className="mb-0">
                  {[
                    candidateActivity.candidate.firstName,
                    candidateActivity.candidate.lastName,
                  ].join(" ")}
                </Title>
                <div className={styles.ClientProfileInfo}>
                  <Paragraph className={styles.seniorLogistician}>
                    {candidateActivity.candidate.currentTitle ??
                      K.NullPlaceholder}
                  </Paragraph>
                  <Button
                    type="primary"
                    className={styles.cvDownload}
                    disabled={!candidateActivity.uploadedCvPath}
                    onClick={() => {
                      window.open(candidateActivity.uploadedCvPath);
                    }}
                  >
                    Download CV
                    <i className={"icon-arrow-down " + styles.downIcon}></i>
                  </Button>
                  <Space size={0}>
                    {showEditIcon &&
                      isPermissionPresent(
                        K.Permissions.EditCandidate,
                        userSlice.roles,
                      ) && (
                        <Button
                          className={styles.editBtn}
                          type="link"
                          disabled={candidateActivity.isConsiderable === 0}
                          onClick={editCandidate}
                          icon={<i className="icon-edit"></i>}
                        />
                      )}

                    {showEditIcon &&
                      isPermissionPresent(
                        K.Permissions.EditCandidate,
                        userSlice.roles,
                      ) && (
                        <Button
                          className={styles.deleteBtn}
                          type="link"
                          icon={<i className="icon-del-threshold" />}
                          disabled={candidateActivity.isConsiderable === 0}
                          onClick={() =>
                            setDeleteCandidateModal((prev) => {
                              return { ...prev, showModal: true };
                            })
                          }
                        />
                      )}
                    {showEditIcon &&
                      isPermissionPresent(
                        K.Permissions.EditCandidate,
                        userSlice.roles,
                      ) && (
                        <Tooltip title={"Transfer"}>
                          <Button
                            // className={styles.editBtn}
                            type="link"
                            disabled={
                              [
                                K.CandidateStatus.Hired,
                                K.CandidateStatus.Terminated,
                                K.CandidateStatus.Withdrew,
                                K.CandidateStatus.Declined,
                              ].includes(statusHistory.previousStatus) ||
                              (!jobDetails?.vacancies?.isActive ?? true) ||
                              candidateActivity.isConsiderable === 0
                            }
                            icon={<SwapOutlined />}
                            onClick={() =>
                              setTransferCandidate((prev) => {
                                return { ...prev, showModal: true };
                              })
                            }
                          />
                        </Tooltip>
                      )}
                  </Space>
                </div>
              </div>
            </div>
            <Button
              type="link"
              className={styles.editButton}
              onClick={onActivityModalClose}
              icon={<i className={"icon-closeable " + styles.editIcon}></i>}
            />
          </div>
          <Tabs
            defaultActiveKey={defaultActiveTab}
            className={"active border-clr " + styles.clientsTabs}
            onChange={(e) => setActiveTabKey(e || "details")}
            items={[
              {
                key: "details",
                label: "Details",
                children: (
                  <>
                    <Form form={form} onFinish={onFinish} layout="vertical">
                      <div className={"tab-header " + styles.tabHeader}>
                        <div
                          className={`job-status-options ${styles.jobStatusOptions}`}
                        >
                          <Form.Item
                            label="Status"
                            name="statusId"
                            className={styles.jobStatus}
                            rules={[
                              {
                                required: true,
                                message: "Status is required.",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              optionFilterProp="children"
                              placeholder="Status"
                              className={styles.jobStatusField}
                              onChange={onStatusChange}
                              disabled={
                                [
                                  K.CandidateStatus.Terminated,
                                  K.CandidateStatus.Withdrew,
                                  K.CandidateStatus.Declined,
                                ].includes(statusHistory.previousStatus) ||
                                candidateActivity.isConsiderable === 0
                                //  ||(!jobDetails?.vacancies?.isActive ?? true)
                              }
                              getPopupContainer={(triggerNode) =>
                                triggerNode.parentNode
                              }
                            >
                              {listing.status.map(({ id, name, type }, i) => (
                                <Option
                                  disabled={disabledOptions.statusIndex > i}
                                  key={id}
                                  value={id}
                                  type={type}
                                >
                                  {name}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                        <div
                          className={
                            "job-status-options " + styles.jobStatusOptions
                          }
                        >
                          <Form.Item
                            label="Sub Status"
                            name="subStatusId"
                            className={styles.jobStatus}
                            shouldUpdate={shouldUpdate}
                            rules={[
                              {
                                required: true,
                                message: "Sub Status is required.",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              optionFilterProp="children"
                              placeholder="Sub Status"
                              className={styles.jobStatusField}
                              getPopupContainer={(triggerNode) =>
                                triggerNode.parentNode
                              }
                              disabled={
                                !statusHistory.currentStatus ||
                                [
                                  K.CandidateStatus.Terminated,
                                  K.CandidateStatus.Withdrew,
                                  K.CandidateStatus.Declined,
                                ].includes(statusHistory.previousStatus) ||
                                candidateActivity.isConsiderable === 0

                                // ||(!jobDetails?.vacancies?.isActive ?? true)
                              }
                              onChange={onSubStatusChange}
                            >
                              {listing.subStatuses &&
                                statusHistory.currentStatus &&
                                (!statusHistory.useConfig
                                  ? listing.subStatuses[
                                      statusHistory.currentStatus
                                    ][0].subStatuses?.map(({ id, name }, i) => (
                                      <Option
                                        disabled={
                                          statusHistory.currentStatus ===
                                            statusHistory.previousStatus &&
                                          disabledOptions.subStatusIndex > i
                                        }
                                        key={id}
                                        value={id}
                                      >
                                        {name}
                                      </Option>
                                    ))
                                  : listing.subStatuses[
                                      statusHistory.currentStatus
                                    ][0].subStatuses?.map(({ id, name }, i) =>
                                      subStatusesToDisplay[
                                        statusHistory.configKey
                                      ][statusHistory.previousStatus]?.includes(
                                        name,
                                      ) ? (
                                        <Option
                                          disabled={
                                            statusHistory.currentStatus ===
                                              statusHistory.previousStatus &&
                                            disabledOptions.subStatusIndex > i
                                          }
                                          key={id}
                                          value={id}
                                        >
                                          {name}
                                        </Option>
                                      ) : null,
                                    ))}
                            </Select>
                          </Form.Item>
                        </div>
                        <div
                          className={
                            "job-status-options " + styles.jobStatusOptions
                          }
                        >
                          <Form.Item
                            label="Other Status"
                            name="otherStatusId"
                            className={styles.jobStatus}
                          >
                            <Select
                              showSearch
                              optionFilterProp="children"
                              placeholder="Other"
                              className={styles.jobStatusField}
                              disabled={
                                !(
                                  statusHistory.currentStatus &&
                                  listing.subStatuses[
                                    statusHistory.currentStatus
                                  ][0].otherStatuses?.length
                                ) ||
                                [
                                  K.CandidateStatus.Terminated,
                                  K.CandidateStatus.Withdrew,
                                  K.CandidateStatus.Declined,
                                ].includes(statusHistory.previousStatus) ||
                                (!jobDetails?.vacancies?.isActive ?? true) ||
                                candidateActivity.isConsiderable === 0
                              }
                              getPopupContainer={(triggerNode) =>
                                triggerNode.parentNode
                              }
                            >
                              {listing.subStatuses &&
                                statusHistory.currentStatus &&
                                listing.subStatuses[
                                  statusHistory.currentStatus
                                ][0].otherStatuses?.map(({ id, name }) => (
                                  <Option key={id} value={id}>
                                    {name}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </div>
                        <Button
                          onClick={undoStatus}
                          className={"activity-reload-btn " + styles.reloadBtn}
                          disabled={
                            [K.CandidateStatus.Terminated].includes(
                              statusHistory.previousStatus,
                            ) ||
                            !(jobDetails?.vacancies?.isActive ?? true) ||
                            ((statusHistory.previousStatus ===
                              K.CandidateStatus.Hired ||
                              (statusHistory.previousStatus ===
                                K.CandidateStatus.Offer &&
                                statusHistory.previousSubStatus ===
                                  K.Status.Offer.subStatus.Accepted.name)) &&
                              !isPermissionPresent(
                                K.Permissions.UndoCandidate,
                                userSlice.roles,
                              )) ||
                            candidateActivity.isConsiderable === 0

                            // (statusHistory.previousStatus ===
                            //   K.CandidateStatus.Prospects &&
                            //   statusHistory.previousSubStatus === "Sourced") ||
                            // (statusHistory.previousStatus ===
                            //   K.CandidateStatus.Candidates &&
                            //   statusHistory.previousSubStatus ===
                            //     "Needs Review")
                          }
                        >
                          <i className="icon-reload"></i>
                        </Button>
                      </div>
                      <div className={styles.hiringDate}>
                        <Row gutter={20}>
                          {statusHistory.currentStatusType === "active" ? (
                            activeStatusDateRules.map((item, i) => {
                              if (i > statusHistory.startIndex) {
                                if (
                                  statusHistory.previousStatus ===
                                    statusHistory.currentStatus &&
                                  statusHistory.previousSubStatus ===
                                    statusHistory.currentSubStatus
                                )
                                  isBroken = true;

                                if (!isBroken) {
                                  if (
                                    !statusHistory.currentSubStatus ||
                                    (item.status ===
                                      statusHistory.currentStatus &&
                                      item.subStatus ===
                                        statusHistory.currentSubStatus)
                                  )
                                    isBroken = true;

                                  if (item.isDateNotRequired) {
                                    if (
                                      item.status ===
                                        statusHistory.currentStatus &&
                                      item.subStatus ===
                                        statusHistory.currentSubStatus
                                    ) {
                                      return item.enteringDate.map(
                                        (dateObj, j) => (
                                          <Col key={j} xs={24} sm={12}>
                                            <Form.Item
                                              className="mt-0"
                                              name={dateObj.formItemName}
                                              initialValue={moment()}
                                              label={dateObj.placeholder}
                                              rules={
                                                dateObj.required
                                                  ? [
                                                      {
                                                        required: true,
                                                        message:
                                                          "This date is required",
                                                      },
                                                    ]
                                                  : []
                                              }
                                            >
                                              {!dateObj.isTimeField ? (
                                                <DatePicker
                                                  getPopupContainer={(
                                                    triggerNode,
                                                  ) => triggerNode.parentNode}
                                                  className={styles.datePiker}
                                                  placeholder={
                                                    dateObj.placeholder
                                                  }
                                                  format={
                                                    K.DateFormat.DashUSFormat
                                                  }
                                                />
                                              ) : (
                                                <TimePicker
                                                  use12Hours
                                                  format={K.TimeFomat.Display}
                                                  className={styles.datePiker}
                                                  placeholder={
                                                    dateObj.placeholder
                                                  }
                                                  getPopupContainer={(
                                                    triggerNode,
                                                  ) => triggerNode.parentNode}
                                                />
                                              )}
                                            </Form.Item>
                                          </Col>
                                        ),
                                      );
                                    } else return null;
                                  } else {
                                    return item.enteringDate.map(
                                      (dateObj, j) => (
                                        <Col key={j} xs={24} sm={12}>
                                          <Form.Item
                                            className="mt-0"
                                            name={dateObj.formItemName}
                                            initialValue={moment()}
                                            label={dateObj.placeholder}
                                            rules={
                                              dateObj.required
                                                ? [
                                                    {
                                                      required: true,
                                                      message:
                                                        "This date is required",
                                                    },
                                                  ]
                                                : []
                                            }
                                          >
                                            {!dateObj.isTimeField ? (
                                              <DatePicker
                                                getPopupContainer={(
                                                  triggerNode,
                                                ) => triggerNode.parentNode}
                                                className={styles.datePiker}
                                                placeholder={
                                                  dateObj.placeholder
                                                }
                                                format={
                                                  K.DateFormat.DashUSFormat
                                                }
                                              />
                                            ) : (
                                              <TimePicker
                                                use12Hours
                                                format={K.TimeFomat.Display}
                                                className={styles.datePiker}
                                                placeholder={
                                                  dateObj.placeholder
                                                }
                                                getPopupContainer={(
                                                  triggerNode,
                                                ) => triggerNode.parentNode}
                                              />
                                            )}
                                          </Form.Item>
                                        </Col>
                                      ),
                                    );
                                  }
                                } else return null;
                              } else {
                                if (
                                  (statusHistory.previousStatus !==
                                    statusHistory.currentStatus ||
                                    statusHistory.previousSubStatus !==
                                      statusHistory.currentSubStatus) &&
                                  item.status ===
                                    statusHistory.previousStatus &&
                                  item.subStatus ===
                                    statusHistory.previousSubStatus
                                ) {
                                  return item.leavingDate.map((dateObj, j) => (
                                    <Col key={j} xs={24} sm={12}>
                                      <Form.Item
                                        className="mt-0"
                                        name={dateObj.formItemName}
                                        initialValue={moment()}
                                        label={dateObj.placeholder}
                                        rules={
                                          dateObj.required
                                            ? [
                                                {
                                                  required: true,
                                                  message:
                                                    "This date is required",
                                                },
                                              ]
                                            : []
                                        }
                                      >
                                        <DatePicker
                                          getPopupContainer={(triggerNode) =>
                                            triggerNode.parentNode
                                          }
                                          className={styles.datePiker}
                                          placeholder={dateObj.placeholder}
                                          format={K.DateFormat.DashUSFormat}
                                        />
                                      </Form.Item>
                                    </Col>
                                  ));
                                } else return null;
                              }
                            })
                          ) : (
                            <>
                              {finalDestinationDates[
                                statusHistory.currentStatus
                              ].map((item, i) => {
                                if (
                                  statusHistory.previousStatus ===
                                    statusHistory.currentStatus &&
                                  statusHistory.previousSubStatus ===
                                    statusHistory.currentSubStatus
                                )
                                  isBroken = true;
                                if (isBroken) return null;
                                return (
                                  <>
                                    <Col key={i} xs={24} sm={12}>
                                      <Form.Item
                                        className="mt-0"
                                        name={item.formItemName}
                                        initialValue={moment()}
                                        label={item.placeholder}
                                        rules={
                                          item.required
                                            ? [
                                                {
                                                  required: true,
                                                  message:
                                                    "This date is required",
                                                },
                                              ]
                                            : []
                                        }
                                      >
                                        <DatePicker
                                          getPopupContainer={(triggerNode) =>
                                            triggerNode.parentNode
                                          }
                                          className={styles.datePiker}
                                          placeholder={item.placeholder}
                                          format={K.DateFormat.DashUSFormat}
                                          onChange={(date) =>
                                            handleCalculateWorkingDays(
                                              date.format("YYYY-MM-DD"),
                                            )
                                          }
                                        />
                                      </Form.Item>
                                    </Col>
                                    {statusHistory.currentStatus ===
                                      K.Status.Terminated.name &&
                                      statusHistory.currentSubStatus ===
                                        K.Status.Terminated.subStatus
                                          .WithinGuarantee.name && (
                                        <Alert
                                          showIcon
                                          type="warning"
                                          className="warningAlert gurantee-warning"
                                          message={`Gurantee period in contract is ${
                                            candidateActivity.guaranteeDays ?? 0
                                          } ${
                                            candidateActivity.isWorkingDays
                                              ? "working days"
                                              : "calendar days"
                                          }, this candidate has worked a total of ${
                                            guranteePeriod.workingDays ??
                                            calculateWorkingDays(
                                              candidateActivity.startDate,
                                              moment().format("YYYY-MM-DD"),
                                            )
                                          } ${
                                            candidateActivity.isWorkingDays
                                              ? "working days"
                                              : "calendar days"
                                          }.`}
                                          // description={calculateWorkingDays()}
                                        />
                                      )}
                                  </>
                                );
                              })}
                              {statusHistory.currentSubStatus in
                              finalStatusDateRules[statusHistory.currentStatus]
                                ? finalStatusDateRules[
                                    statusHistory.currentStatus
                                  ][statusHistory.currentSubStatus].map(
                                    (item, i) => {
                                      if (
                                        statusHistory.previousStatus ===
                                          statusHistory.currentStatus &&
                                        statusHistory.previousSubStatus ===
                                          statusHistory.currentSubStatus
                                      )
                                        isBroken = true;
                                      if (isBroken) return null;
                                      return (
                                        <Col key={i} xs={24} sm={12}>
                                          <Form.Item
                                            className="mt-0"
                                            name={item.formItemName}
                                            initialValue={moment()}
                                            label={item.placeholder}
                                            rules={
                                              item.required
                                                ? [
                                                    {
                                                      required: true,
                                                      message:
                                                        "This date is required",
                                                    },
                                                  ]
                                                : []
                                            }
                                          >
                                            <DatePicker
                                              getPopupContainer={(
                                                triggerNode,
                                              ) => triggerNode.parentNode}
                                              className={styles.datePiker}
                                              placeholder={item.placeholder}
                                              format={K.DateFormat.DashUSFormat}
                                            />
                                          </Form.Item>
                                        </Col>
                                      );
                                    },
                                  )
                                : null}
                            </>
                          )}
                        </Row>
                      </div>
                      {isPermissionPresent(
                        K.Permissions.EditCandidate,
                        userSlice.roles,
                      ) &&
                      statusHistory.currentStatus ===
                        K.Status.Terminated.name &&
                      statusHistory.currentSubStatus ===
                        K.Status.Terminated.subStatus.WithinGuarantee.name ? (
                        <div className={styles.clientChanges}>
                          {(statusHistory.currentStatus !==
                            statusHistory.previousStatus ||
                            statusHistory.currentSubStatus !==
                              statusHistory.previousSubStatus) && (
                            <Button
                              type="primary"
                              className={styles.saveBtn}
                              onClick={() => {
                                setBillingListModal((prev) => {
                                  return { ...prev, showModal: true };
                                });

                                form.setFieldValue(
                                  "issueCreditReason",
                                  "Terminated Within Guarantee",
                                );
                                // form.submit();
                              }}
                            >
                              Save Changes
                            </Button>
                          )}
                        </div>
                      ) : (
                        <div className={styles.clientChanges}>
                          {(statusHistory.currentStatus !==
                            statusHistory.previousStatus ||
                            statusHistory.currentSubStatus !==
                              statusHistory.previousSubStatus) && (
                            <Button
                              type="primary"
                              className={styles.saveBtn}
                              onClick={() => {
                                jobDetails.vacancies.currentOpenings === 1 &&
                                statusHistory.currentStatus ===
                                  K.Status.Hired.name &&
                                isLastElgible(candidateActivity.id)
                                  ? setLastOpeningFilledModal((prev) => ({
                                      ...prev,
                                      showModal: true,
                                    }))
                                  : form.submit();
                              }}
                            >
                              Save Changes
                            </Button>
                          )}
                        </div>
                      )}

                      <BillingListModal
                        candidateActivity={candidateActivity}
                        setBillingListModal={setBillingListModal}
                        billingListModal={billingListModal}
                        setCandidateActivity={setCandidateActivity}
                        setOfferedSalaryModal={setOfferedSalaryModal}
                        viewActivityForm={form}
                        styles={styles}
                        loading={loading}
                      />
                    </Form>
                    <Scrollbars style={{ height: 400 }}>
                      <CandidateDetails
                        styles={styles}
                        candidateActivity={candidateActivity}
                        setCandidateActivity={setCandidateActivity}
                        getCandidateUdfs={getCandidateUdfs}
                        getJobDetails={getJobDetails}
                      />
                    </Scrollbars>
                  </>
                ),
              },
              {
                key: "comments",
                label: "Comments",
                children: (
                  <CandidateComments
                    styles={styles}
                    userData={userSlice.details}
                    candidateActivity={candidateActivity}
                  />
                ),
              },
              {
                key: "billings",
                label: "Billing",
                children: (
                  <CandidateIssueCredits
                    styles={styles}
                    candidateActivity={candidateActivity}
                  />
                ),
              },
              {
                key: "activities",
                label: "Activity",
                children: (
                  <CandidateHistory
                    styles={styles}
                    candidateActivity={candidateActivity}
                  />
                ),
              },
              {
                key: "scheduledBillings",
                label: "Scheduled Billing",
                children: (
                  <ScheduledBilling
                    candidateActivity={candidateActivity}
                    styles={styles}
                  />
                ),
              },
              ...(isEngagementTabVisible()
                ? [
                    {
                      key: "engagements",
                      label: "Engagements",
                      children: (
                        <EngagementView
                          isActive={activeTabKey === "engagements"}
                          candidateJobEntry={candidateActivity}
                          isJobDetail={isjobDetail}
                        />
                      ),
                    },
                  ]
                : []),
              ...(isPermissionPresent(
                K.Permissions.ViewAttachment,
                userSlice.roles,
              )
                ? [
                    {
                      key: "attachments",
                      label: "Attachments",
                      children: (
                        <CandidateAttachments
                          candidateActivity={candidateActivity}
                          styles={styles}
                        />
                      ),
                    },
                  ]
                : []),
            ]}
          />
        </Card>
      )}
      <OfferedSalaryModal
        candidateActivity={candidateActivity}
        offeredSalaryModal={offeredSalaryModal}
        setCandidateActivity={setCandidateActivity}
        setOfferedSalaryModal={setOfferedSalaryModal}
      />
      <DeleteCandidate
        candidateActivity={candidateActivity}
        deleteCandidateModal={deleteCandidateModal}
        setDeleteCandidateModal={setDeleteCandidateModal}
        setJobData={setJobData}
        onActivityModalClose={onActivityModalClose}
      />

      <TransferCandidateJob
        candidateActivity={candidateActivity}
        transferCandidate={transferCandidate}
        setTransferCandidate={setTransferCandidate}
        setJobData={setJobData}
        jobId={jobId}
        jobDetail={jobDetails}
        userClients={userSlice.clients}
        onActivityModalClose={onActivityModalClose}
        clientId={clientId}
      />

      <LastOpeningFilledModal
        isLastOpeningModalVisible={lastOpeningFilledModal}
        setIsLastOpeningModalVisible={setLastOpeningFilledModal}
        candidateActivity={candidateActivity}
        statusForm={form}
        loading={loading}
      />
    </>
  );
}
