import Engagement from "../../redux/models/engagement";
import User from "../../redux/models/user";
import K from "../../utilities/constants";
import { EngagementsContainer } from "./engagementsContainer/engagementsContainer";
import Export from "../../redux/models/export";

export const TabsConfig = {
  thisWeek: {
    key: "thisWeek",
    label: "Due This Week",
    component: () => {
      const getData = (params) =>
        Engagement.get({
          ...params,
          timeFrame: "thisWeek",
          state: "pending",
          candidateJobStatusId: 9,
        });

      const getConfig = () =>
        User.getConfigrations(
          K.AgGridTable.Keys.ThisWeekEngagementsVisibleColumn,
        );

      const saveConfig = (params) =>
        User.saveColumnSort({
          ...params,
          tableName: K.AgGridTable.Keys.ThisWeekEngagementsVisibleColumn,
        });

      const onExport = (params) =>
        Export.getReportEngagements({
          ...params,
          timeFrame: "thisWeek",
          state: "pending",
          candidateJobStatusId: 9,
        });

      return (
        <EngagementsContainer
          key={"thisWeek"}
          getData={getData}
          getConfig={getConfig}
          saveConfig={saveConfig}
          onExport={onExport}
        />
      );
    },
  },
  pastDue: {
    key: "pastDue",
    label: "Past Due",
    component: () => {
      const getData = (params) =>
        Engagement.get({
          ...params,
          timeFrame: "pastDue",
          state: "pending",
          candidateJobStatusId: 9,
        });

      const getConfig = () =>
        User.getConfigrations(
          K.AgGridTable.Keys.PastDueEngagementsVisibleColumn,
        );

      const saveConfig = (params) =>
        User.saveColumnSort({
          ...params,
          tableName: K.AgGridTable.Keys.PastDueEngagementsVisibleColumn,
        });

      const onExport = (params) =>
        Export.getReportEngagements({
          ...params,
          timeFrame: "pastDue",
          state: "pending",
          candidateJobStatusId: 9,
        });

      return (
        <EngagementsContainer
          key={"pastDue"}
          getData={getData}
          getConfig={getConfig}
          saveConfig={saveConfig}
          onExport={onExport}
        />
      );
    },
  },
  future: {
    key: "future",
    label: "Future",
    component: () => {
      const getData = (params) =>
        Engagement.get({
          ...params,
          timeFrame: "future",
          state: "pending",
          candidateJobStatusId: 9,
        });

      const getConfig = () =>
        User.getConfigrations(
          K.AgGridTable.Keys.FutureEngagementsVisibleColumn,
        );

      const saveConfig = (params) =>
        User.saveColumnSort({
          ...params,
          tableName: K.AgGridTable.Keys.FutureEngagementsVisibleColumn,
        });

      const onExport = (params) =>
        Export.getReportEngagements({
          ...params,
          timeFrame: "future",
          state: "pending",
          candidateJobStatusId: 9,
        });

      return (
        <EngagementsContainer
          key={"future"}
          getData={getData}
          getConfig={getConfig}
          saveConfig={saveConfig}
          onExport={onExport}
        />
      );
    },
  },
};
