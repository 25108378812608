import K from "../../../utilities/constants";
import { valueFormatter } from "../../../utilities/tableUtility";

export const Columns = [
  {
    headerName: "ID",
    field: "id",
    lockVisible: true,
  },
  {
    headerName: "Milestone Date",
    field: "milestoneDate",
    sort: "asc",
    headerTooltip: "Milestone Date",
  },
  {
    headerName: "Milestone",
    field: "milestoneName",
  },
  {
    headerName: "Candidate Name",
    field: "candidateName",
    tooltipField: "candidateName",
    headerTooltip: "Candidate Name",
  },
  {
    headerName: "Candidate ATS ID",
    field: "candidateAtsId",
    tooltipField: "candidateAtsId",
    headerTooltip: "Candidate ATS ID",
  },
  {
    headerName: "Department",
    field: "departmentName",
    tooltipField: "departmentName",
  },
  {
    headerName: "Job Title",
    field: "jobTitle",
    tooltipField: "jobTitle",
  },
  {
    headerName: "Start Date",
    field: "startDate",
  },
  {
    headerName: "Recruiters",
    field: "recruiters",
    tooltipField: "recruiters",
    sortable: false,
  },
];

export const DefaultColDef = {
  ...K.AgGridTable.DefaultColDef,
  valueFormatter: valueFormatter(),
  sortable: true,
};
