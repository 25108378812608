import { AgGridReact } from "ag-grid-react";
import {
  Button,
  Card,
  Collapse,
  Form,
  message,
  Modal,
  Spin,
  Typography,
} from "antd";
import LayoutCss from "layout/layout.module.scss";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import BillingApproval from "redux/models/billingApproval";
import { selectGlobalSelector } from "redux/slices/globalSelectorSlice";
import { selectUser } from "redux/slices/userSlice";
import K from "utilities/constants";
import { isPermissionPresent } from "utilities/generalUtility";
import { displayDollar } from "utilities/tableUtility";
import BillingDetails from "./billingDetails";
import CreateInvoice from "./createInvoice";
import OfferedSalaryCalculations from "./offeredSalaryCalculation";
import moment from "moment";
import "./submitToBilling.scss";
import { PaginationSizeSelector } from "common/tableComponents/pagination/paginationSizeSelector";
import "./billing-collapse.scss";

const { Title } = Typography;
const { Panel } = Collapse;
const {
  AgGridTable,
  Invoice: { Type },
} = K;
const keys = {
  jobTotal: 0,
  clientTotal: 0,
  isSameTypeJobs: true,
  isSameTypeClients: true,
  selectedJobBillingRows: [],
  selectedClientBillingRows: [],
  selectedJobBillingRowsData: [],
};
const configKeys = {
  jobBilling: AgGridTable.Keys.SubmittedJobBillingsVisibleColumn,
  clientBilling: AgGridTable.Keys.SubmittedClientBillingsVisibleColumn,
};

export default function BillingComponent({
  clientId,
  columnDefs,
  setColumnDefs,
  onColumnVisible,
  updateColumns,
  activeKey,
}) {
  const inputRef = useRef(null);
  const invoicePayloadRef = useRef(null);
  const [form] = Form.useForm();
  //this form ref is used in offered Salary

  const [salaryForm] = Form.useForm();
  const [isConverting, setIsConverting] = useState(false);

  const [pageStates, setPageStates] = useState({
    listing: [],
    isEditMode: false,
    checkedRows: { ...keys },
    selectedClient: null,
    selectedRecord: null,
    isModalVisible: false,
    conversionHistory: [],
    isClientBillingRow: false,
    isDueDateModalVisible: false,
    isOfferedSalaryModalVisible: false,
    selectedClientIndex: null,
    loading: false,
    shouldUpdae: false,
  });
  const [rowData, setRowData] = useState([]);
  const [isOpen, setIsOpen] = useState(activeKey[0] === clientId);
  const [tableHeight, setTableHeight] = useState({ job: 310, client: 322 });
  const [loading, setLoading] = useState(false);

  const userSlice = useSelector(selectUser);
  const globalSelector = useSelector(selectGlobalSelector);

  const toggleEdit = () => {
    setPageStates((prev) => ({ ...prev, isEditMode: !prev.isEditMode }));
  };
  const warning = () => {
    message.warning("Selected currency types should be same.");
  };
  const sameMonthWarning = () => {
    message.warning(
      "Your client may be eligible for invoicing a volume-based discount. Please do not invoice until all hires are identified at the end of the month.",
    );
  };
  const convert = async () => {
    const billingType = pageStates.isClientBillingRow ? Type.Client : Type.Job;
    const payload = {
      from: "CAD",
      to: "USD",
      amount: pageStates.selectedRecord.amount,
      billingApprovalId: pageStates.selectedRecord.id,
      type: billingType,
      edit: false,
      isIssueCredit:
        Math.sign(pageStates.selectedRecord.dublicateAmount) === -1 ? 1 : 0,
    };
    setIsConverting(true);

    try {
      const res = await BillingApproval.currencyConversion(payload);
      const history = await getBillHistory({
        type: billingType,
        billingId: res.id,
      });
      updateListing(res, history);
      message.success("Conversion Complete");
      setIsConverting(false);
    } catch (err) {
      setIsConverting(false);
      console.error(err);
    }
  };
  const editAmount = async () => {
    const payload = {
      edit: true,
      dublicateAmount: +inputRef.current.value,
      currency: pageStates.selectedRecord.convertToCurrency,
    };
    try {
      const res = pageStates.isClientBillingRow
        ? await BillingApproval.editClientSubmittedBilling({
            ...payload,
            clientSubmittedBillId: pageStates.selectedRecord.id,
          })
        : await BillingApproval.editJobSubmittedBilling({
            ...payload,
            jobSubmittedBillId: pageStates.selectedRecord.id,
          });
      const history = await getBillHistory({
        type: pageStates.isClientBillingRow ? Type.Client : Type.Job,
        billingId: res.id,
      });
      updateListing(res, history);
    } catch (err) {
      message.error("Failed to edit amount.");
      console.error(err);
    }
    toggleEdit();
  };

  const onClientSelectionChanged = (event, index) => {
    const selectedRows = event.api.getSelectedRows();
    const updated = pageStates.checkedRows;
    const isSameType = selectedRows.length
      ? selectedRows.every(
          (el) => el.convertToCurrency === selectedRows[0].convertToCurrency,
        )
      : true;

    const { total, selected } = selectedRows.reduce(
      (prev, curr) => ({
        total: prev.total + curr.dublicateAmount,
        selected: [...prev.selected, curr.id],
      }),
      { total: 0, selected: [] },
    );
    updated.clientTotal = isSameType ? total : 0;
    updated.selectedClientBillingRows = selected;
    updated.isSameTypeClients = isSameType;
    setPageStates((prev) => ({
      ...prev,
      checkedRows: updated,
    }));
    if (!isSameType) warning();
  };

  const onClientRowClicked = async (event, selectedClient) => {
    const history = await getBillHistory({
      type: Type.Client,
      billingId: event.data.id,
    });
    setPageStates((prev) => ({
      ...prev,
      selectedClient,
      isEditMode: false,
      isModalVisible: true,
      isClientBillingRow: true,
      selectedRecord: event.data,
      conversionHistory: history,
    }));
  };
  const onJobSelectionChanged = (event, index) => {
    const selectedRows = event.api.getSelectedRows();
    const updated = pageStates.checkedRows;
    const isSameType = selectedRows.length
      ? selectedRows.every(
          (el) => el.convertToCurrency === selectedRows[0].convertToCurrency,
        )
      : true;

    const isSameMonth = selectedRows.length
      ? selectedRows.some(
          (el) =>
            el?.billingRuleEntry &&
            el.billingRuleEntry.jobBillingTypesId === 1 &&
            moment(el.candidateJobEntry.startDate).format("M") ===
              moment().format("M"),
        )
      : false;
    const { total, selected } = selectedRows.reduce(
      (prev, curr) => ({
        total: prev.total + curr.dublicateAmount,
        selected: [...prev.selected, curr.id],
      }),
      { total: 0, selected: [] },
    );

    updated.jobTotal = isSameType ? total : 0;
    updated.isSameTypeJobs = isSameType;
    updated.selectedJobBillingRows = selected;
    updated.selectedJobBillingRowsData = selectedRows;

    setPageStates((prev) => ({
      ...prev,
      checkedRows: { ...updated },
    }));

    if (!isSameType) warning();
    if (isSameMonth) sameMonthWarning();
  };

  const onJobRowClicked = async (event, selectedClient) => {
    const history = await getBillHistory({
      type: Type.Job,
      billingId: event.data.id,
    });
    setPageStates((prev) => ({
      ...prev,
      selectedClient,
      isEditMode: false,
      isModalVisible: true,
      isClientBillingRow: false,
      selectedRecord: event.data,
      conversionHistory: history,
    }));
  };

  const getBillHistory = async (payload) => {
    try {
      const res = await BillingApproval.getSubmittedBillHistory(payload);
      return res;
    } catch (err) {
      console.error(err);
    }
  };
  const handleInvoice = (billType, clientId, index) => {
    const isOfferSalaryRequired =
      pageStates.checkedRows?.selectedJobBillingRowsData.some(
        (jd) =>
          jd?.billingRuleEntry?.jobBillingType?.isCancellation === 0 &&
          jd?.candidateJobEntry.salary === null &&
          jd?.candidateJobEntry.isInvoiced === 0 &&
          jd?.billingRuleEntry?.jobBillingType?.isDependentOn === 1 &&
          jd?.candidateJobEntry.isOfferedSalaryDone === 0 &&
          jd?.billingRuleEntry.amountType === K.Format.Percentage,
      );
    if (isOfferSalaryRequired) {
      setPageStates((prev) => ({
        ...prev,
        isOfferedSalaryModalVisible: true,
        selectedClientIndex: index,
      }));
    } else {
      generateInvoice(billType, clientId, index);
    }
  };
  const generateInvoice = async (billType, clientId, index) => {
    const payload = {
      index,
      clientId,
      type: billType,
      submittalBillIds:
        Type.Client === billType
          ? pageStates.checkedRows.selectedClientBillingRows
          : pageStates.checkedRows.selectedJobBillingRows,

      submittalJobBillsData:
        Type.Client === billType
          ? []
          : pageStates.checkedRows.selectedJobBillingRowsData,
    };
    invoicePayloadRef.current = payload;
    form.resetFields();
    setPageStates((prev) => ({ ...prev, isDueDateModalVisible: true }));
  };

  const createBillingInvoice = async (values) => {
    const { index, ...payload } = invoicePayloadRef.current;
    payload.dueDate = values.dueDate.format(K.DateFormat.Response);
    try {
      const res = await BillingApproval.generateInvoice(payload);
      message.success(res.message);
      removeBillingEntry(
        payload.clientId,
        payload.type,
        payload.submittalBillIds,
        index,
      );
    } catch (err) {
      console.error(err);
    }
  };

  const updateListing = (record, history) => {
    setPageStates((prev) => {
      const { listing, ...rest } = prev;

      const { submittedClientBiils, submittedJobBills } = listing;
      const updated = (
        rest.isClientBillingRow ? submittedClientBiils : submittedJobBills
      ).map((el) => {
        if (el.id === record.id) return { ...el, ...record };
        return el;
      });
      if (rest.isClientBillingRow) listing.submittedClientBiils = updated;
      else listing.submittedJobBills = updated;
      return {
        ...rest,
        listing,
        checkedRows: { ...prev.checkedRows, jobTotal: 0 },
        selectedRecord: record,
        conversionHistory: history,
      };
    });
  };

  const removeBillingEntry = (
    clientId,
    type,
    submittalBillIds,
    clientIndex,
  ) => {
    setPageStates((prev) => {
      const { listing, ...rest } = prev;
      const { submittedClientBiils, submittedJobBills } = listing;
      const updated = (
        type === Type.Client ? submittedClientBiils : submittedJobBills
      ).filter((el) => !submittalBillIds.includes(el.id));
      if (type === Type.Client) listing.submittedClientBiils = updated;
      else listing.submittedJobBills = updated;
      return {
        ...rest,
        listing,
        isDueDateModalVisible: false,
        checkedRows: {
          ...keys,
        },
      };
    });
  };

  const reCalculateBilling = async (
    candidateJobEntryId,
    billingRuleEntryId,
    salary,
  ) => {
    try {
      await BillingApproval.submitReCalculateBilling(
        billingRuleEntryId,
        candidateJobEntryId,
        salary,
      );
      setPageStates((prev) => ({
        ...prev,
        loading: true,
      }));
      const res = await BillingApproval.getClientSubmittedBillings([clientId]);

      //this method only update selectedJobBillingRowsData and this will need in offered salary popup update
      const selectedJobRowsData = res[0].submittedJobBills.filter((dt) =>
        pageStates?.checkedRows.selectedJobBillingRows.includes(dt.id),
      );

      //Remaining selected job rows after salary enter :
      //This fileration filter of those selected values that has not salary entered because when we enter the salary the new row is created and then we have a new row ID.
      const selectedJobRows = selectedJobRowsData?.map(({ id }) => id) ?? [];

      const updatedCheckedRowsData = {
        ...pageStates?.checkedRows,
        selectedJobBillingRowsData: selectedJobRowsData,
        selectedJobBillingRows: selectedJobRows,
      };

      setPageStates((prev) => ({
        ...prev,
        listing: res[0],
        checkedRows: { ...updatedCheckedRowsData },
      }));
    } catch (error) {
      console.log("Error ", error);
    }
  };
  const onCanelOfferSalary = () => {
    const updatedRowData = rowData.map((item) => {
      return {
        ...item,
        enteredSalary: null,
        updatedBill: null,
      };
    });

    setRowData([...updatedRowData]);
  };

  const fetchDataFromServer = async (keys) => {
    try {
      if (keys.length > 0) {
        setPageStates((prev) => ({
          ...prev,
          loading: true,
        }));
        const res = await BillingApproval.getClientSubmittedBillings([
          clientId,
        ]);

        setPageStates((prev) => ({
          ...prev,
          listing: res[0],
          checkedRows: { ...keys },
          loading: false,
        }));
      }
    } catch (err) {
      console.error(err);
    }
  };

  const calculateHeight = (bills) => {
    const count = bills ? Math.min(bills.length, 20) : 0;
    return count * 42 + 112;
  };

  const onFilterChanged = (gridApi, type) => {
    const filteredBillsBills = gridApi
      .getRenderedNodes()
      .map((node) => node.data);
    setTableHeight((prev) => ({
      job: type === "job" ? calculateHeight(filteredBillsBills) : prev.job,
      client:
        type === "client" ? calculateHeight(filteredBillsBills) : prev.client,
    }));
  };

  useEffect(() => {
    if (isOpen) {
      const { submittedJobBills, submittedClientBiils } = pageStates.listing;
      setTableHeight({
        job: calculateHeight(submittedJobBills),
        client: calculateHeight(submittedClientBiils),
      });
    }
  }, [pageStates.listing]);

  useEffect(() => {
    if (activeKey.length > 0 && activeKey[0] === clientId) {
      fetchDataFromServer(activeKey);
    }
  }, []);

  const fetchClientTitle = () => {
    return userSlice.clients.find((item) => item.id === clientId).name;
  };

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  const customIcon = isOpen ? (
    <i className="icon-up-arrow"></i>
  ) : (
    <i className="icon-down-arrow"></i>
  );
  return (
    <>
      <Collapse
        className="billing-collapse-wrap"
        defaultActiveKey={[userSlice.clients[0].id]}
        onChange={fetchDataFromServer}
        expandIcon={() => customIcon}
        expandIconPosition="right"
      >
        <Panel
          header={fetchClientTitle()}
          key={clientId}
          className="billing-approval-header"
          onClick={toggleCollapse}
        >
          <Spin
            onClick={(e) => e.stopPropagation()}
            spinning={pageStates.loading}
            className="billing-component"
          >
            <Card
              className={
                "clientBillingCard  table-pagination collapse-card " +
                LayoutCss.appCard
              }
              bordered={false}
              title="Client Billing"
              extra={
                <div className="clientBilingBtn">
                  <Title level={5} className="mb-0">
                    Total:&nbsp;
                    {displayDollar(pageStates.checkedRows?.clientTotal)}
                  </Title>

                  {isPermissionPresent(
                    K.Permissions.CreateInvoices,
                    userSlice.roles,
                  ) && (
                    <Button
                      type="primary"
                      className={LayoutCss.appHeaderBtn}
                      onClick={() => {
                        generateInvoice(Type.Client, pageStates.listing.id);
                      }}
                      disabled={
                        pageStates.checkedRows?.selectedClientBillingRows
                          ?.length === 0 ||
                        !pageStates.checkedRows?.isSameTypeClients
                      }
                    >
                      Create Invoice
                    </Button>
                  )}
                </div>
              }
            >
              <div
                className="ag-theme-alpine s2-theme-style"
                style={{
                  height: tableHeight.client,
                  minHeight: 310,
                }}
              >
                <AgGridReact
                  onRowClicked={(event) => {
                    onClientRowClicked(event, {
                      id: pageStates.listing.id,
                      name: pageStates.listing.name,
                    });
                  }}
                  onSelectionChanged={(event) => {
                    onClientSelectionChanged(event);
                  }}
                  pagination
                  paginationPageSize={25}
                  rowSelection="multiple"
                  suppressRowClickSelection
                  rowData={pageStates.listing.submittedClientBiils}
                  columnDefs={columnDefs.clientBilling}
                  defaultColDef={K.AgGridTable.DefaultColDef}
                  onFilterChanged={(event) =>
                    onFilterChanged(event.api, "client")
                  }
                  onColumnMoved={(event) =>
                    updateColumns(event, "clientBilling")
                  }
                  onColumnPinned={(event) =>
                    onColumnVisible(event, "clientBilling")
                  }
                  onColumnVisible={(event) =>
                    onColumnVisible(event, "clientBilling")
                  }
                  components={{ PaginationSizeSelector }}
                  statusBar={{
                    statusPanels: [
                      {
                        statusPanel: "PaginationSizeSelector",
                        align: "right",
                      },
                    ],
                  }}
                />
              </div>
            </Card>
          </Spin>

          <Spin
            onClick={(e) => e.stopPropagation()}
            spinning={pageStates.loading}
            className="billing-component"
          >
            <Card
              title="Job Billing"
              extra={
                <div className="clientBilingBtn">
                  <Title className="mb-0" level={5}>
                    Total:&nbsp;
                    {displayDollar(pageStates.checkedRows?.jobTotal)}
                  </Title>
                  {isPermissionPresent(
                    K.Permissions.CreateInvoices,
                    userSlice.roles,
                  ) && (
                    <Button
                      type="primary"
                      className={LayoutCss.appHeaderBtn}
                      onClick={() => {
                        handleInvoice(Type.Job, pageStates?.listing.id);
                        // generateInvoice(Type.Job, item.id, index);
                      }}
                      disabled={
                        pageStates.checkedRows?.selectedJobBillingRows
                          ?.length === 0 ||
                        !pageStates.checkedRows?.isSameTypeJobs
                      }
                    >
                      Create Invoice
                    </Button>
                  )}
                </div>
              }
              className={
                "clientBillingCard table-pagination collapse-card " +
                LayoutCss.appCard
              }
            >
              <div
                className="ag-theme-alpine s2-theme-style"
                style={{
                  height: tableHeight.job,
                  minHeight: 322,
                }}
              >
                <AgGridReact
                  onRowClicked={(event) => {
                    onJobRowClicked(event, {
                      id: pageStates.listing.id,
                      name: pageStates.listing.name,
                    });
                  }}
                  onSelectionChanged={(event) => {
                    onJobSelectionChanged(event);
                  }}
                  pagination
                  paginationPageSize={25}
                  rowSelection="multiple"
                  suppressRowClickSelection
                  rowData={pageStates.listing.submittedJobBills}
                  columnDefs={columnDefs.jobBilling}
                  defaultColDef={K.AgGridTable.DefaultColDef}
                  onFilterChanged={(event) => onFilterChanged(event.api, "job")}
                  onColumnMoved={(event) => updateColumns(event, "jobBilling")}
                  onColumnPinned={(event) =>
                    onColumnVisible(event, "jobBilling")
                  }
                  onColumnVisible={(event) =>
                    onColumnVisible(event, "jobBilling")
                  }
                  components={{ PaginationSizeSelector }}
                  statusBar={{
                    statusPanels: [
                      {
                        statusPanel: "PaginationSizeSelector",
                        align: "left",
                      },
                    ],
                  }}
                />
              </div>
            </Card>
          </Spin>
        </Panel>
      </Collapse>

      <Modal
        title="Create Invoice"
        className="s2-theme-style"
        open={pageStates.isDueDateModalVisible}
        destroyOnClose={true}
        // centered
        width={invoicePayloadRef.current?.type === Type.Client ? 500 : 1200}
        onOk={form.submit}
        okText={"Create Invoice"}
        okButtonProps={{ loading }}
        onCancel={() =>
          setPageStates((prev) => ({ ...prev, isDueDateModalVisible: false }))
        }
      >
        <CreateInvoice
          form={form}
          invoicePayloadRef={invoicePayloadRef}
          removeBillingEntry={removeBillingEntry}
          pageStates={pageStates}
          setLoading={setLoading}
        />
      </Modal>
      {/* Offer salary calculation */}
      <Modal
        centered
        width={1200}
        className="clientBillingModal modal-overflow-auto"
        title={"WARNING: OFFERED SALARY MISSING"}
        footer={false}
        open={pageStates.isOfferedSalaryModalVisible}
        onOk={() =>
          setPageStates((prev) => ({
            ...prev,
            isOfferedSalaryModalVisible: false,
          }))
        }
        onCancel={() => {
          setPageStates((prev) => ({
            ...prev,
            isOfferedSalaryModalVisible: false,
          }));
          salaryForm.resetFields();
          onCanelOfferSalary();
        }}
        closeIcon={
          <span className="closeIcon">
            <i className="icon-closeable"></i>
          </span>
        }
      >
        <OfferedSalaryCalculations
          reCalculateBilling={reCalculateBilling}
          pageStates={pageStates}
          salaryForm={salaryForm}
          rowData={rowData}
          setRowData={setRowData}
          setPageStates={setPageStates}
        />
      </Modal>
      {/* Detail Modal */}
      <Modal
        centered
        width={572}
        className="clientBillingModal modal-overflow-auto"
        title={pageStates.selectedClient?.name}
        footer={false}
        open={pageStates.isModalVisible}
        onOk={() =>
          setPageStates((prev) => ({ ...prev, isModalVisible: false }))
        }
        onCancel={() =>
          setPageStates((prev) => ({ ...prev, isModalVisible: false }))
        }
        closeIcon={
          <span className="closeIcon">
            <i className="icon-closeable"></i>
          </span>
        }
      >
        <BillingDetails
          convert={convert}
          isConverting={isConverting}
          pageStates={pageStates}
          inputRef={inputRef}
          editAmount={editAmount}
          toggleEdit={toggleEdit}
        />
      </Modal>
    </>
  );
}
