import TextArea from "antd/lib/input/TextArea";
import { Button, message, Row, Space } from "antd";
import { useState } from "react";
import Candidate from "../../../redux/models/candidate";

const EditEngagementNotes = ({
  notes,
  setNotes,
  setClosed,
  candidateJobEntry,
}) => {
  const [updatedNotes, setUpdatedNotes] = useState(notes);

  const onSave = async () => {
    if (notes === updatedNotes) {
      setClosed((prev) => !prev);
    } else {
      try {
        await Candidate.v2
          .editEngagementNotes(
            candidateJobEntry.jobSource.id,
            candidateJobEntry.candidate.id,
            {
              engagement_notes: updatedNotes.length > 0 ? updatedNotes : null,
            },
          )
          .then(() => {
            setNotes(updatedNotes);
            setClosed((prev) => !prev);
            message.success("Successfully updated");
          });
      } catch (e) {
        message.error(e.message);
      }
    }
  };

  const onClose = () => {
    setUpdatedNotes(notes);
    setClosed((prev) => !prev);
  };

  return (
    <>
      <TextArea
        value={updatedNotes}
        onChange={(e) => setUpdatedNotes(e.target.value)}
        placeholder={"Add general notes for all engagements"}
        autoSize={{ minRows: 5, maxRows: 5 }}
      />
      <Row justify={"end"} style={{ padding: "16px 0" }}>
        <Space direction="horizontal">
          <Button onClick={onClose}>Cancel</Button>
          <Button type="primary" onClick={onSave}>
            Save
          </Button>
        </Space>
      </Row>
    </>
  );
};

export default EditEngagementNotes;
