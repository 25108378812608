import NetworkCall from "network/networkCall";
import Request from "network/request";
import {
  camelCaseKeys,
  camelCaseKeysRecursively,
} from "utilities/generalUtility";

export default class Dashboard {
  static async hireChart(body) {
    const res = await NetworkCall.fetch(Request.hireChart(body), false);
    return camelCaseKeysRecursively(res.data);
  }
  static async submittalDeclineChart(body) {
    const res = await NetworkCall.fetch(
      Request.submittalDeclineChart(body),
      false,
    );
    return camelCaseKeysRecursively(res.data);
  }

  static async interviewDeclineChart(body) {
    const res = await NetworkCall.fetch(
      Request.interviewDeclineChart(body),
      false,
    );
    return camelCaseKeysRecursively(res.data);
  }

  static async interviewNoShowChart(body) {
    const res = await NetworkCall.fetch(
      Request.interviewNoShowChart(body),
      false,
    );
    return camelCaseKeysRecursively(res.data);
  }

  static async postOfferFallOutChart(body) {
    const res = await NetworkCall.fetch(
      Request.postOfferFallOutChart(body),
      false,
    );
    return camelCaseKeysRecursively(res.data);
  }

  static async postOfferFallOutReasonChart(body) {
    const res = await NetworkCall.fetch(
      Request.postOfferFallOutReasonChart(body),
      false,
    );
    return res.data;
  }

  static async getActiveCandidateSummary(body) {
    const res = await NetworkCall.fetch(
      Request.getActiveCandidateSummary(body),
      false,
    );
    return camelCaseKeys(res.data);
  }

  static async getRecruitmentPipelineData(body) {
    const res = await NetworkCall.fetch(
      Request.getRecruitmentPipelineData(body),
      false,
    );
    return camelCaseKeysRecursively(res.data);
  }

  static async getRequisitionDetail(body) {
    const res = await NetworkCall.fetch(
      Request.getRequisitionDetail(body),
      false,
    );
    return camelCaseKeysRecursively(res.data);
  }
  static async getJobDetailByCandidateStatus(body) {
    const res = await NetworkCall.fetch(
      Request.getJobDetailByCandidateStatus(body),
      false,
    );
    return camelCaseKeysRecursively(res.data);
  }
  static async ttmChart(body) {
    const res = await NetworkCall.fetch(Request.ttmChart(body), false);
    return camelCaseKeysRecursively(res.data);
  }

  // v2

  static v2 = {
    getPipelineSummary: async (params) => {
      const res = await NetworkCall.fetch(
        Request.v2.getDashboardPipelineSummary(params),
        false,
      );
      return camelCaseKeysRecursively(res.data);
    },

    getPipelineDetails: async (params) => {
      const res = await NetworkCall.fetch(
        Request.v2.getDashboardPipelineDetails(params),
        false,
      );
      return camelCaseKeysRecursively(res.data);
    },
  };
}
