import navigations from "layout/navigations";
import { hasAccessToRoute } from "utilities/generalUtility";

const getNextAvailableRoute = (userSlice) => {
  const availableRoutes = [];

  navigations.forEach((navigation) => {
    const hasAccessToNavigation = hasAccessToRoute(
      navigation,
      userSlice.roles,
      userSlice.clients,
      userSlice.isSuperAdmin,
    );

    if ((navigation.children?.length ?? 0) > 0) {
      navigation.children.forEach((subNavigation) => {
        const hasAccessToSubNavigation = hasAccessToRoute(
          subNavigation,
          userSlice.roles,
          userSlice.clients,
          userSlice.isSuperAdmin,
        );

        if (hasAccessToSubNavigation) availableRoutes.push(subNavigation.path);
      });
    }
    if (hasAccessToNavigation && navigation.path)
      availableRoutes.push(navigation.path);
  });

  return availableRoutes;
};
export default getNextAvailableRoute;
