import { Empty } from "antd";

export default function EmptyPlaceholder({
  image,
  showDefaultImage = true,
  description,
}) {
  return (
    <Empty
      description={description ? description : undefined}
      image={showDefaultImage ? Empty.PRESENTED_IMAGE_DEFAULT : image}
    />
  );
}
