import K from "utilities/constants";
import {
  hasAtLeastOneAssignedClient,
  hasAtLeastOneClientWithEngagementMilestones,
} from "../routes/extraConditions";

const billingCommissionsChildren = (basePath) => [
  {
    path: `${basePath}/submit`,
    name: "Submit to Billing",
    icon: <i className="icon-invoices"></i>,
    extraCondition: hasAtLeastOneAssignedClient,
    permission: [K.Permissions.ViewBillingApproval],
  },
  {
    path: `${basePath}/scheduled`,
    name: "Scheduled Billing",
    icon: <i className="icon-invoices"></i>,
    extraCondition: hasAtLeastOneAssignedClient,
    permission: [K.Permissions.ViewScheduledBilling],
  },
  {
    path: `${basePath}/invoices`,
    name: "Invoices",
    icon: <i className="icon-invoices"></i>,
    extraCondition: hasAtLeastOneAssignedClient,
    permission: [K.Permissions.ViewInvoices],
  },
  {
    path: `${basePath}/commission-approval`,
    name: "Commission Approval",
    icon: <i className="icon-commission"></i>,
    extraCondition: hasAtLeastOneAssignedClient,
    permission: [K.Permissions.ViewCommissionApproval],
  },
  {
    path: `${basePath}/commission-statement`,
    name: "Commission Statements",
    icon: <i className="icon-commission"></i>,
    extraCondition: hasAtLeastOneAssignedClient,
    permission: [K.Permissions.ViewCommissionStatement],
  },
];
const configurationsChildren = (basePath) => [
  {
    path: `${basePath}/billing-clients`,
    name: "Billing - Clients",
    icon: <i className="icon-user"></i>,
    permission: [K.Permissions.ViewBillingClients],
  },
  {
    path: `${basePath}/billing-jobs`,
    name: "Billing - Jobs",
    icon: <i className="icon-breifcase"></i>,
    permission: [K.Permissions.ViewBillingJobs],
  },
  {
    path: `${basePath}/commission-plans`,
    name: "Commission Plans",
    icon: <i className="icon-commission-plans"></i>,
    permission: [K.Permissions.ViewCommissionPlan],
  },
  {
    path: `${basePath}/lookup-tables`,
    name: "Lookup Tables",
    icon: <i className="icon-lookup"></i>,
    permission: [K.Permissions.ViewLookupTable],
  },
  {
    path: `${basePath}/roles-permission`,
    name: "Roles & Permissions",
    icon: <i className="icon-roles-permissions"></i>,
    permission: [K.Permissions.ViewRolesAndPermissions],
  },
  {
    path: `${basePath}/users`,
    name: "Users",
    icon: <i className="icon-users"></i>,
    permission: [K.Permissions.ViewUsers],
  },
  {
    path: `${basePath}/client-contacts`,
    name: "Client Contacts",
    icon: <i className="icon-user-square"></i>,
    permission: [K.Permissions.ViewClientContacts],
  },
];

const navigations = [
  {
    name: "Dashboard",
    path: "/",
    icon: <i className="icon-dashboard"></i>,
    extraCondition: hasAtLeastOneAssignedClient,
    permission: [K.Permissions.ViewDashboard],
  },
  {
    name: "Clients",
    path: "/clients",
    icon: <i className="icon-user"></i>,
    extraCondition: hasAtLeastOneAssignedClient,
    // permission: [K.Permissions.ViewClients],
  },
  {
    name: "Jobs",
    path: "/jobs",
    icon: <i className="icon-invoices"></i>,
    extraCondition: hasAtLeastOneAssignedClient,
    permission: [K.Permissions.ViewJobs],
  },
  {
    name: "Candidates",
    path: "/candidates",
    icon: <i className="icon-candidate"></i>,
    extraCondition: hasAtLeastOneAssignedClient,
    permission: [K.Permissions.ViewCandidates],
  },
  {
    name: "Engagements",
    path: "/engagements",
    icon: <i className="icon-candidate"></i>,
    extraCondition: hasAtLeastOneClientWithEngagementMilestones,
    permission: [
      K.Permissions.ManageEngagementsForAssignedCandidates,
      K.Permissions.ManageEngagementsForAssignedClients,
    ],
  },
  {
    name: "Billings & Commissions",
    icon: <i className="icon-check-circle"></i>,
    extraCondition: hasAtLeastOneAssignedClient,
    permission: [
      K.Permissions.ViewBillingApproval,
      K.Permissions.ViewInvoices,
      K.Permissions.ViewCommissionApproval,
      K.Permissions.ViewCommissionStatement,
      K.Permissions.ViewScheduledBilling,
    ],
    children: billingCommissionsChildren("/billings"),
  },
  {
    name: "Configurations",
    icon: <i className="icon-check-circle"></i>,
    permission: [
      K.Permissions.ViewBillingClients,
      K.Permissions.ViewBillingJobs,
      K.Permissions.ViewCommissionPlan,
      K.Permissions.ViewLookupTable,
      K.Permissions.ViewRolesAndPermissions,
      K.Permissions.ViewUsers,
      K.Permissions.ViewClientContacts,
    ],
    children: configurationsChildren("/configurations"),
  },
  {
    name: "Logs",
    path: "/logs",
    icon: <i className="icon-invoices"></i>,
    permission: [K.Permissions.ViewLogs],
  },
];

export default navigations;
