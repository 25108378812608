import User from "redux/models/user";
import K from "utilities/constants";
import qs from "qs";

const { Network } = K;
export default class Request {
  constructor(
    relativeURL,
    method = Network.Method.GET,
    body = null,
    defaultHeaderType = Network.Header.Type.Json,
    headers = {},
    responseType = Network.ResponseType.Json,
  ) {
    const token = User.getToken();
    headers = {
      ...(defaultHeaderType === Network.Header.Type.Json
        ? Network.Header.Default(token)
        : Network.Header.Authorization(token)),
      ...headers,
    };
    this.url = Network.URL.BaseAPI + relativeURL;
    this.method = method;
    this.body = body;
    this.headers = headers;
    this.responseType = responseType;
  }

  static loginUser(email, password) {
    const body = {
      email,
      password,
    };
    return new Request(
      Network.URL.Auth.Login,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static forgotPassword(email, clientHost) {
    const body = {
      email,
      clientHost,
    };
    return new Request(
      Network.URL.Auth.ForgotPassword,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static resetPassword(password, token) {
    const body = {
      password,
      token,
    };
    return new Request(
      Network.URL.Auth.ResetPassword,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static changePassword(body) {
    return new Request(
      K.Network.URL.Auth.ChangePassword,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
    );
  }

  static getLoggedInUserDetails() {
    return new Request(
      Network.URL.Auth.LoggedInUserDetails,
      Network.Method.GET,
      null,
      Network.Header.Type.Json,
      {},
    );
  }

  static getAllUsers(body) {
    return new Request(
      Network.URL.Users.GetAll,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static createUser(body) {
    return new Request(
      Network.URL.Users.CreateUser,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static updateUser(body) {
    return new Request(
      Network.URL.Users.UpdateUser,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static getUserDetailById(id) {
    return new Request(
      `${Network.URL.Users.UserDetailById}/${id}`,
      Network.Method.GET,
      null,
      Network.Header.Type.Json,
      {},
    );
  }

  static getUserByRoleId(roleId) {
    const body = { roleId };
    return new Request(
      Network.URL.Users.GetByRoleId,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static getUserByDesignationId(id) {
    return new Request(
      `${Network.URL.Users.GetByDesignationId}/${id}`,
      Network.Method.GET,
      null,
      Network.Header.Type.Json,
      {},
    );
  }

  static getLookupTableData(apiKey) {
    return new Request(
      apiKey,
      Network.Method.GET,
      null,
      Network.Header.Type.Json,
      {},
    );
  }

  static deleteLookupData(apiKey, id) {
    return new Request(
      `${apiKey}/${id}`,
      Network.Method.DELETE,
      null,
      Network.Header.Type.Json,
      {},
    );
  }

  static editLookupData(apiKey, id, body) {
    return new Request(
      `${apiKey}/${id}`,
      Network.Method.PATCH,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static addLookupData(apiKey, body) {
    return new Request(
      apiKey,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static editClientLookupData(apiKey, id, clientId, data) {
    console.log("Data", { data, clientId });
    const body = {
      ...data,
      clientId,
    };
    return new Request(
      `${apiKey}/${id}`,
      Network.Method.PATCH,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static addClientLookupData(apiKey, clientId, data) {
    console.log("Data", { data, clientId });
    const body = {
      ...data,
      clientId,
    };
    return new Request(
      apiKey,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static moveLookupDataColumn(apikey, value) {
    const body = { ...value };
    return new Request(
      apikey,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static getClientContact() {
    return new Request(
      Network.URL.ClientContacts,
      Network.Method.GET,
      null,
      Network.Header.Type.Json,
      {},
    );
  }

  static createClientContact(values) {
    const body = { ...values };
    return new Request(
      Network.URL.ClientContacts,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static updateClientContact(values, id) {
    const body = { ...values };
    return new Request(
      `${Network.URL.ClientContacts}/${id}`,
      Network.Method.PUT,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static deleteClientContact(id) {
    return new Request(
      `${Network.URL.ClientContacts}/${id}`,
      Network.Method.DELETE,
      null,
      Network.Header.Type.Json,
      {},
    );
  }

  static addBillingClient(values) {
    const body = { ...values };
    return new Request(
      Network.URL.BillingClient.Create,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static addNewBillingClientRule(values) {
    const body = { ...values };
    return new Request(
      Network.URL.BillingClient.AddNew,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static getBillingClient() {
    return new Request(
      Network.URL.BillingClient.GetAll,
      Network.Method.GET,
      null,
      Network.Header.Type.Json,
      {},
    );
  }

  static addBillingJob(body) {
    return new Request(
      Network.URL.BillingJob.Create,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static getBillingJob() {
    return new Request(
      Network.URL.BillingJob.GetAll,
      Network.Method.GET,
      null,
      Network.Header.Type.Json,
      {},
    );
  }

  static getClientsList() {
    return new Request(
      Network.URL.Clients.GetAll,
      Network.Method.GET,
      null,
      Network.Header.Type.Json,
      {},
    );
  }

  static getClientById(id) {
    return new Request(
      `${Network.URL.Clients.ClientDetails.Overview}/${id}`,
      Network.Method.GET,
      null,
      Network.Header.Type.Json,
      {},
    );
  }

  static getAllClientName() {
    return new Request(
      Network.URL.Clients.GetAllName,
      Network.Method.GET,
      null,
      Network.Header.Type.Json,
      {},
    );
  }

  static getClientJobsById(id) {
    return new Request(
      `${Network.URL.Clients.ClientDetails.Jobs}/${id}`,
      Network.Method.GET,
      null,
      Network.Header.Type.Json,
      {},
    );
  }

  static getJobBillingRulesById(id) {
    return new Request(
      `${Network.URL.Clients.ClientDetails.JobBillingRules}/${id}`,
      Network.Method.GET,
      null,
      Network.Header.Type.Json,
      {},
    );
  }

  static getClientBillingRulesById(id) {
    return new Request(
      `${Network.URL.Clients.ClientDetails.ClientBillingRules}/${id}`,
      Network.Method.GET,
      null,
      Network.Header.Type.Json,
      {},
    );
  }

  static addClientLevelBillingRules(body) {
    return new Request(
      Network.URL.Clients.ClientDetails.ClientLevelBillingRules,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static editUpcomingClientBillingRule(body) {
    return new Request(
      Network.URL.Clients.ClientDetails.EditUpcomingClientRule,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static editUpcomingJobBillingRule(body) {
    return new Request(
      Network.URL.Clients.ClientDetails.EditUpcomingJobRule,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static addJobLevelBillingRules(body) {
    return new Request(
      Network.URL.Clients.ClientDetails.JobLevelBillingRules,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static addTier(values) {
    return new Request(
      Network.URL.Clients.ClientDetails.AddTier,
      Network.Method.POST,
      values,
      Network.Header.Type.Json,
      {},
    );
  }

  static getJobTierDetailsById(tierId, clientId) {
    return new Request(
      `${Network.URL.Clients.ClientDetails.Client}/${clientId}${Network.URL.Clients.ClientDetails.JobTier}/${tierId}`,
      Network.Method.GET,
      null,
      Network.Header.Type.Json,
      {},
    );
  }

  static createClient(values) {
    const body = { ...values };
    return new Request(
      Network.URL.Clients.Create,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static addClientJobsById(values) {
    const body = { ...values };
    return new Request(
      Network.URL.Clients.ClientDetails.CreateJob,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static editClientJobs(body) {
    return new Request(
      Network.URL.Clients.ClientDetails.EditJob,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static editClientBillingUnit(value) {
    return new Request(
      Network.URL.Clients.ClientDetails.BillingUnitsEdit,
      Network.Method.POST,
      value,
      Network.Header.Type.Json,
      {},
    );
  }

  static addBillingUnit(value) {
    return new Request(
      Network.URL.Clients.ClientDetails.AddBillingUnit,
      Network.Method.POST,
      value,
      Network.Header.Type.Json,
      {},
    );
  }

  static editClientInformation(value) {
    return new Request(
      Network.URL.Clients.ClientDetails.ClientInformationEdit,
      Network.Method.POST,
      value,
      Network.Header.Type.Json,
      {},
    );
  }

  static getClientTiersById(id) {
    return new Request(
      `${Network.URL.Tiers.GetClientTiers}/${id}`,
      Network.Method.GET,
      null,
      Network.Header.Type.Json,
      {},
    );
  }

  static getCandidateByEmail(email) {
    return new Request(
      `${Network.URL.Candidate.Get}/${email}`,
      Network.Method.GET,
      null,
      Network.Header.Type.Json,
      {},
    );
  }

  static getCandidateActivity(id) {
    return new Request(
      `${Network.URL.Candidate.GetActivity}/${id}`,
      Network.Method.GET,
      null,
      Network.Header.Type.Json,
      {},
    );
  }

  static createJobCandidate(values) {
    const body = { ...values };
    return new Request(
      Network.URL.Candidate.Create,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static getAllCandidates() {
    return new Request(
      Network.URL.Candidate.GetAll,
      Network.Method.GET,
      null,
      Network.Header.Type.Json,
      {},
    );
  }

  static getCandidatesByClientIds(body) {
    return new Request(
      Network.URL.Candidate.GetByClientId,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static getCandidatesByJobId(body) {
    return new Request(
      Network.URL.Candidate.GetByJobId,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static getJobRecruiterByJobId(jobId) {
    return new Request(
      `${Network.URL.Candidate.GetJobRecruiterByJobId}/${jobId}`,
      Network.Method.GET,
      null,
      Network.Header.Type.Json,
      {},
    );
  }

  static getCandidateDetail(id) {
    return new Request(
      `${Network.URL.Candidate.GetById}/${id}`,
      Network.Method.GET,
      null,
      Network.Header.Type.Json,
      {},
    );
  }

  static addToBlacklist(body) {
    return new Request(
      Network.URL.Candidate.AddToBlacklist,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static removeToBlacklist(body) {
    return new Request(
      Network.URL.Candidate.RemoveToBlacklist,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static editJobCandidate(values) {
    const body = { ...values };
    return new Request(
      Network.URL.Candidate.Edit,
      Network.Method.PUT,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static addCommentOnCandidate(values) {
    const body = { ...values };
    return new Request(
      Network.URL.Candidate.AddComment,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static getCandidateComments(id) {
    return new Request(
      `${Network.URL.Candidate.GetComments}/${id}`,
      Network.Method.GET,
      null,
      Network.Header.Type.Json,
      {},
    );
  }

  static getCandidateLogs(id) {
    const body = {
      candidateJobEntryId: id,
    };
    return new Request(
      Network.URL.Candidate.GetCandidateLog,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static getCandidateBillingsIssues(id) {
    return new Request(
      `${Network.URL.Candidate.GetBillings}/${id}`,
      Network.Method.GET,
      null,
      Network.Header.Type.Json,
      {},
    );
  }

  static getJobDetailsById(clientId, jobId) {
    const body = { clientId, jobId };
    return new Request(
      Network.URL.Jobs.GetById,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static updateJobVacancies(jobSourcesId, jobOriginalOpenings) {
    const body = { jobSourcesId, jobOriginalOpenings };
    return new Request(
      Network.URL.Jobs.UpdateVacancies,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static getAllRoles() {
    return new Request(
      Network.URL.RoleAndPermissions.GetAllRole,
      Network.Method.GET,
      null,
      Network.Header.Type.Json,
      {},
    );
  }

  static getAllPermissions() {
    return new Request(
      Network.URL.RoleAndPermissions.GetAllPermission,
      Network.Method.GET,
      null,
      Network.Header.Type.Json,
      {},
    );
  }

  static createRole(body) {
    return new Request(
      Network.URL.RoleAndPermissions.CreateRole,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static getRoleById(roleId) {
    return new Request(
      `${Network.URL.RoleAndPermissions.GetRoleById}/${roleId}`,
      Network.Method.GET,
      null,
      Network.Header.Type.Json,
      {},
    );
  }

  static updateRole(roleId, body) {
    return new Request(
      `${Network.URL.RoleAndPermissions.UpdateRole}/${roleId}`,
      Network.Method.PUT,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static getJobSettings(jobSourcesId, clientId) {
    const body = { jobSourcesId, clientId };
    return new Request(
      Network.URL.Jobs.GetSettings,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static updateJobSettings(values) {
    const body = { ...values };
    return new Request(
      Network.URL.Jobs.UpdateSettings,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static cancelJobById(body) {
    return new Request(
      Network.URL.Jobs.CancelById,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static CustomCancelBilling(body) {
    return new Request(
      Network.URL.Jobs.CustomCancelBilling,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static getJobsByClientIds(clientIds) {
    const body = { clientIds };
    return new Request(
      Network.URL.Jobs.GetByClientIds,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static getJobStatistics(jobId) {
    return new Request(
      `${Network.URL.Jobs.GetStatistics}/${jobId}`,
      Network.Method.GET,
      null,
      Network.Header.Type.Json,
      {},
    );
  }

  static getAllCommission() {
    return new Request(
      Network.URL.CommissionPlans.GetAll,
      Network.Method.GET,
      null,
      Network.Header.Type.Json,
      {},
    );
  }

  static uploadFile(formData) {
    return new Request(
      Network.URL.Files.Upload,
      Network.Method.POST,
      formData,
      Network.Header.Type.File,
      {},
    );
  }

  static createCommissionPlan(body) {
    return new Request(
      Network.URL.CommissionPlans.CreateCommissionPlan,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static getCommissionPlansById(planId) {
    const body = { id: planId };
    return new Request(
      Network.URL.CommissionPlans.GetCommissionPlansById,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static addNewCommissionPlan(body) {
    return new Request(
      Network.URL.CommissionPlans.AddNewCommissionPlan,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static deleteUpcommingPlan(commissionTimelineId) {
    return new Request(
      Network.URL.CommissionPlans.DeleteUpcommingPlan,
      Network.Method.POST,
      { commissionTimelineId },
      Network.Header.Type.Json,
      {},
    );
  }

  static editUserProfile(body) {
    return new Request(
      Network.URL.Users.EditUserProfile,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static saveTableColumnSorting(body) {
    return new Request(
      Network.URL.Users.SaveColumnSort,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static saveTableVisibleColumn(body) {
    return new Request(
      Network.URL.Users.SaveColumnSort,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static getUserConfigrations(key) {
    return new Request(
      `${Network.URL.Users.GetConfigrations}/${key}`,
      Network.Method.GET,
      null,
      Network.Header.Type.Json,
      {},
    );
  }

  static getLogs(body) {
    return new Request(
      Network.URL.Users.GetLogs,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static getSubStatusBreakdown(jobId) {
    return new Request(
      `${Network.URL.Status.GetSubStatusBreakdown}/${jobId}`,
      Network.Method.GET,
      null,
      Network.Header.Type.Json,
      {},
    );
  }

  static undoCandidateStatus(id) {
    return new Request(
      `${Network.URL.Status.UndoStatus}/${id}`,
      Network.Method.GET,
      null,
      Network.Header.Type.Json,
      {},
    );
  }

  static changeCandidateStatusDate(body) {
    return new Request(
      Network.URL.Status.ChangeStatusDate,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static getAllStatus() {
    return new Request(
      Network.URL.Status.GetAll,
      Network.Method.GET,
      null,
      Network.Header.Type.Json,
      {},
    );
  }

  static getAllCurrencies() {
    return new Request(
      Network.URL.Currency,
      Network.Method.GET,
      null,
      Network.Header.Type.Json,
      {},
    );
  }

  static changeCandidateStatus(values) {
    return new Request(
      Network.URL.Candidate.ChangeStatus,
      Network.Method.POST,
      values,
      Network.Header.Type.Json,
      {},
    );
  }

  static getActiveCandidateSummary(body) {
    return new Request(
      Network.URL.Dashboard.PipelineDashboard.Summary,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static hireChart(body) {
    return new Request(
      Network.URL.Dashboard.HistoricalPerformance.HireChart,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static submittalDeclineChart(body) {
    return new Request(
      Network.URL.Dashboard.HistoricalPerformance.SubmittalDecline,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static interviewDeclineChart(body) {
    return new Request(
      Network.URL.Dashboard.HistoricalPerformance.InterviewDecline,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static interviewNoShowChart(body) {
    return new Request(
      Network.URL.Dashboard.HistoricalPerformance.InterviewNoShowChart,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static postOfferFallOutChart(body) {
    return new Request(
      Network.URL.Dashboard.HistoricalPerformance.PostOfferFallOutChart,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static postOfferFallOutReasonChart(body) {
    return new Request(
      Network.URL.Dashboard.HistoricalPerformance.PostOfferFallOutReasonChart,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static getClientDepartments() {
    return new Request(
      Network.URL.ClientDeparments,
      Network.Method.GET,
      null,
      Network.Header.Type.Json,
      {},
    );
  }

  static getRecruitmentPipelineData(body) {
    return new Request(
      Network.URL.Dashboard.HistoricalPerformance.RecruitmentPipeline,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static getRequisitionDetail(body) {
    return new Request(
      Network.URL.Dashboard.PipelineDashboard.RequisitionDetail,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static getClientSubmittedBillings(clientIds) {
    return new Request(
      Network.URL.BillingApproval.GetClientSubmittedBilling,
      Network.Method.POST,
      { clientIds },
      Network.Header.Type.Json,
      {},
    );
  }

  static getSubmittedBillHistory(body) {
    return new Request(
      Network.URL.BillingApproval.GetSubmittedBillHistory,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static editJobSubmittedBilling(body) {
    return new Request(
      Network.URL.BillingApproval.EditJobSubmittedBilling,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static editClientSubmittedBilling(body) {
    return new Request(
      Network.URL.BillingApproval.EditClientSubmittedBilling,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static currencyConversion(body) {
    return new Request(
      Network.URL.BillingApproval.CurrencyConversion,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static generateBillingInvoice(body) {
    return new Request(
      Network.URL.BillingApproval.CreateInvoice,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static getAllInvoices(body) {
    return new Request(
      Network.URL.Invoices.GetAll,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static getInvoiceDetails(id) {
    return new Request(
      `${Network.URL.Invoices.GetDetails}/${id}`,
      Network.Method.GET,
      null,
      Network.Header.Type.Json,
      {},
    );
  }

  static exportInvoiceDetail(id) {
    return new Request(
      `${Network.URL.Invoices.InvoiceDetailExport}/${id}`,
      Network.Method.GET,
      null,
      Network.Header.Type.Json,
      {},
      Network.ResponseType.Blob,
    );
  }

  static editInvoice(body) {
    return new Request(
      Network.URL.Invoices.EditInvoice,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static deleteInvoice(body) {
    return new Request(
      Network.URL.Invoices.DeleteInvoice,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static issueJobCredit(body) {
    return new Request(
      Network.URL.BillingApproval.IssueCredit,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static testClientBillingRules(data) {
    return new Request(
      Network.URL.Clients.BillingRuleTest,
      Network.Method.POST,
      { data },
      Network.Header.Type.Json,
      {},
    );
  }

  static getBillingScheduleByClientId(clientsId) {
    return new Request(
      Network.URL.BillingSchedule.GetJobBillingSchedule,
      Network.Method.POST,
      { clientsId },
      Network.Header.Type.Json,
      {},
    );
  }

  static getClientBillingScheduled(clientsId) {
    return new Request(
      Network.URL.BillingSchedule.GetClientBillingSchedule,
      Network.Method.POST,
      { clientsId },
      Network.Header.Type.Json,
      {},
    );
  }

  static updateJobScheduledbilling(body, id) {
    return new Request(
      `${Network.URL.BillingSchedule.UpdateJobScheduledbilling}/${id}`,
      Network.Method.PUT,
      { ...body },
      Network.Header.Type.Json,
      {},
    );
  }

  static updateClientScheduledbilling(body, id) {
    return new Request(
      `${Network.URL.BillingSchedule.UpdateClientScheduledbilling}/${id}`,
      Network.Method.PUT,
      { ...body },
      Network.Header.Type.Json,
      {},
    );
  }

  static getScheduledBillingByCandidateId(id) {
    return new Request(
      `${Network.URL.BillingSchedule.GetScheduledBillingByCandidateId}/${id}`,
      Network.Method.GET,
      {},
      Network.Header.Type.Json,
      {},
    );
  }

  static getOneTimeBillingClientEntries(id, currentDate) {
    return new Request(
      `${Network.URL.BillingClient.GetOneTimeEntries}/${id}/${currentDate}`,
      Network.Method.GET,
      {},
      Network.Header.Type.Json,
      {},
    );
  }

  static submitOneTimeClientBilling(body) {
    return new Request(
      Network.URL.BillingClient.SubmitOneTimeBilling,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static getCommissionApprovals(recruiterIds) {
    return new Request(
      Network.URL.CommissionPlans.GetCommissionApprovals,
      Network.Method.POST,
      { recruiterIds },
      Network.Header.Type.Json,
      {},
    );
  }

  static editCommissionApproval(body) {
    return new Request(
      Network.URL.CommissionPlans.EditCommissionApproval,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static getCommissionStatements(recruiterIds) {
    return new Request(
      Network.URL.CommissionPlans.GetCommissionStatements,
      Network.Method.POST,
      { recruiterIds },
      Network.Header.Type.Json,
      {},
    );
  }

  static getCommissionStatementDetails(id) {
    return new Request(
      `${Network.URL.CommissionPlans.GetCommissionStatementDetail}/${id}`,
      Network.Method.Get,
      null,
      Network.Header.Type.Json,
      {},
    );
  }

  static editCandidateDetailsById(body) {
    return new Request(
      Network.URL.Candidate.EditCandidateDetail,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static createCommissionStatement(body) {
    return new Request(
      Network.URL.CommissionPlans.CreateCommissionStatement,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static updateTierDetails(body) {
    return new Request(
      Network.URL.Clients.ClientDetails.UpdateTierDetails,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static calculateBilling(candidateJobEntry, date) {
    return new Request(
      `${Network.URL.Candidate.CalculateBilling}/${candidateJobEntry}/${date}`,
      Network.Method.GET,
      {},
      Network.Header.Type.Json,
      {},
    );
  }

  static editBillingClient(body) {
    return new Request(
      Network.URL.BillingClient.EditBillingClient,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static editBillingJob(body) {
    return new Request(
      Network.URL.BillingJob.EditBillingJob,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static sortCandidateByStatus(statusId, jobId, subStatusId) {
    let path = `${Network.URL.Candidate.SortCandidateByStatus}/${jobId}/${statusId}`;
    if (subStatusId) {
      path += `/${subStatusId}`;
    }
    return new Request(
      path,
      Network.Method.GET,
      {},
      Network.Header.Type.Json,
      {},
    );
  }

  static uploadCSV(body) {
    return new Request(
      Network.URL.Files.UploadCSV,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static uploadCandidate(body) {
    return new Request(
      Network.URL.Files.UploadCSV,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static abortClientBillingRule(id) {
    return new Request(
      Network.URL.Clients.ClientDetails.AbortClientBillingRule,
      Network.Method.POST,
      { clientBillingRuleId: id },
      Network.Header.Type.Json,
      {},
    );
  }

  static abortJobBillingRule(id) {
    return new Request(
      Network.URL.Clients.ClientDetails.AbortJobBillingRule,
      Network.Method.POST,
      { billingRuleId: id },
      Network.Header.Type.Json,
      {},
    );
  }

  static deleteCV(path) {
    return new Request(
      Network.URL.Files.DeleteCV,
      Network.Method.POST,
      { filePath: path },
      Network.Header.Type.File,
      {},
    );
  }

  static saveDashboardVisibleCharts(body) {
    return new Request(
      Network.URL.Users.SaveColumnSort,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static getUserPlanTimelines(id) {
    const body = null;
    return new Request(
      `${Network.URL.Users.GetUserPlanTimeline}/${id}`,
      Network.Method.GET,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static handleUserActive(body) {
    return new Request(
      Network.URL.Users.HandleUserActive,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static editCandidateOfferedSalary(body) {
    return new Request(
      Network.URL.Candidate.EditOfferSalary,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static exportJobDetail(body) {
    return new Request(
      Network.URL.Export.Job,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
      Network.ResponseType.Blob,
    );
  }

  static exportJobCandidates(body) {
    return new Request(
      Network.URL.Export.Candidate,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
      Network.ResponseType.Blob,
    );
  }

  static updateJobStatusById(body) {
    return new Request(
      Network.URL.Jobs.UpdateJobStatus,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static submitReCalculateBillings(
    billingRuleEntryId,
    candidateJobEntryId,
    salary,
  ) {
    return new Request(
      Network.URL.BillingApproval.ReCalculateBillings,
      Network.Method.POST,
      { billingRuleEntryId, candidateJobEntryId, salary },
      Network.Header.Type.Json,
      {},
    );
  }

  static submitRunTimeCalculation(
    billingRuleEntryId,
    candidateJobEntryId,
    salary,
  ) {
    return new Request(
      Network.URL.BillingApproval.RunTimeCalculations,
      Network.Method.POST,
      { billingRuleEntryId, candidateJobEntryId, salary },
      Network.Header.Type.Json,
      {},
    );
  }

  static submitCalculateDiscount(body) {
    return new Request(
      Network.URL.BillingApproval.SubmitCalculateDiscount,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static submitOnetimeJobBilling(data) {
    const body = data;
    return new Request(
      Network.URL.BillingJob.SubmitOnetime,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static getClientHiringManager(body) {
    return new Request(
      Network.URL.Users.GetClientHiringManager,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static getVBDRule(clientId) {
    return new Request(
      `${Network.URL.VBD.GetByClientId}/${clientId}`,
      Network.Method.GET,
      null,
      Network.Header.Type.Json,
      {},
    );
  }

  static createVBD(body) {
    return new Request(
      Network.URL.VBD.create,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static exportCommissionStatements(body) {
    return new Request(
      Network.URL.CommissionPlans.ExportCommissionStatement,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
      Network.ResponseType.Blob,
    );
  }

  static deleteCommissionStatements(id) {
    return new Request(
      `${Network.URL.CommissionPlans.DeleteCommissionStatement}/${id}`,
      Network.Method.GET,
      null,
      Network.Header.Type.Json,
      {},
    );
  }

  static getJobDepartmentByMultipleClient(body) {
    return new Request(
      Network.URL.ClientLookupTable.JobDepartmentByMultipleClient,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static getJobLocationByMultipleClient(body, loader) {
    return new Request(
      Network.URL.ClientLookupTable.JobLocationByMultipleClient,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
      loader,
    );
  }

  static suggestAnEmail(jobId) {
    return new Request(
      `${Network.URL.Candidate.SuggestAnEmail}/${jobId}`,
      Network.Method.GET,
      null,
      Network.Header.Type.Json,
      {},
    );
  }

  static deleteJob(jobId) {
    return new Request(
      `${Network.URL.Jobs.DeleteJob}/${jobId}`,
      Network.Method.GET,
      null,
      Network.Header.Type.Json,
      {},
    );
  }

  static deleteCandiate(candidateJobEntryId) {
    return new Request(
      `${Network.URL.Candidate.DeleteCandidate}/${candidateJobEntryId}`,
      Network.Method.GET,
      null,
      Network.Header.Type.Json,
      {},
    );
  }

  static deleteClientContact(contactId) {
    return new Request(
      `${Network.URL.DeleteClientContact}/${contactId}`,
      Network.Method.GET,
      null,
      Network.Header.Type.Json,
      {},
    );
  }

  static deleteUser(userId) {
    return new Request(
      `${Network.URL.Users.DeleteUser}/${userId}`,
      Network.Method.GET,
      null,
      Network.Header.Type.Json,
      {},
    );
  }

  static getRecruiterByClientsId(body) {
    return new Request(
      Network.URL.Clients.Recruiters,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static saveLookUpSorting(body) {
    return new Request(
      Network.URL.Lookup.SaveSorting,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static getJobDetailByCandidateStatus(body) {
    return new Request(
      Network.URL.Dashboard.PipelineDashboard.JobDetailByCandiateStatus,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static transferCandiateJob(body) {
    return new Request(
      Network.URL.Candidate.TransferCandidateJob,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static getOnlyJobsByClientIds(body) {
    return new Request(
      Network.URL.Jobs.GetOnlyJobsByClientIds,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static getCandidateBillings(candidateJobEntryId) {
    return new Request(
      `${Network.URL.Candidate.GetCandidateBilling}/${candidateJobEntryId}`,
      Network.Method.GET,
      null,
      Network.Header.Type.Json,
      {},
    );
  }

  static bulkUpdateInvoice(body) {
    return new Request(
      Network.URL.Invoices.BulkUpdate,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static editClientBillingRuleStartDate(body) {
    return new Request(
      Network.URL.Clients.ClientDetails.EditClientBillingRuleStartDate,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static getUdfFields() {
    return new Request(
      Network.URL.UDF.GetFields,
      Network.Method.GET,
      null,
      Network.Header.Type.Json,
      {},
    );
  }

  static createUDF(body) {
    return new Request(
      Network.URL.UDF.Create,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static updateUDF(body) {
    return new Request(
      Network.URL.UDF.Update,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static GetUDF(body) {
    return new Request(
      Network.URL.UDF.GetUDF,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static GetEditUdfList(body) {
    return new Request(
      Network.URL.UDF.GetEditUDFList,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static editUdfValue(body) {
    return new Request(
      Network.URL.UDF.EditUDFValues,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static editExistingJobLevelBillingRuleDate(body) {
    return new Request(
      Network.URL.Clients.ClientDetails.EditExistingJobLevelBillingRuleDate,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static createAttachment(body) {
    return new Request(
      Network.URL.Attachment.create,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static getAttachment(body) {
    return new Request(
      Network.URL.Attachment.get,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static getPreSignedUrl(url) {
    const body = { path: url };
    return new Request(
      Network.URL.Attachment.preSignedUrl,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static getLookupHistory(body) {
    return new Request(
      Network.URL.Lookup.GetHistory,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static deleteFileFromS3(url) {
    const body = { filePath: url };
    return new Request(
      Network.URL.Attachment.deleteS3,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static deleteAttachment(id) {
    const body = null;
    return new Request(
      `${Network.URL.Attachment.delete}/${id}`,
      Network.Method.GET,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static ttmChart(body) {
    return new Request(
      Network.URL.Dashboard.HistoricalPerformance.TTMChart,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static syncCanidateTtm(jobId) {
    return new Request(
      `${Network.URL.TTM.syncCandiateTTM}/${jobId}`,
      Network.Method.GET,
      null,
      Network.Header.Type.Json,
      {},
    );
  }

  static addClientClass(body) {
    return new Request(
      Network.URL.Class.addClass,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static getClientClassList(clientId) {
    return new Request(
      `${Network.URL.Class.getClientClassList}/${clientId}`,
      Network.Method.GET,
      null,
      Network.Header.Type.Json,
      {},
    );
  }

  static getClassDetailById(classId) {
    return new Request(
      `${Network.URL.Class.getClassDetailById}/${classId}`,
      Network.Method.GET,
      null,
      Network.Header.Type.Json,
      {},
    );
  }

  static getCandidatesByClassId(classId) {
    return new Request(
      `${Network.URL.Candidate.GetByClassId}/${classId}`,
      Network.Method.GET,
      null,
      Network.Header.Type.Json,
      {},
    );
  }

  static getClassSettings(classId, clientId) {
    return new Request(
      `${Network.URL.Class.getClassSetting}/${classId}`,
      Network.Method.GET,
      null,
      Network.Header.Type.Json,
      {},
    );
  }

  static assignCandidateClass(body) {
    return new Request(
      Network.URL.Class.assignCandidate,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static detachCandidateClass(body) {
    return new Request(
      Network.URL.Class.detachCandidate,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static getClientClassManager(body) {
    return new Request(
      Network.URL.Class.getClientClassManager,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static updateClassSettings(values) {
    const body = { ...values };
    return new Request(
      Network.URL.Class.updateClassSetting,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static getClassStatistics(classId) {
    return new Request(
      `${Network.URL.Class.GetStatistics}/${classId}`,
      Network.Method.GET,
      null,
      Network.Header.Type.Json,
      {},
    );
  }

  static getClassSubStatusBreakdown(classId) {
    return new Request(
      `${Network.URL.Class.StatusAndSubStatusBreakDown}/${classId}`,
      Network.Method.GET,
      null,
      Network.Header.Type.Json,
      {},
    );
  }

  static updateClassStatusById(body) {
    return new Request(
      Network.URL.Class.UpdateClassStatus,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static getAllJobsForExcelUpload() {
    return new Request(
      Network.URL.UploadExcelFile.jobs,
      Network.Method.GET,
      null,
      Network.Header.Type.Json,
      {},
    );
  }

  static getAllRecruitersForExcelUpload() {
    return new Request(
      Network.URL.UploadExcelFile.recruiters,
      Network.Method.GET,
      null,
      Network.Header.Type.Json,
      {},
    );
  }

  static getAllClassForExcelUpload() {
    return new Request(
      Network.URL.UploadExcelFile.class,
      Network.Method.GET,
      null,
      Network.Header.Type.Json,
      {},
    );
  }

  static getClientJobsWithServerSidePagination(body) {
    return new Request(
      Network.URL.Clients.ClientDetails.JobsWithServerSide,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static getJobsByClientIdsWithServerSide(body) {
    return new Request(
      Network.URL.Jobs.GetByClientIdsWithServerSide,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static changeCandidateStatusInBulk(body) {
    return new Request(
      Network.URL.Candidate.ChangeCandidateStatusInBulk,
      Network.Method.POST,
      body,
      Network.Header.Type.Json,
      {},
    );
  }

  static filledLastJobOpening(jobId) {
    return new Request(
      `${Network.URL.Jobs.FilledJobLastOpening}/${jobId}`,
      Network.Method.GET,
      null,
      Network.Header.Type.Json,
      {},
    );
  }

  static v2 = {
    getDashboardPipelineSummary: (params) => {
      const queryString = qs.stringify(params);
      return new Request(
        `${Network.URL.V2.Dashboard.Pipeline.Summary}?${queryString}`,
        Network.Method.GET,
        null,
        Network.Header.Type.Json,
        {},
      );
    },

    getDashboardPipelineDetails: (params) => {
      const queryString = qs.stringify(params);
      return new Request(
        `${Network.URL.V2.Dashboard.Pipeline.Details}?${queryString}`,
        Network.Method.GET,
        null,
        Network.Header.Type.Json,
        {},
      );
    },

    getCandidates: (params) => {
      const queryString = qs.stringify(params);
      return new Request(
        `${Network.URL.V2.Candidates.All}?${queryString}`,
        Network.Method.GET,
        null,
        Network.Header.Type.Json,
        {},
      );
    },

    getCandidateActivities: (candidateId) => {
      return new Request(
        Network.URL.V2.Candidates.Activities.replace(":id", candidateId),
        Network.Method.GET,
        null,
        Network.Header.Type.Json,
        {},
      );
    },

    mergeCandidates: (body) => {
      return new Request(
        Network.URL.V2.Candidates.Merge,
        Network.Method.POST,
        body,
        Network.Header.Type.Json,
        {},
      );
    },

    exportReportJobsVacancies: (params) => {
      const queryString = qs.stringify(params);
      return new Request(
        `${Network.URL.V2.Export.Reports.JobsVacancies}?${queryString}`,
        Network.Method.GET,
        null,
        Network.Header.Type.Json,
        {},
        Network.ResponseType.Blob,
      );
    },

    exportReportCandidateJobEntries: (params) => {
      const queryString = qs.stringify(params);
      return new Request(
        `${Network.URL.V2.Export.Reports.CandidateJobEntries}?${queryString}`,
        Network.Method.GET,
        null,
        Network.Header.Type.Json,
        {},
        Network.ResponseType.Blob,
      );
    },

    getEngagements: (params) => {
      const queryString = qs.stringify(params, {
        arrayFormat: "comma",
      });
      return new Request(
        `${Network.URL.V2.Engagements.All}?${queryString}`,
        Network.Method.GET,
        null,
        Network.Header.Type.Json,
        {},
      );
    },

    editEngagement: (id, params) => {
      return new Request(
        Network.URL.V2.Engagements.Edit.replace(":id", id),
        Network.Method.PATCH,
        params,
        Network.Header.Type.Json,
        {},
      );
    },

    getEngagementRecruiters: (params) => {
      const queryString = qs.stringify(params, {
        arrayFormat: "comma",
        encode: false,
      });
      return new Request(
        `${Network.URL.V2.Engagements.Recruiters}?${queryString}`,
        Network.Method.GET,
        null,
        Network.Header.Type.Json,
        {},
      );
    },

    editCandidateJobEntry(jobId, candidateId, params) {
      return new Request(
        Network.URL.V2.Candidates.Edit.replace(":jobId", jobId).replace(
          ":candidateId",
          candidateId,
        ),
        Network.Method.POST,
        params,
        Network.Header.Type.Json,
        {},
      );
    },

    exportEngagements: (params) => {
      const queryString = qs.stringify(params, {
        arrayFormat: "comma",
      });
      return new Request(
        `${Network.URL.V2.Export.Reports.Engagements}?${queryString}`,
        Network.Method.GET,
        null,
        Network.Header.Type.Json,
        {},
      );
    },
  };
}
