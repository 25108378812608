import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  InputNumber,
  message,
  Row,
  Typography,
} from "antd";
import "./jobSettingsModal.scss";
import Job from "redux/models/job";
import Scrollbars from "react-custom-scrollbars-2";
import EmptyPlaceholder from "common/components/emptyPlaceholder/emptyPlaceholder";
import K from "utilities/constants";
import { customFormatSettingTime } from "utilities/dateUtility";
import TextArea from "antd/lib/input/TextArea";
import { useState } from "react";

const { Paragraph, Title } = Typography;

export default function JobSettingModal({
  form,
  jobId,
  jobData,
  jobStats,
  setJobData,
  jobDetails,
  setModalVisible,
}) {
  const [loading, setLoading] = useState(false);

  const jobHistory = [...jobStats.history];

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const res = await Job.updateSettings({
        ...values,
        jobSourcesId: +jobId,
        jobOriginalOpenings: jobDetails.vacancies.totalOpenings,
      });

      setModalVisible((prev) => {
        return { ...prev, isSettingModalVisible: false };
      });
      setJobData((prev) => ({
        ...prev,
        settings: res,
        shouldUpdate: !prev.shouldUpdate,
      }));
      form.resetFields();
      message.success("Settings Updated.");
      Job.syncCandidateTTM(+jobId);
    } catch (err) {
      console.error(err);
      message.error("Failed to update settings.");
    } finally {
      setLoading(false);
    }
  };

  const validateOpeningsToBill = (value) => {
    const diff =
      jobStats.setting.currentOpenings - form.getFieldValue("currentOpenings");
    if (value <= diff) {
      return Promise.resolve();
    }
    return Promise.reject(`Value should be less than or equal to ${diff}`);
  };

  return (
    <Form layout="vertical" form={form} onFinish={onFinish}>
      {/* <Title className="VacancyTitle" level={5}>
        Openings
      </Title> */}
      <Card className="opening-card">
        <div className="title-wrap">
          <h5 className="VacancyTitle">Openings</h5>
          <h5 level={5} className="total-opening">
            Current:{jobStats?.setting?.currentOpenings}
          </h5>
        </div>
        <Row gutter={16}>
          <Col xs={24} sm={14}>
            <Form.Item
              name="currentOpenings"
              rules={[
                {
                  required: true,
                  message: "Please enter number of current openings.",
                },
              ]}
            >
              <InputNumber
                className="vacancyNumberZero"
                placeholder="No of New Openings"
                min={0}
                disabled={!jobData.jobDetails?.vacancies?.isActive ?? true}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, curValues) =>
                prevValues.currentOpenings !== curValues.currentOpenings
              }
            >
              {({ getFieldValue }) =>
                getFieldValue("currentOpenings") <
                  jobStats?.setting?.currentOpenings && (
                  <Form.Item
                    label="How many orders are eligible for billing?"
                    name="jobCancelledOpenings"
                    initialValue={0}
                    dependencies={["currentOpenings"]}
                    rules={[
                      {
                        required: true,
                        message: "Please enter number of eligible orders.",
                      },
                      () => ({
                        validator(_, value) {
                          return validateOpeningsToBill(value);
                        },
                      }),
                    ]}
                  >
                    <InputNumber
                      className="vacancyNumberZero"
                      placeholder="0"
                      min={0}
                      // disabled={!jobData.jobDetails?.vacancies?.isActive ?? true}
                    />
                  </Form.Item>
                )
              }
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              name="reasons"
              rules={[
                {
                  required: true,
                  message: "Please enter suitable reason.",
                },
              ]}
            >
              <TextArea
                placeholder="Reason"
                disabled={!jobData.jobDetails?.vacancies?.isActive ?? true}
                autoSize={{
                  minRows: 5,
                  maxRows: 6,
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <div className="add-position-wrap">
          <Button
            loading={loading}
            disabled={!jobData.jobDetails?.vacancies?.isActive ?? true}
            onClick={() => form.submit()}
          >
            Confirm
          </Button>
        </div>
      </Card>
      <Card className="opening-card">
        <div className="title-wrap details-wrap">
          <h2 className="VacancyTitle">Opening History</h2>
        </div>

        <div className="settingHistoryWrap ">
          <Scrollbars style={{ height: 270 }}>
            {jobHistory.length ? (
              jobHistory.map((item, i, source) => (
                <div
                  key={item.id}
                  className="settingHistoryDetail history-detail-card"
                >
                  <div className="settingAmountHistory">
                    <div className="clientName history-detail">
                      <Title level={5}>Previous Openings:</Title>
                      <Paragraph>{item.previousOpening}</Paragraph>
                    </div>
                    <div className="clientName history-detail">
                      <Title level={5}>Current Openings:</Title>
                      <Paragraph>{item.currentOpenings}</Paragraph>
                    </div>
                  </div>

                  <div className="clientAmount history-detail">
                    <Title level={5} className="reason">
                      Reason:<span> {item.reasons ?? K.NullPlaceholder}</span>
                    </Title>
                  </div>
                  <Divider className="historyDivider" />
                  <div className="settingAmountHistory">
                    <div className="clientName history-detail">
                      <Title level={5}>Updated By:</Title>
                      <Paragraph>{item.user?.name}</Paragraph>
                    </div>
                    <div className="clientName history-detail">
                      <Title level={5}>Updated Date:</Title>
                      <Paragraph>
                        {" "}
                        {i === source.length - 1
                          ? customFormatSettingTime(item.openDate, false)
                          : customFormatSettingTime(item.createdAt, true)}
                      </Paragraph>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <EmptyPlaceholder />
            )}
          </Scrollbars>
        </div>
      </Card>
      {/* <div className="modalVacancyContainer">
        <div className="modalBodyVacancy">
          <Paragraph className="vacancyDate">
            Last updated at{" "}
            {moment(jobHistory[0]?.createdAt).format(
              K.DateFormat.DashUSFormatTimeStamp,
            )}{" "}
            by {jobHistory[0]?.user?.name}
          </Paragraph>
          <Paragraph className="vacancyNumber">
            {!jobData.jobDetails?.vacancies?.isActive
              ? 0
              : jobStats.setting?.currentOpenings}
          </Paragraph>
          <Title className="totalVacancy">Current Openings</Title>
        </div>
      </div> */}

      {/* <Row gutter={16}>
        <Col xs={24} sm={14}>
          <Form.Item
            label="Enter New Current Openings"
            name="currentOpenings"
            rules={[
              {
                required: true,
                message: "Please enter number of current openings.",
              },
            ]}
          >
            <InputNumber
              className="vacancyNumberZero"
              placeholder="0"
              min={0}
              // disabled={!jobData.jobDetails?.vacancies?.isActive ?? true}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={10}>
          <Form.Item
            label="No of Submittals"
            className="no-of-submitText"
            style={{ textAlign: "center" }}
          >
            <Paragraph className="submittalNumber">
              {jobDetails.submittedCount}
            </Paragraph>
          </Form.Item>
        </Col>
      </Row> */}
      {/* <Row>
        <Col xs={24} sm={24}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, curValues) =>
              prevValues.currentOpenings !== curValues.currentOpenings
            }
          >
            {({ getFieldValue }) =>
              getFieldValue("currentOpenings") <
                jobStats?.setting?.currentOpenings && (
                <Form.Item
                  label="How many orders are eligible for billing?"
                  name="jobCancelledOpenings"
                  initialValue={0}
                  dependencies={["currentOpenings"]}
                  rules={[
                    {
                      required: true,
                      message: "Please enter number of eligible orders.",
                    },
                    () => ({
                      validator(_, value) {
                        return validateOpeningsToBill(value);
                      },
                    }),
                  ]}
                >
                  <InputNumber
                    className="vacancyNumberZero"
                    placeholder="0"
                    min={0}
                    // disabled={!jobData.jobDetails?.vacancies?.isActive ?? true}
                  />
                </Form.Item>
              )
            }
          </Form.Item>
        </Col>
      </Row> */}
      {/* <Form.Item
        name="reasons"
        label="Reason"
        rules={[{ required: true, message: "Enter reason!" }]}
      >
        <Input
          className="vacancyNumberZero"
          placeholder="Reason"
          disabled={!jobData.jobDetails?.vacancies?.isActive ?? true}
        />
      </Form.Item> */}
      {/*
      <Title level={5} className="VacancyTitle">
        Opening History
      </Title>
      <div className="settingHistoryWrap">
        <Scrollbars style={{ height: 270 }}>
          {jobHistory.length ? (
            jobHistory.map((item, i, source) => (
              <div key={item.id} className="settingHistoryDetail">
                <div className="settingAmountHistory">
                  <div className="clientName">
                    <Title level={5}>Updated By:</Title>
                    <Paragraph>{item.user?.name}</Paragraph>
                  </div>
                  <div className="clientName">
                    <Title level={5}>Previous Openings:</Title>
                    <Paragraph>{item.previousOpening}</Paragraph>
                  </div>
                  <div className="clientName">
                    <Title level={5}>Current Openings:</Title>
                    <Paragraph>{item.currentOpenings}</Paragraph>
                  </div>
                </div>
                <Divider className="historyDivider" />
                <div className="clientAmount">
                  <Title level={5}>
                    Updated Date:{" "}
                    {i === source.length - 1
                      ? customFormatSettingTime(item.openDate, false)
                      : customFormatSettingTime(item.createdAt, true)}
                  </Title>
                </div>

                <div className="clientAmount">
                  <Title level={5}>
                    Reason: {item.reasons ?? K.NullPlaceholder}
                  </Title>
                </div>
              </div>
            ))
          ) : (
            <EmptyPlaceholder />
          )}
        </Scrollbars>
      </div> */}
    </Form>
  );
}
