import { AgGridReact } from "ag-grid-react";
import { Button, message, Modal, Tag } from "antd";
import { useForm } from "antd/es/form/Form";
import { tooltipTagRenderer } from "features/billingCommissions/submitToBilling/tableColumns";
import CreateJob from "features/clients/details/jobs/createJob";
import DeleteJob from "features/clients/details/jobs/deleteJob";
import LayoutCss from "layout/layout.module.scss";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Job from "redux/models/job";
import User from "redux/models/user";
import { selectConfigration } from "redux/slices/configrationSlice";
import { selectUser } from "redux/slices/userSlice";
import K from "utilities/constants";
import { convertIntoDashUSFormat } from "utilities/dateUtility";
import { isPermissionPresent } from "utilities/generalUtility";
import {
  displayDollar,
  filterColumnListing,
  stringSorting,
} from "utilities/tableUtility";

export default function JobsTable({
  rowData,
  gridRef,
  onRowClicked,
  setClientJobs,
  setExportData,
  getClientDetailsJobs,
  getJobUdfs,
  onGridReady,
  datasource,
  setRefreshTable,
}) {
  const [form] = useForm();
  const [deleteForm] = useForm();
  const { id } = useParams();
  const userSlice = useSelector(selectUser);
  const userDetails = useSelector(selectUser).details;
  const [modalState, setModalState] = useState({
    editData: null,
    isCreateJobModalVisible: false,
    isDeleteJobModalVisible: false,
    deleteResponse: null,
    isUploadModalVisible: false,
    isLoadingJobModal: false,
    isLoadingUploadModal: false,
  });
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const lookupTables = useSelector(selectConfigration).lookup;

  const actionColumnRenderer = (params) => (
    <div className="d-flex " style={{ justifyContent: "center" }}>
      <Button
        type="link"
        className={LayoutCss.appListingCardActions}
        onClick={(e) => {
          editRecord(params.data.id);
        }}
      >
        Edit
      </Button>
      {isPermissionPresent(K.Permissions.DeleteJob, userSlice.roles) && (
        <Button
          type="link"
          style={{ color: "red" }}
          className={LayoutCss.appListingCardActions + " "}
          onClick={(e) => {
            e.stopPropagation();
            deleteRecord(params.data.id);
          }}
        >
          Delete
        </Button>
      )}
    </div>
  );

  const payRenderer = (params) => displayDollar(params.value);
  const changeFormat = (params) => convertIntoDashUSFormat(params.value, false);
  const tagRenderer = (params, key, color) =>
    params.data[key].map((item, i) => (
      <Tag key={i} color={color}>
        {item.name}{" "}
      </Tag>
    ));
  const jobStatus = (params) => (params.data.isCancel === 1 ? "Yes" : "No");
  const workFormHome = (params) => (params.data.isWorkFromHome ? "Yes" : "No");
  const atsId = (params) => params.data.extAtsId ?? K.NullPlaceholder;
  const jobDepartment = (params) => params.data.jobDepartment?.name ?? "N/A";
  const jobGroup = (params) => params.data.jobGroup?.name ?? "N/A";

  const tooltipJobStatus = (params) => (
    <div className="client-list-tooltip">
      {params.data.isCancel === 1 ? "Yes" : "No"}
    </div>
  );
  const tooltipWorkFormHome = (params) => (
    <div className="client-list-tooltip">
      {params.data.isWorkFromHome === 1 ? "Yes" : "No"}
    </div>
  );
  const tooltipChangeFormat = (params) => (
    <div className="client-list-tooltip">
      {convertIntoDashUSFormat(params.value, false)}
    </div>
  );
  const tooltipPayRenderer = (params) => (
    <div className="client-list-tooltip"> {displayDollar(params.value)}</div>
  );
  const tooltipJobDepartment = (params) => (
    <div className="client-list-tooltip">
      {params.data.jobDepartment?.name ?? "N/A"}
    </div>
  );
  const tooltipJobGroup = (params) => (
    <div className="client-list-tooltip">
      {params.data.jobGroup?.name ?? "N/A"}
    </div>
  );
  const tooltipAtsId = (params) => {
    return (
      <div className="client-list-tooltip">
        {params.data.extAtsId ?? K.NullPlaceholder}
      </div>
    );
  };

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "ID",
      field: "id",
      sortable: true,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      suppressColumnsToolPanel: true,
    },
    {
      headerName: "Recruiter",
      field: "searchRecruiters",
      sortable: true,
      tooltipField: "searchRecruiters",
      tooltipComponent: (params) => tooltipTagRenderer(params, "jobRecruiters"),
      cellRenderer: (params) => tagRenderer(params, "jobRecruiters", "#0070C0"),
    },
    {
      headerName: "Job Title",
      field: "title",
      sortable: true,
      tooltipField: "title",
    },
    //TODO:Need to change the total opening to current openings
    {
      headerName: "Current Openings",
      field: "totalOpenings",
      sortable: true,
      tooltipField: "totalOpenings",
    },
    {
      headerName: "Job Location",
      field: "jobLocation.name",
      sortable: true,
      tooltipField: "jobLocation.name",
    },
    {
      headerName: "Job Department",
      field: "jobDepartment",
      sortable: true,
      tooltipField: "jobDepartment",
      getQuickFilterText: jobDepartment,
      comparator: (a, b, nodeA, nodeB, isDescending) =>
        stringSorting(a, b, "name"),
      tooltipComponent: tooltipJobDepartment,
      cellRenderer: jobDepartment,
    },
    {
      headerName: "Job Group",
      field: "jobGroup",
      sortable: true,
      tooltipField: "jobGroup",
      getQuickFilterText: jobGroup,
      comparator: (a, b, nodeA, nodeB, isDescending) =>
        stringSorting(a, b, "name"),
      tooltipComponent: tooltipJobGroup,
      cellRenderer: jobGroup,
    },
    {
      headerName: "Job Hiring Manager",
      field: "searchHiringManagers",
      sortable: true,
      tooltipField: "searchHiringManagers",
      tooltipComponent: (params) =>
        tooltipTagRenderer(params, "jobHiringManagers"),
      cellRenderer: (params) =>
        tagRenderer(params, "jobHiringManagers", "#BDD7EE"),
    },
    {
      headerName: "Recruiting Start Date",
      field: "recruitingStartDate",
      sortable: true,
      suppressMovable: false,
      tooltipField: "recruitingStartDate",
      tooltipComponent: tooltipChangeFormat,
      cellRenderer: changeFormat,
    },
    {
      headerName: "Max Pay",
      field: "maxPay",
      sortable: true,
      suppressMovable: false,
      tooltipField: "maxPay",
      tooltipComponent: tooltipPayRenderer,
      cellRenderer: payRenderer,
    },
    {
      headerName: "Min Pay",
      field: "minPay",
      sortable: true,
      suppressMovable: false,
      tooltipField: "minPay",
      tooltipComponent: tooltipPayRenderer,
      cellRenderer: payRenderer,
    },
    {
      headerName: "Tier",
      field: "jobTier.name",
      sortable: true,
      tooltipField: "jobTier.name",
    },
    {
      headerName: "ATS ID",
      field: "extAtsId",
      sortable: true,
      tooltipField: "extAtsId",
      tooltipComponent: tooltipAtsId,
      cellRenderer: atsId,
    },
    {
      headerName: "Job Status",
      field: "jobStatus.name",
      sortable: true,
      tooltipField: "jobStatus.name",
    },
    {
      headerName: "Cancelled?",
      field: "isCancel",
      sortable: true,
      tooltipField: "isCancel",
      tooltipComponent: tooltipJobStatus,
      cellRenderer: jobStatus,
    },
    {
      headerName: "WFH?",
      field: "isWorkFromHome",
      sortable: true,
      tooltipField: "isWorkFromHome",
      tooltipComponent: tooltipWorkFormHome,
      cellRenderer: workFormHome,
    },
    {
      headerName: "Actions",
      field: "actions",
      sortable: true,
      tooltipField: "actions",
      tooltipComponent: actionColumnRenderer,
      cellRenderer: actionColumnRenderer,
    },
  ]);

  const editRecord = async (recordId) => {
    await getJobUdfs(recordId);
    setModalState((prev) => {
      return {
        ...prev,
        isCreateJobModalVisible: true,
        editJobId: recordId,
      };
    });
  };

  const deleteRecord = (recordId) => {
    setModalState((prev) => {
      return {
        ...prev,
        isDeleteJobModalVisible: true,
      };
    });
  };

  const cancelHandler = () => {
    form.resetFields();
    setModalState((prev) => {
      return { ...prev, isCreateJobModalVisible: false };
    });
  };
  const deleteCancelHandler = () => {
    form.resetFields();
    setModalState((prev) => {
      return { ...prev, isDeleteJobModalVisible: false, deleteResponse: null };
    });
  };

  const afterModalClose = () => {
    form.resetFields();
  };

  //?save cloumn Sorting
  const updateColumns = async (event) => {
    const cols = filterColumnListing(
      columnDefs,
      event.columnApi.getColumnState(),
    );
    if (shouldUpdate)
      try {
        await User.saveColumnSort({
          usersId: userDetails.id,
          tableName: K.AgGridTable.Keys.ClientJobsVisibleColumn,
          configJson: JSON.stringify(cols),
        });
        message.success("Columns order saved successfully");
      } catch (err) {
        console.error(err);
      }
    else setShouldUpdate(true);
  };

  const onColumnVisible = async (event) => {
    if (event.source === "gridOptionsChanged") return;
    const cols = filterColumnListing(
      columnDefs,
      event.columnApi.getColumnState(),
    );
    try {
      await User.saveTableVisibleColumn({
        usersId: userDetails.id,
        tableName: K.AgGridTable.Keys.ClientJobsVisibleColumn,
        configJson: JSON.stringify(cols),
      });
      message.success("Column configration saved");
    } catch (err) {
      console.error(err);
    }
  };

  const onSelectionChanged = (event) => {
    const selectedRows = event.api.getSelectedRows();
    setExportData((prev) => ({
      ...prev,
      isDisabled:
        selectedRows.length === 0 &&
        !event.api.getServerSideSelectionState().selectAll,
      jobIds: selectedRows.map(({ id }) => id),
    }));
  };

  //*this method is save row clicked data into te edit data and edit data is used in both delete and edit job
  const onCellClicked = (params) => {
    if (params.colDef.field !== "actions") {
      onRowClicked(params);
    } else {
      setModalState((prev) => {
        return {
          ...prev,
          editData: rowData.find((item) => item.id === params.data.id),
        };
      });
    }
    // if (params.colDef.field === "actions") {
    //   console.log(params);
    //   editRecord(params.data.id);
    // } else {
    //   onRowClicked(params);
    // }
  };

  const getColumnsConfigrations = async () => {
    try {
      const res = await User.getConfigrations(
        K.AgGridTable.Keys.ClientJobsVisibleColumn,
      );
      const parsed = JSON.parse(res.config).map((item) => {
        if (item.field === "id")
          return {
            ...item,
            checkboxSelection: true,
            headerCheckboxSelection: true,
          };
        else if (item.field === "recruitingStartDate")
          return {
            ...item,
            cellRenderer: changeFormat,
            tooltipComponent: tooltipChangeFormat,
          };
        else if (["minPay", "maxPay"].includes(item.field))
          return {
            ...item,
            cellRenderer: payRenderer,
            tooltipComponent: tooltipPayRenderer,
          };
        else if (item.field === "isCancel")
          return {
            ...item,
            cellRenderer: jobStatus,
            tooltipComponent: tooltipJobStatus,
          };
        else if (item.field === "isWorkFromHome")
          return {
            ...item,
            cellRenderer: workFormHome,
            tooltipComponent: tooltipWorkFormHome,
          };
        else if (item.field === "extAtsId")
          return {
            ...item,
            cellRenderer: atsId,
            tooltipComponent: tooltipAtsId,
          };
        else if (item.field === "jobDepartment")
          return {
            ...item,
            cellRenderer: jobDepartment,
            getQuickFilterText: jobDepartment,
            tooltipComponent: tooltipJobDepartment,
            comparator: (a, b, nodeA, nodeB, isDescending) =>
              stringSorting(a, b, "name"),
          };
        else if (item.field === "jobGroup")
          return {
            ...item,
            cellRenderer: jobGroup,
            getQuickFilterText: jobGroup,
            tooltipComponent: tooltipJobGroup,
            comparator: (a, b, nodeA, nodeB, isDescending) =>
              stringSorting(a, b, "name"),
          };
        else if (
          ["searchRecruiters", "searchHiringManagers"].includes(item.field)
        ) {
          const columnKeys = {
            searchRecruiters: ["jobRecruiters", "#0070C0"],
            searchHiringManagers: ["jobHiringManagers", "#BDD7EE"],
          };
          return {
            ...item,
            cellRenderer: (params) =>
              tagRenderer(
                params,
                columnKeys[item.field][0],
                columnKeys[item.field][1],
              ),
            tooltipComponent: (params) =>
              tooltipTagRenderer(params, columnKeys[item.field][0]),
          };
        } else if (item.field === "actions")
          return {
            ...item,
            cellRenderer: actionColumnRenderer,
            tooltipComponent: actionColumnRenderer,
          };

        return item;
      });
      setColumnDefs(parsed);
    } catch (err) {
      console.error(err);
    }
  };

  const deleteJob = async () => {
    try {
      setModalState((prev) => {
        return { ...prev, isLoadingJobModal: true };
      });

      await Job.deleteJob(modalState.editData.id);

      setModalState((prev) => {
        return {
          ...prev,
          isDeleteJobModalVisible: false,
          deleteResponse: null,
        };
      });
      setClientJobs((prev) =>
        prev.filter(({ id }) => id !== modalState.editData.id),
      );
      message.success("Job deleted successfully");
    } catch (error) {
      setModalState((prev) => {
        return {
          ...prev,
          deleteResponse: error.message,
        };
      });
      console.log(error);
    } finally {
      setModalState((prev) => {
        return { ...prev, isLoadingJobModal: false };
      });
    }
  };

  useEffect(() => {
    getColumnsConfigrations();
  }, []);

  return (
    <>
      <div
        className="ag-theme-alpine s2-theme-style"
        style={{
          height: 675,
        }}
      >
        <AgGridReact
          ref={gridRef}
          columnDefs={columnDefs}
          defaultColDef={K.AgGridTable.DefaultColDef}
          suppressRowClickSelection
          rowSelection="multiple"
          onColumnMoved={updateColumns}
          onCellClicked={onCellClicked}
          onColumnPinned={onColumnVisible}
          onColumnVisible={onColumnVisible}
          onSelectionChanged={onSelectionChanged}
          rowData={rowData}
          pagination={true}
          paginationPageSize={25}
          cacheBlockSize={25}
          onGridReady={onGridReady}
          rowModelType="serverSide"
          loadingCellRenderer={false}
          serverSideDatasource={datasource}
          {...K.AgGridTable.DefaultProps}
        />
      </div>

      <Modal
        centered
        width={568}
        okText="Update"
        title="Edit Job"
        className="s2-theme-style modal-overflow-auto"
        wrapClassName="vertical-center-modal"
        open={modalState.isCreateJobModalVisible}
        closeIcon={<i className="icon-closeable"></i>}
        okButtonProps={{ loading: modalState.isLoadingJobModal }}
        onOk={form.submit}
        onCancel={cancelHandler}
        afterClose={afterModalClose}
        destroyOnClose
      >
        <CreateJob
          form={form}
          clientId={id}
          lookupTables={lookupTables}
          editJobId={modalState.editJobId}
          editData={modalState.editData}
          setModalState={setModalState}
          setClientJobs={setClientJobs}
          getClientDetailsJobs={getClientDetailsJobs}
          getJobUdfs={getJobUdfs}
          setRefreshTable={setRefreshTable}
        />
      </Modal>

      <Modal
        centered
        width={568}
        okText="Delete Job"
        title="Are you sure?"
        className="s2-theme-style modal-overflow-auto"
        wrapClassName="vertical-center-modal"
        open={modalState.isDeleteJobModalVisible}
        closeIcon={<i className="icon-closeable"></i>}
        okButtonProps={{
          loading: modalState.isLoadingJobModal,
        }}
        onOk={deleteForm.submit}
        onCancel={deleteCancelHandler}
        afterClose={afterModalClose}
        destroyOnClose
      >
        <DeleteJob
          form={deleteForm}
          clientId={id}
          modalState={modalState}
          record={modalState.editData}
          setModalState={setModalState}
          deleteJob={deleteJob}
        />
      </Modal>
    </>
  );
}
