import { Card, Col, Input, message, Row, Space } from "antd";
import { useCallback, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { selectSelectedClients } from "../../../redux/slices/globalSelectorSlice";
import { AgGridReact } from "ag-grid-react";
import K from "../../../utilities/constants";
import { Columns, DefaultColDef } from "./columns";
import { selectUserId, selectUserRoles } from "../../../redux/slices/userSlice";
import { filterColumnListing } from "../../../utilities/tableUtility";
import {
  isPermissionPresent,
  mergeArrayOfObjects,
} from "../../../utilities/generalUtility";
import { debounce } from "lodash";
import { ExportButton } from "./exportButton";
import { useHistory } from "react-router-dom";
import { RecruitersSelect } from "./recruitersSelect";

export const EngagementsContainer = ({
  getData,
  getConfig,
  saveConfig,
  onExport,
}) => {
  const history = useHistory();

  const clients = useSelector(selectSelectedClients);
  const userId = useSelector(selectUserId);
  const roles = useSelector(selectUserRoles);

  const gridRef = useRef(null);

  const [columnDefs, setColumnDefs] = useState(null);
  const [totalCount, setTotalCount] = useState(null);
  const [search, setSearch] = useState(null);
  const [recruiters, setRecruiters] = useState([]);

  const isExportLimitExceeded = totalCount > K.Limits.Engagement.export;
  const isExportDisabled = totalCount === 0 || isExportLimitExceeded;

  const hasAccessToCandidateProfile = isPermissionPresent(
    K.Permissions.ViewCandidateJobs,
    roles,
  );

  const hasAccessToRecruiters = isPermissionPresent(
    K.Permissions.ManageEngagementsForAssignedClients,
    roles,
  );

  const dataSource = useMemo(() => {
    return {
      getRows: async (params) => {
        const { startRow, endRow, sortModel } = params.request;

        const pageSize = endRow - startRow;
        const page = startRow / pageSize + 1;

        const body = {
          page,
          pageSize,
          client: clients,
          sortBy: sortModel[0]?.colId,
          sortOrder: sortModel[0]?.sort,
          search: search ?? undefined,
          recruiter: recruiters,
        };

        try {
          const res = await getData(body);

          if (!res.total) {
            params.api.showNoRowsOverlay();
          }

          setTotalCount(res.total);
          params.success({ rowData: res.data, rowCount: res.total });
        } catch (err) {
          params.fail();
        }
      },
    };
  }, [getData, clients, search, recruiters]);

  const onGridReady = useCallback(async () => {
    try {
      const res = await getConfig();

      if (res) {
        const config = JSON.parse(res.config);
        const data = mergeArrayOfObjects(config, Columns, (i) => i.field);
        setColumnDefs(data);
      } else {
        setColumnDefs(Columns);
      }
    } catch (err) {
      console.error(err);
    }
  }, [getConfig, setColumnDefs]);

  const updateColumns = useCallback(
    debounce(async ({ columnApi }) => {
      try {
        const data = filterColumnListing(Columns, columnApi.getColumnState());
        await saveConfig({ usersId: userId, configJson: JSON.stringify(data) });
        message.success("Table configuration saved successfully");
      } catch (err) {
        console.error(err);
      }
    }, 1000),
    [saveConfig, userId],
  );

  const onExportRequested = async () => {
    try {
      const columnApi = gridRef.current.columnApi;
      const sortColumn = columnApi.getColumnState().find((c) => c.sort);

      const params = {
        client: clients,
        sortBy: sortColumn?.colId,
        sortOrder: sortColumn?.sort,
        search: search ?? undefined,
        recruiter: recruiters,
      };

      await onExport(params);
      message.success(
        "Engagement export request successfully received! Check your email shortly",
      );
    } catch (err) {
      console.error(err);
    }
  };

  const onSearchChange = useCallback(
    debounce((e) => {
      setSearch(e.target.value || null);
    }, 500),
    [setSearch],
  );

  const onRowClicked = ({ data }) => {
    if (!hasAccessToCandidateProfile) {
      return;
    }

    const { candidateId, candidateJobEntryId } = data;
    const params = { tab: "jobs", candidateJobEntryId, jobTab: "engagements" };

    history.push(`/candidates/detail/${candidateId}`, params);
  };

  return (
    <Card bodyStyle={{ padding: 16 }}>
      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        <Row justify={"space-between"} gutter={[8, 8]}>
          <Col style={{ flex: 1 }}>
            <Row gutter={[8, 8]}>
              <Col style={{ width: "100%", maxWidth: 264, minWidth: 200 }}>
                <Input.Search
                  allowClear
                  placeholder="Search"
                  onChange={onSearchChange}
                />
              </Col>
              {hasAccessToRecruiters && (
                <Col style={{ width: "100%", maxWidth: 200, minWidth: 150 }}>
                  <RecruitersSelect
                    onChange={setRecruiters}
                    clients={clients}
                  />
                </Col>
              )}
            </Row>
          </Col>
          <Col>
            <ExportButton
              disabled={isExportDisabled}
              onClick={onExportRequested}
              isExportLimitExceeded={isExportLimitExceeded}
            />
          </Col>
        </Row>
        <div
          className="ag-theme-alpine s2-theme-style"
          style={{
            height: 735,
          }}
        >
          <AgGridReact
            {...K.AgGridTable.DefaultProps}
            ref={gridRef}
            defaultColDef={DefaultColDef}
            columnDefs={columnDefs}
            serverSideDatasource={columnDefs ? dataSource : null}
            cacheBlockSize={25}
            onGridReady={onGridReady}
            rowModelType="serverSide"
            onColumnMoved={updateColumns}
            onColumnPinned={updateColumns}
            onColumnVisible={updateColumns}
            onRowClicked={onRowClicked}
            rowStyle={{
              cursor: hasAccessToCandidateProfile ? "pointer" : "default",
            }}
          />
        </div>
      </Space>
    </Card>
  );
};
