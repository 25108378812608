import { Layout } from "antd";
import moment from "moment";

export default function Footer() {
  const { Footer } = Layout;
  return (
    <Footer style={{ textAlign: "center" }}>
      Source2 &copy;{moment().year()}
    </Footer>
  );
}
