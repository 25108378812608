import Icon from "@ant-design/icons";

export const Component = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_611_47351)">
        <path
          d="M3 11.0437L3 13.5613C3 13.9429 3.15804 14.3088 3.43934 14.5786C3.72064 14.8484 4.10218 15 4.5 15H9H13.5C13.8978 15 14.2794 14.8484 14.5607 14.5786C14.842 14.3088 15 13.9429 15 13.5613V11.0437M9.00084 2.00005V11.0442M9.00084 11.0442L12.4294 7.92927M9.00084 11.0442L5.57227 7.92927"
          stroke="currentColor"
          fill="none"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_611_47351">
          <rect width="18" height="18" fill="currentColor" stroke="none" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const DownloadIcon = (props) => (
  <Icon component={Component} {...props} />
);
