import Icon from "@ant-design/icons";

export const Component = () => {
  return (
    <svg
      width="11"
      height="14"
      viewBox="0 0 11 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.125 5V4.42857C2.125 2.52925 3.63036 1 5.5 1C6.66464 1 7.68793 1.5934 8.29405 2.5M2.125 5C1.50625 5 1 5.51429 1 6.14286V11.8571C1 12.4857 1.50625 13 2.125 13H8.875C9.49375 13 10 12.4857 10 11.8571V6.14286C10 5.51429 9.49375 5 8.875 5H2.125ZM5.5 7.85714C6.11875 7.85714 6.625 8.37143 6.625 9C6.625 9.62857 6.11875 10.1429 5.5 10.1429C4.88125 10.1429 4.375 9.62857 4.375 9C4.375 8.37143 4.88125 7.85714 5.5 7.85714Z"
        stroke="#1890FF"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const UnlockIcon = (props) => <Icon component={Component} {...props} />;
