import { Spin } from "antd";
import { usePromiseTracker } from "react-promise-tracker";

export default function Spinner({
  children,
  isSidebarCollapsed = true,
  isGuest = false,
}) {
  const { promiseInProgress } = usePromiseTracker();

  const classNames = !isSidebarCollapsed && !isGuest ? "without-sidebar" : "";

  return (
    <Spin
      className={`global-spinner ${classNames}`}
      spinning={promiseInProgress}
      size="large"
    >
      {children}
    </Spin>
  );
}
