import NetworkCall from "network/networkCall";
import Request from "network/request";

export default class Engagement {
  static async get(params) {
    return NetworkCall.fetch(Request.v2.getEngagements(params));
  }

  static async edit(id, params) {
    return NetworkCall.fetch(Request.v2.editEngagement(id, params));
  }
}
